import React from 'react';

export default function UniversityProgram ({ profileData }) {
// console.log(profileData);

    return (
        <div className='bg-white px-8 py-8  shadow-md rounded-md bg-clip-border '>


            <div className='flex flex-col items-start'>
                <h3 className=" block font-sans text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                    University Program
                </h3>
                <p className='text-left'>
                    {/* {profileData && profileData.profileData.about} */}
                </p>
            </div>



        </div>
    )
}