import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');

let headers = {};

if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject.token;

  headers = {
    Authorization: token,
  };
}

// Fetch all educations
export const getAllEducations = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/educations`, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error('Error fetching educations:', error);
    throw new Error('Failed to fetch educations');
  }
};

// Fetch a single education by ID
export const getEducationById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/educations/${id}`, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error fetching education with ID ${id}:`, error);
    throw new Error(`Failed to fetch education with ID ${id}`);
  }
};

// Create a new education
export const createEducation = async (educationData) => {
  try {
    const response = await axios.post(`${BASE_URL}/educations`, educationData, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error('Error creating education:', error);
    throw new Error('Failed to create education');
  }
};

// Update an existing education
export const updateEducation = async (id, educationData) => {
  try {
    const response = await axios.put(`${BASE_URL}/educations/${id}`, educationData, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error updating education with ID ${id}:`, error);
    throw new Error(`Failed to update education with ID ${id}`);
  }
};

// Delete an education by ID
export const deleteEducation = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/educations/${id}`, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error deleting education with ID ${id}:`, error);
    throw new Error(`Failed to delete education with ID ${id}`);
  }
};

// Fetch educations by student ID
export const getEducationsByStudent = async (studentId) => {
  try {
    const response = await axios.get(`${BASE_URL}/educations/student/${studentId}`, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error fetching educations for student with ID ${studentId}:`, error);
    throw new Error(`Failed to fetch educations for student with ID ${studentId}`);
  }
};
