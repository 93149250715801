import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/userContext';
import { useSocket } from '../../contexts/socketContext';
import { getConversationsByParticipant,  } from '../../api/conversationApi';
import { getUserProfileById } from '../../api/userApi';

const ChatList = ({ onConversationSelect }) => {
  const [conversations, setConversations] = useState([]);
  const { user } = useContext(UserContext);
  const currentUserId = user && user.userId;
  const socket = useSocket();

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        if (currentUserId) {
          const conversationsData = await getConversationsByParticipant(currentUserId);
          const conversationsWithNames = await Promise.all(
            conversationsData.map(async (conversation) => {
              const participantId = conversation.participant1Id !== currentUserId
                ? conversation.participant1Id
                : conversation.participant2Id;
              const profileData = await getUserProfileById(participantId);
              const participantName = profileData.role === 'company'
                ? profileData.profileData.companyName
                : profileData.role === 'university'
                ? profileData.profileData.universityName
                : profileData.profileData.firstName;
              return { ...conversation, participantName };
            })
          );
          setConversations(conversationsWithNames);
        }
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    };

    fetchConversations();
  }, [currentUserId]);

  const handleJoinRoom = (conversationId) => {
    if (!socket || !currentUserId) return;
    socket.emit('leaveRoom');
    socket.emit('joinRoom', conversationId);
  };

  const handleConversationClick = (conversation) => {

    const participantId = conversation.participant1Id !== currentUserId
      ? conversation.participant1Id
      : conversation.participant2Id;
    const participantName = conversation.participantName;
    // console.log(participantId,participantName);
    onConversationSelect(conversation, participantId, participantName);
    handleJoinRoom(conversation.id);
  };

  return (
    <div className="flex flex-col gap-2">
      {conversations.map((conversation, index) => (
        <div
          key={index}
          className="flex gap-2 items-center p-2 cursor-pointer hover:bg-gray-100 rounded-lg"
          onClick={() => handleConversationClick(conversation)}
        >
          <div className="flex-shrink-0">
            <img
              src="/studentProfile.jpeg"
              alt="Avatar"
              className="hidden md:flex w-10 h-10 rounded-full "
            />
          </div>
          <div className="flex-grow truncate ..">
            <div className="overflow-hidden font-semibold text-lg text-left ml-2 text-wrap">{conversation.participantName}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatList;
