import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

export default function ActionsButton({ selectedApplicant, onUpdateStatus, context }) {
    // console.log(selectedApplicant);
    return (
        <Menu>
            <MenuHandler>
                <Button variant="outlined">Actions</Button>
            </MenuHandler>

            <MenuList className="max-h-72">
                <Link to={`/profile/${selectedApplicant.Student.User.userName}`}>
                    {/* role="button "
                    className=" "
                    onClick={() => onUpdateStatus(selectedApplicant.id, 'accepted')}> */}

                    <MenuItem>
                        View Profile

                    </MenuItem>
                </Link>
                <div
                    role="button "
                    className=" "
                    // onClick={() => onUpdateStatus(selectedApplicant.id, 'accepted')}
                    >

                    <MenuItem>
                        Send Message

                    </MenuItem>
                </div>

                {/* <hr className="my-3" /> */}
                {context === 'default' && (
                    <>
                                    <hr className="my-3" />

                        <div
                            role="button"
                            className="bg-green-500/20 text-green-900"
                            onClick={() => onUpdateStatus(selectedApplicant.id, 'accepted')}>
                            <MenuItem>Accept</MenuItem>
                        </div>
                        <div
                            role="button"
                            className="bg-white"
                            onClick={() => onUpdateStatus(selectedApplicant.id, 'shortlisted')}>
                            <MenuItem>Shortlist</MenuItem>
                        </div>
                        {/* <div
                            role="button"
                            className=""
                            onClick={() => onUpdateStatus(selectedApplicant.id, 'scheduleInterview')}>
                            <MenuItem>Schedule Interview</MenuItem>
                        </div> */}
                                                            <hr className="my-3" />

                        <div
                            role="button"
                            className="bg-red-500/20 text-red-900"
                            onClick={() => onUpdateStatus(selectedApplicant.id, 'rejected')}>
                            <MenuItem>Reject</MenuItem>
                        </div>
                    </>
                )}

                {context === 'validation' && (
                    <>
                                    <hr className="my-3" />

                        <div
                            role="button"
                            className="bg-green-500/20 text-green-900 rounded-lg"
                            onClick={() => onUpdateStatus(selectedApplicant.id, 'validated')}>
                            <MenuItem>Validate</MenuItem>
                        </div>
                        <hr className="my-3" />

                        <div
                            role="button"
                            className="bg-red-500/20 text-red-900 rounded-lg"
                            onClick={() => onUpdateStatus(selectedApplicant.id, 'invalidated')}>
                            <MenuItem>Invalidate</MenuItem>
                        </div>
                    </>
                )}

                {context === 'hired' && (
                    <>
                        {/* <div
                            role="button"
                            className=""
                            onClick={() => onUpdateStatus(selectedApplicant.id, 'sendMessage')}>
                            <MenuItem>Send Message</MenuItem>
                        </div>
                        <div
                            role="button"
                            className=""
                            onClick={() => onUpdateStatus(selectedApplicant.id, 'viewProfile')}>
                            <MenuItem>View Profile</MenuItem>
                        </div> */}
                    </>
                )}

                {context === 'shortlisted' && (
                    <>
                                    <hr className="my-3" />

                        <div
                            role="button"
                            className=""
                            onClick={() => onUpdateStatus(selectedApplicant.id, 'accept')}>
                            <MenuItem>Accept</MenuItem>
                        </div>
                        <div
                            role="button "
                            className="bg-red-500/20 text-red-900 "
                            onClick={() => onUpdateStatus(selectedApplicant.id, 'rejected')}>

                            <MenuItem>
                                Reject
                            </MenuItem>
                        </div>
                    </>
                )}
                {/* <div
                    role="button "
                    className="bg-green-500/20 text-green-900 "
                    onClick={() => onUpdateStatus(selectedApplicant.id, 'accepted')}>

                    <MenuItem>
                        Accept

                    </MenuItem>
                </div>
                <div
                    role="button "
                    className="bg-white "
                    onClick={() => onUpdateStatus(selectedApplicant.id, 'accepted')}>

                    <MenuItem>
                        Shortlist
                    </MenuItem>
                </div>
                <div
                    role="button "
                    className=" "
                    onClick={() => onUpdateStatus(selectedApplicant.id, 'accepted')}>

                    <MenuItem>
                        Schedule Interview
                    </MenuItem>
                </div>
                <hr className="my-3" />
                <div
                    role="button "
                    className=" "
                    onClick={() => onUpdateStatus(selectedApplicant.id, 'accepted')}>

                    <MenuItem>
                        Send Mail

                    </MenuItem>
                </div>
                <div
                    role="button "
                    className=" "
                    onClick={() => onUpdateStatus(selectedApplicant.id, 'accepted')}>

                    <MenuItem>
                        Request Paper

                    </MenuItem>
                </div>
                <hr className="my-3" /> */}

                {/* <div
                    role="button "
                    className="bg-red-500/20 text-red-900"
                    onClick={() => onUpdateStatus(selectedApplicant.id, 'accepted')}>

                    <MenuItem>
                        Reject
                    </MenuItem>
                </div> */}
            </MenuList>
        </Menu>
    );
}