import React, { useContext, useEffect, useState } from 'react';
import IndustryInput from '../authentification/industryInput';
import { UserContext } from '../../contexts/userContext';
import { useNavigate } from 'react-router-dom';
import { getCompanyById, updateCompany } from '../../api/companyApi';
import { getUniversityById, updateUniversity } from '../../api/universityApi';


export default function UniversityInformation() {
  const { userData } = useContext(UserContext);
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [universityData, setUniversityData] = useState({
    universityName: '',
    location: '',
    contactInfo: '',
    about: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUniversityData = async () => {
      if (userData) {
        try {
          const response = await getUniversityById(userData.id);
          setUniversityData({
            universityName: response.universityName,
            location: response.location,
            about: response.about,
            contactInfo: response.contactInfo
          });
        } catch (error) {
          console.error('Error fetching university data:', error);
        }
      }
    };

    fetchUniversityData();
  }, [userData]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setUniversityData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log(universityData);
      await updateUniversity(userData.id, universityData);
      setAlert({ type: 'success', message: 'Information updated successfully!' });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
          setTimeout(() => {
            navigate('/login');
          }, 1000);
        } else if (error.response.status === 403) {
          setAlert({ type: 'error', message: 'Forbidden. You do not have permission to edit this information.' });
          setTimeout(() => {
            navigate('/unauthorized');
          }, 1000);
        } else {
          setAlert({ type: 'error', message: 'An error occurred. Please try again.' });
        }
      } else {
        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });
      }
    }
  };
  const handleCloseAlert = () => {
    setAlert({ type: '', message: '' });
  };
  return (
    <div class="bg-white py-4 px-12 container mx-auto shadow-md rounded-xl bg-clip-border">
      <div className='fixed  top-24 right-10 space-y-4 z-50'>
        {alert.type === 'success' && (
          <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-green-500 rounded-lg shadow-lg opacity-100 font-regular">
            {alert.message}
            <button
              className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
              type="button"
              onClick={handleCloseAlert}
            >
              Close
            </button>
          </div>
        )}
        {alert.type === 'error' && (
          <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
            {alert.message}
            <button
              className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
              type="button"
              onClick={handleCloseAlert}
            >
              Close
            </button>
          </div>
        )}
      </div>
      <h4
        class="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Basic informations
      </h4>
      <form onSubmit={handleSubmit}>
        <div className='mt-8 flex flex-col gap-4 items-center'>
          {/* <label className='self-start  block text-base font-medium text-dark dark:text-white'>
          University name
        </label>
        <input
          type='text'
          name='companyName'
          value={companyData.companyName}
          onChange={handleChange}
          placeholder='Company Name'
          className='w-full bg-transparent rounded-md border border-primary px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
        />
         */}
              <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                University name
              </label>
              <input
                type='text'
                name='universityName'
                value={universityData.universityName}
                onChange={handleChange}
                placeholder='University Name'
                className='w-full bg-transparent rounded-md border border-primary px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
              />
          <div className='flex gap-4 w-full items-center'>
            
            <div className='flex flex-col gap-3 basis-1/2'>
              <label className="self-start block text-base font-medium text-dark dark:text-white">
                Location
              </label>
              <input
                type='text'
                name='location'
                value={universityData.location}
                onChange={handleChange}
                placeholder='Location'
                className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
              />
            </div>

            <div className='flex flex-col gap-3 basis-1/2'>
            <label className='self-start  block text-base font-medium text-dark dark:text-white'>
              Phone number
            </label>
            <input
              type='text'
              name='contactInfo'
              value={universityData.contactInfo}
              onChange={handleChange}
              placeholder='+216 66 666 666'
              className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
            />
          </div>

          </div>
          <label className=' self-start block text-base font-medium text-dark dark:text-white'>
            About
          </label>
          <textarea
            name="about"
            value={universityData.about}
            onChange={handleChange}
            rows='5'
            placeholder='About the company...'
            className='mb-6 w-full bg-transparent rounded-md border border-stroke dark:border-dark-3 p-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
          />

        </div>

        <button
          className="block w-48 select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="submit">
          Save
        </button>
      </form>
    </div>

  )
}