import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');

let headers = {};

if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject.token;

  headers = {
    Authorization: token,
  };
}
export const getOffers = async (page, limit, searchQuery = '', employmentType, university) => {
  try {
    const response = await axios.get(`${BASE_URL}/offers`, {
      params: { page, limit, search: searchQuery, employmentType, university},
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching offers:', error);
    throw new Error('Failed to fetch offers');
  }
};

export const getOffersByStatus = async (page, limit, searchQuery = '', employmentType, university, status = 'open') => {
  try {
      const response = await axios.get(`${BASE_URL}/offers/status/${status}`, {
          params: { page, limit, search: searchQuery, employmentType, university, status },
      });
      return response.data;
  } catch (error) {
      console.error('Error fetching offers by status:', error);
      throw new Error('Failed to fetch offers by status');
  }
};
// Fetch a single offer by ID
export const getOfferById = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/offers/${id}`/* ,{headers: headers,  } */);
      return response.data;
    } catch (error) {
      // Handle error
      console.error(`Error fetching offer with ID ${id}:`, error);
      throw new Error(`Failed to fetch offer with ID ${id}`);
    }
  };
  export const getOfferByCompanyId = async (companyId, page, limit, searchQuery = '') => {
    try {
      const response = await axios.get(`${BASE_URL}/offers/company/${companyId}`, {
        params: { page, limit, search: searchQuery }
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching offers for company ID ${companyId}:`, error);
      throw new Error(`Failed to fetch offers for company ID ${companyId}`);
    }
  };
  export const getOffersByStatusAndCompany = async (companyId, status, page, limit, searchQuery = '') => {
    try {
      const response = await axios.get(`${BASE_URL}/offers/company/${companyId}/status/${status}`, {
        params: { page, limit, search: searchQuery }
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching offers with status ${status} for company ID ${companyId}:`, error);
      throw new Error(`Failed to fetch offers with status ${status} for company ID ${companyId}`);
    }
  };

  export const createOffer = async (offerData) => {
    try {
      const response = await axios.post(`${BASE_URL}/offers`, offerData
      // ,{headers: headers,  }
      );
      return response.data;
    } catch (error) {
      // Handle error
      console.error('Error creating offer:', error);
      throw new Error('Failed to create offer');
    }
  };

  export const updateOffer = async (id, offerData) => {
    try {
      const response = await axios.put(`${BASE_URL}/offers/${id}`, offerData
      ,{headers: headers,  }
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw error;
      // console.error('Error creating offer:', error);
      // throw new Error('Failed to create offer');
    }
  };

  export const deleteOffer = async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/offers/${id}`, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      // Handle error
      console.error('Error deleting offer:', error);
      throw new Error('Failed to delete offer');
    }
  };