import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AuthProvider from './contexts/authContext';
import { UserProvider } from './contexts/userContext';
import { AbilityContext } from './contexts/canContext';
import ability from './routes/ability';
import { SocketProvider } from './contexts/socketContext';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <UserProvider>
        <AuthProvider>
            <AbilityContext.Provider value={ability}>
                <SocketProvider>
                    <App />
                </SocketProvider>
            </AbilityContext.Provider>
        </AuthProvider>
    </UserProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

