import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      [{ 'color': [] }, { 'background': [] }],  
    //   [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'],
      
    ]
  };

export default function Editor ({ value, onChange }) {
    // const [value, setValue] = useState('');
    // const logValue = () => {
    //     console.log(value);
    //   };

  return (
    <div >
  <ReactQuill theme="snow" value={value} onChange={onChange} modules={modules} />
  {/* <button onClick={logValue} className='mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600' > Log Content </button> */}
</div>
);
}