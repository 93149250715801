import React, { useState, useEffect, useContext } from 'react';
import DatePicker from '../common/datePicker';
import useUserRoles from '../../routes/roles';
import { UserContext } from '../../contexts/userContext';
import { getStudentById, updateStudent } from '../../api/studentApi';
import { useNavigate } from 'react-router-dom';



export default function StudentInformation() {
  // const role = useUserRoles();
  const { userData } = useContext(UserContext);
  
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [dateOfBirth, setDateOfBirth] = useState(new Date())

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    contactInfo: '',
    headline: '',
    about: ''
  });


  const navigate = useNavigate();

  useEffect(() => {
    // Fetch existing social media links for the student (if any)
    
    const fetchStudentData = async () => {
      if(userData)
      try {
        const response = await getStudentById(userData.id)
        // console.log( response);
        // setStudentData(response);
        setFormData({
          firstName: response.firstName,
          lastName: response.lastName,
          dateOfBirth: response.dateOfBirth,
          contactInfo: response.contactInfo,
          headline: response.headline,
          about: response.about})


      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };

    fetchStudentData();
  }, [userData]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      formData.dateOfBirth = dateOfBirth;
      // console.log(formData);
      await updateStudent(userData.id, formData);
      setAlert({ type: 'success', message: 'information updated successfully!' });

      // Handle successful update
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
            setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
            setTimeout(() => {
                 navigate('/login');
            }, 1000);
         
        } else if (error.response.status === 403) {
        setAlert({ type: 'error', message: 'Forbidden. You do not have permission to edit this offer.' });
        setTimeout(() => {
            navigate('/unauthorized');
        }, 1000);  
        

        } else {
            setTimeout(() => {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

            }, 1000);
        }
      } else {
        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

      }
    }
  };

  const handleCloseAlert = () => {
    setAlert({ type: '', message: '' });
};

  return (

    <div class="bg-white py-4 px-12 container mx-auto shadow-md rounded-xl bg-clip-border">
<div className='fixed  top-24 right-10 space-y-4 z-50'>
        {alert.type === 'success' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-green-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
                )}
                {alert.type === 'error' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
            )}
            </div>
      <h4
        class="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Basic informations
      </h4>
<form onSubmit={handleSubmit}>
      <div className='mt-8 flex flex-col gap-4 items-center'>
        <div className='flex gap-4 w-full'>
          <div className='flex flex-col gap-3 basis-1/2'>
            <label className='self-start  block text-base font-medium text-dark dark:text-white'>
              First name
            </label>
            <input
              type='text'
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              placeholder='First name'
              className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
            />
          </div>
          <div className='flex flex-col gap-3 basis-1/2'>
            <label className='self-start  block text-base font-medium text-dark dark:text-white'>
              Last name
            </label>
            <input
              type='text'
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              placeholder='Last name'
              className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
            />
          </div>
        </div>
        <div className='flex gap-4 w-full items-center'>
          <div className='flex flex-col gap-3 basis-1/2'>
            <label className='self-start  block text-base font-medium text-dark dark:text-white'>
              Date of birth
            </label>
            <DatePicker date={dateOfBirth} setDate={setDateOfBirth}/>
            {/* <input
              type='text'
              name='firstName'
              // value={formData.jobTitle}
              // onChange={handleChange}
              placeholder='Enter Title'
              className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
            /> */}
          </div>
          <div className='flex flex-col gap-3 basis-1/2'>
            <label className='self-start  block text-base font-medium text-dark dark:text-white'>
              Phone number
            </label>
            <input
              type='text'
              name='contactInfo'
              value={formData.contactInfo}
              onChange={handleChange}
              placeholder='+216 66 666 666'
              className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
            />
          </div>
        </div>
        <label className='self-start  block text-base font-medium text-dark dark:text-white'>
          Headline *
        </label>
        <input
          type='text'
          name='headline'
          value={formData.headline}
          onChange={handleChange}
          placeholder='Full stack Web developer'
          className='w-full bg-transparent rounded-md border border-primary px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
        />
        <label className=' self-start block text-base font-medium text-dark dark:text-white'>
          About
        </label>
        <textarea
          name="about"
          value={formData.about}
            onChange={handleChange}
          rows='5'
          placeholder='i"am a ...'
          className='mb-6 w-full bg-transparent rounded-md border border-stroke dark:border-dark-3 p-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
        />

      </div>

      <button
          className="block w-48 select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="submit">
          Save
        </button>
        </form>


    </div>

   

  )
}