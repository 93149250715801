import React from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'

import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)

export default function MyCalendar(props) {
    const { height } = props; // Destructure the height prop

    return (
        <>



            <div>
                <Calendar
                    localizer={localizer}
                    //   events={myEventsList}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height }}
                />
            </div>

        </>
    )
}