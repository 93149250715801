import React, { useEffect } from 'react';
import SavedJobs from '../../components/dashboard/student/savedJobs';

import ProfileCard from '../../components/dashboard/student/profileCard';
import MyCalendar from '../../components/calendar/calendar';
import RecentApplication from '../../components/dashboard/company/recentCompanyApplication';
import RecentJobs from '../../components/dashboard/student/recentJobs';
import UpcomingEvents from '../../components/dashboard/upcomingEvents';
import OpenJobs from '../../components/dashboard/company/openJobs';
import NewApplicationCard from '../../components/dashboard/company/newApplicationCard';
import RecommendedProfile from '../../components/dashboard/company/recommendedProfile';
import HiredCandidates from '../../components/dashboard/company/hiredCandidates';
import ShortlisedApplicant from '../../components/dashboard/company/shortlisedApplicant';
import JobPostingPerformance from '../../components/dashboard/company/jobPostingPerfermance';
import ApplicationFunnel from '../../components/dashboard/company/applicationFunnel';

export default function DashboardPageCompany() {



    return (
        <div className='flex flex-col gap-4 justify-center items-stretch'>
            <div 
            className='grid md:grid-cols-2 gap-4 grid-cols-1'
            // className='flex flex-wrap items-center justify-around gap-4'
            >
                {/* <div className='place-self-center w-full'>
                    <ProfileCard />
                </div> */}
                

                <div className='flex gap-4 flex-wrap place-self-center '>
                    <HiredCandidates />
                    
                    <NewApplicationCard/>
                                    

                    
</div>

            {/* <div className='grid grid-cols-2 gap-2 justify-center' > */}
            <div className='flex gap-4 flex-wrap place-self-center'>
                 <OpenJobs/>
                 <ShortlisedApplicant />
</div>
               
                                    

<JobPostingPerformance/>
<ApplicationFunnel/>
<RecentApplication />
                <RecommendedProfile />
                <UpcomingEvents/>
                <div className=' flex flex-col w-full bg-white shadow-md rounded-xl bg-clip-border p-4'>
                    <span className='font-semibold text-lg text-left mb-4 ml-2'>
                        Calendar
                    </span>
                    <MyCalendar height={280} />
                </div>

            </div>














            {/* <div className="mixed-chart"> */}
            {/* <Chart
              options={options}
              series={options.series}
            //   type=""
            //     height={350}
            //   width="500"
            chart={options.chart}
            /> */}

            {/* <RadialBar imageUrl= "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"/> */}
            {/* <Donut/> */}
            {/*  */}
            {/* </div> */}

            {/* <CirclePie percentage={5} colour="#000" imageUrl={'https://www.google.com/url?sa=i&url=https%3A%2F%2Favatar.fandom.com%2Fwiki%2FCategory%3ACharacters&psig=AOvVaw1k_GGusiMb5-z4SGH7QRkQ&ust=1715788158333000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCPCXoJG_jYYDFQAAAAAdAAAAABAE'}/> */}






        </div>
    )
}