// CertificateDialog.js
import React, { useState } from 'react';
import { Dialog } from '@material-tailwind/react';

export default function CertificateDialog({ open, handleOpen, handleSubmit, formData, handleChange, date, handleDateChange }) {
    return (
        <Dialog open={open} handler={handleOpen}>
            <div className="flex flex-col gap-4 px-6 h-screen overflow-scroll">
                <div className='sticky top-0 z-50 p-4 rounded-xl bg-clip-border bg-white'>
                    <h4 className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                        Add Certificates
                    </h4>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='pt-14 flex flex-col gap-3'>
                        <label className='self-start block text-base font-medium text-dark dark:text-white'>
                            Certificate name *
                        </label>
                        <input
                            type='text'
                            name="certificateName"
                            value={formData.certificateName}
                            onChange={handleChange}
                            placeholder='enter a certificate name'
                            className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                        />

                        <label className='self-start block text-base font-medium text-dark dark:text-white'>
                            Certificate date *
                        </label>

                        <div className='flex gap-4'>
                            <div className='flex flex-col gap-3 basis-1/2'>
                                <div className='relative z-20'>
                                    <select
                                        name="month"
                                        value={date.month}
                                        onChange={handleDateChange}
                                        className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'>
                                        <option value='' className='dark:bg-dark-2'>select a month</option>
                                        <option value='01' className='dark:bg-dark-2'>January</option>
                                        <option value='02' className='dark:bg-dark-2'>February</option>
                                        <option value='03' className='dark:bg-dark-2'>March</option>
                                        <option value='04' className='dark:bg-dark-2'>April</option>
                                        <option value='05' className='dark:bg-dark-2'>May</option>
                                        <option value='06' className='dark:bg-dark-2'>June</option>
                                        <option value='07' className='dark:bg-dark-2'>July</option>
                                        <option value='08' className='dark:bg-dark-2'>August</option>
                                        <option value='09' className='dark:bg-dark-2'>September</option>
                                        <option value='10' className='dark:bg-dark-2'>October</option>
                                        <option value='11' className='dark:bg-dark-2'>November</option>
                                        <option value='12' className='dark:bg-dark-2'>December</option>
                                    </select>
                                    <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                                </div>
                            </div>
                            <div className='flex flex-col gap-3 basis-1/2'>
                                <div className='relative z-20'>
                                    <select
                                        name="year"
                                        value={date.year}
                                        onChange={handleDateChange}
                                        className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'>
                                        <option value='' className='dark:bg-dark-2'>Select year</option>
                                        {Array.from({ length: 31 }, (_, i) => (
                                            <option key={i} value={2000 + i} className='dark:bg-dark-2'>{2000 + i}</option>
                                        ))}
                                    </select>
                                    <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                                </div>
                            </div>
                        </div>

                        <label className='self-start block text-base font-medium text-dark dark:text-white'>
                            Organization name / Institution *
                        </label>
                        <input
                            type='text'
                            name="organizationName"
                            value={formData.organizationName}
                            onChange={handleChange}
                            placeholder='enter an organization name'
                            className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                        />

                        <label className='self-start block text-base font-medium text-dark dark:text-white'>
                            Certificate link (If available)
                        </label>
                        <input
                            type="url"
                            name="certificateLink"
                            value={formData.certificateLink}
                            onChange={handleChange}
                            placeholder='ex : https://certificateUrl.com'
                            className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                        />

                        <label className='mt-6 self-start block text-base font-medium text-dark dark:text-white'>
                            Description
                        </label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            rows='5'
                            placeholder='Default textarea'
                            className='w-full bg-transparent rounded-md border border-stroke dark:border-dark-3 p-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
                        />
                        <div className="flex p-6 pt-6 gap-4">
                            <button
                                className="block w-48 select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                type="submit">
                                Save
                            </button>
                            <button
                                className="block w-48 select-none rounded-lg bg-white py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-black border-black shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                type="button"
                                onClick={handleOpen}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Dialog>
    );
}
