import React, { useState, useEffect, useCallback, useContext } from 'react';
import defineAbilities from '../routes/ability';
import { createCanBoundTo } from '@casl/react';
import OfferCard from '../components/offer/offerCard';
import SidebarFilter from '../components/sideBar/sidebarFilter';
import { getOffers, getOffersByStatus } from '../api/offerApi';
import SidebarFilterContent from '../components/sideBar/sidebarFilterContent';
import OfferOverview from '../components/offer/offerOverview';
import Pagination from '../components/common/pagination';
import { getUniversities } from '../api/universityApi';
import { Spinner } from '@material-tailwind/react';
import OfferApplyCard from '../components/offer/offerApplyCard';
import { UserContext } from '../contexts/userContext';
// import defineAbility from '../routes/ability';
// this is what i need to do the can 
// const Can = createCanBoundTo(defineAbilities());

{/* <Can I="read" an="InternshipOffer" > */ }
{/* <TestOffer/> */ }
{/* </Can> */ }

const OffersPage = () => {
  const [offers, setOffers] = useState([]);
  const [totalOffers, setTotalOffers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [employmentType, setEmploymentType] = useState('');
  const [university, setUniversity] = useState('');
  const [universities, setUniversities] = useState([]);
  const [status, setStatus] = useState('open'); // New state for status

  const itemsPerPage = 6;
  const { user } = useContext(UserContext);
// console.log(user);


  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const data = await getUniversities();
        setUniversities(data);
      } catch (error) {
        console.error('Error fetching universities:', error);
      }
    };

    fetchUniversities();
  }, []);


  useEffect(() => {
    const fetchOffer = async () => {
      setLoading(true);

      try {
        const data = await getOffersByStatus(currentPage, itemsPerPage, searchQuery, employmentType, university, status);
        // console.log("data", data);
        setOffers(data.offers);
        setTotalOffers(data.totalOffers);
        setLoading(false);
        if (currentPage > 1 && data.offers.length === 0) {
          setCurrentPage(1);
        }
      } catch (error) {
        console.error('Error fetching offer by user:', error);
        setLoading(false);

      }
    };

    fetchOffer();
  }, [currentPage, searchQuery, employmentType, university]);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };




  const handleFilter = useCallback(({ searchQuery, employmentType, university }) => {
    setSearchQuery(searchQuery);
    setEmploymentType(employmentType);
    setUniversity(university);
    setCurrentPage(1); // Reset to first page on filter change
  }, []);


  return (
    <div className=" flex flex-col items-center overflow-hidden gap-4 py-6  " >
      <div className=' '>
        <SidebarFilterContent onFilter={handleFilter} universities={universities} />
      </div>
      <div className='self-center px-6 w-full'>
      {loading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4">
              {offers.map((offer, index) => (
                user?.role === 'company' || user?.role === 'university' ? (
                  <OfferCard key={index} offer={offer} />
                ) : user?.role === 'student' || !user?.role ? (
                  <OfferApplyCard key={index} offer={offer} />
                ) : null
              ))}
          </div>
        )}
      </div>
      {!loading && (
        <div className="justify-between p-4 border-t border-blue-gray-50">
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={totalOffers}
            currentPage={currentPage}
            paginate={handlePageChange}
          />
        </div>
      )}
    </div>

  );
}

export default OffersPage;
