import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';

const applicationData = [
  { name: 'Jan', pending: 5, accepted: 2, rejected: 3 },
  { name: 'Feb', pending: 3, accepted: 4, rejected: 1 },
  { name: 'Mar', pending: 2, accepted: 3, rejected: 2 },
  { name: 'avr', pending: 5, accepted: 1, rejected: 0 },
  { name: 'May', pending: 2, accepted: 3, rejected: 2 },
  // Add more data points
];

const PendingApplicationsChart = () => (
  <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center">
    <h3 className='self-start ml-4 font-semibold'> Application Statistics </h3>
    
    <LineChart width={500} height={300} data={applicationData} margin={{ top: 20, right: 40, left: 0, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name">
      </XAxis>
      <YAxis>
      </YAxis>
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="pending" stroke="#8884d8" activeDot={{ r: 8 }} />
      <Line type="monotone" dataKey="accepted" stroke="#82ca9d" />
      <Line type="monotone" dataKey="rejected" stroke="#ff8042" />
    </LineChart>
  </div>
);

export default PendingApplicationsChart;
