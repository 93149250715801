import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getOfferByCompanyId, getOfferById } from '../../api/offerApi';
import { Spinner } from '@material-tailwind/react';

export default function RecentJob({ profileData }) {

  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalJobs, setTotalJobs] = useState(0);
  const itemsPerPage = 6;

  const fetchJobs = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getOfferByCompanyId(profileData.id, currentPage, itemsPerPage);
      setJobs(data.offers);
      setTotalJobs(data.totalOffers);
      setLoading(false);
      if (currentPage > 1 && data.offers.length === 0) {
        setCurrentPage(1);
      }
    } catch (error) {
      console.error('Error fetching offers', error);
      setLoading(false);
    }
  }, [profileData.id, currentPage]);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return <div className="flex justify-center items-center"><Spinner /></div>;
  }

  return (
    <div class="bg-white shadow-md rounded-xl bg-clip-border p-4">

      <div className='flex flex-col gap-2'>
        <span className='font-semibold text-lg text-left mb-2 ml-2'>
          Recent open jobs
        </span>

        <div className='grid grid-cols-1 lg:grid-cols-2 gap-2 '>
          {jobs.map((job) => (
            <div key={job.id} className="flex justify-between gap-4 w-full border-b">
              <div className='flex gap-4'>
                <div className=" hidden sm:block sm:shrink-0">
                  <img
                    alt=""
                    src="/jobLogo.png"
                    className="size-16 rounded-lg object-cover shadow-sm"
                  />
                </div>
                <div className=''>
                  <h3 className="text-lg text-left font-semibold text-gray-900 sm:text-sm">
                    {job.title}

                  </h3>
                  {/* <p className="mt-1 text-sm font-medium text-gray-600">{job.company}</p> */}
                </div>

              </div>

              <div className="  p-4 mr-4 border-blue-gray-50 ">
                <div className="w-max">
                  <div className={`relative grid items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap ${job.status === 'open' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                    <span>{job.status}</span>
                  </div>
                </div>
              </div>


            </div>
          ))}</div>

        <Link to="/offers" className=' '>
          <span className='underline font-body mt-2'>Show more </span>
        </Link>
      </div>
    </div>
  );
}