import React from 'react';

export default function NewStudents() {
    return (
        <div className="bg-gradient-to-b from-green-500/20  to-white shadow-md rounded-xl bg-clip-border lg:w-52 w-36 bg-white">
            <div className='flex flex-col items-start'>
                <div class="m-4 flex  self-start justify-center rounded-full ">
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-school"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" /><path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4" />
                </svg>
                <h3 className='self-start ml-4 font-semibold'> New students  </h3>

                </div>
                {/* <h3 className='   text-sm'> find best match for your job offers  </h3> */}

                <h1 className=' text-5xl ml-4 mb-4 text-green-900 font-bold' > 0 </h1>

                
            </div>
        </div>
    )
}