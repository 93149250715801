import React, { useState, useEffect, useContext } from 'react';

// import { getApplicationByCompanyId } from '../api'; // Import your API function
import ApplicantSidebar from '../../components/application/applicantSidebar';
import ApplicationData from '../../components/application/applicantData';
import { getApplicationByCompanyId, updateApplication, updateApplicationStatus } from '../../api/applicationApi';
import { UserContext } from '../../contexts/userContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getOfferByCompanyId } from '../../api/offerApi';
import { Drawer, IconButton, Spinner } from '@material-tailwind/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
const ApplicationDashboard = (/* { userData } */) => {
  const [applications, setApplications] = useState([]);
  const [offers, setOffers] = useState([]);

  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const { userData } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const { applicationId } = useParams();
 
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
   const navigate = useNavigate();
  useEffect(() => {
    if(!userData)      setLoading(true);

    const fetchApplicationsAndOffers = async () => {
      try {
        const applicationData = await getApplicationByCompanyId(userData.id);
        const offerData = await getOfferByCompanyId(userData.id);
        setApplications(applicationData);
        setOffers(offerData.offers);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching applications or offers:', error);
      }
    };

    fetchApplicationsAndOffers();
  }, [userData]);

  useEffect(() => {
    if (applicationId) {
      const selected = applications.find(app => app.id === parseInt(applicationId));
      setSelectedApplicant(selected);
    }

  }, [applicationId, applications]);

  const handleUpdateStatus = async (applicantId, newStatus) => {
    try {
        console.log(applicantId, newStatus);

      await updateApplicationStatus(applicantId, newStatus);
      setApplications((prev) =>
        prev.map((app) =>
          app.id === applicantId ? { ...app, status: newStatus } : app
        )
      );
      if (selectedApplicant && selectedApplicant.id === applicantId) {
        setSelectedApplicant((prev) => ({ ...prev, status: newStatus }));
      }
    } catch (error) {
      console.error('Error updating application status:', error);
    }
  };
  if (loading) {
    return <div className="flex justify-center items-center"><Spinner /></div>;
}
  return (
    <div className="flex bg-white lg:bg-transparent">
      <div className="lg:w-1/3 hidden lg:flex">
        <ApplicantSidebar
          applications={applications}
          offers={offers}
          onSelectApplicant={(applicant) => navigate(`/dashboard/applications/applicants/${applicant.id}`)}
        />
      </div>
      <IconButton
        className="lg:hidden"
        variant="text"
        size="lg"
        onClick={isDrawerOpen ? closeDrawer : openDrawer}
      >
        {isDrawerOpen ? (
          <XMarkIcon className="h-8 w-8 stroke-2" />
        ) : (
          <Bars3Icon className="h-8 w-8 stroke-2" />
        )}
      </IconButton>
      <Drawer
        className="drawer"
        open={isDrawerOpen}
        onClose={closeDrawer}
      >
        <ApplicantSidebar
          applications={applications}
          offers={offers}
          onSelectApplicant={(applicant) => {
            navigate(`/dashboard/applications/applicants/${applicant.id}`);
            closeDrawer();
          }}
        />
      </Drawer>
      <div className="lg:w-2/3 w-full">
      <ApplicationData
            selectedApplicant={selectedApplicant}
            onUpdateStatus={handleUpdateStatus}
            context="default" // or "hired", "shortlisted", etc.
          />
      </div>
    </div>
  );
};

export default ApplicationDashboard;
