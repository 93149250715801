import React, { useEffect, useState } from 'react';
import ApplyForm from '../components/application/applyForm';
import OfferOverview from '../components/offer/offerOverview';
import ApplyWitProfile from '../components/application/applyWithProfile';
import { useParams } from 'react-router-dom';
import OfferApplyCard from '../components/application/offerApplyPageCard';
import ApplyWitExternLink from '../components/application/applyWithExternLink';
import ApplicationUploadForm from '../components/application/applicationUploadForm';
import { getOfferById } from '../api/offerApi';
import OfferCard from '../components/offer/offerCard';

const Applypage = () => {
    const { id } = useParams();
    const [offer, setOffer] = useState([]);

    useEffect(() => {
        const fetchOffer = async () => {
          try {
            const offerData = await getOfferById(id);
            // console.log(offerData);
            setOffer(offerData);
            // console.log(offer);
            // setLoading(false); // Set loading to false when offers are fetched
    
          } catch (error) {
            console.error('Error fetching offer by user:', error);
          }
        };
    
        fetchOffer();
      }, []);
    
// console.log(offer);
    return (
        <div className='flex  flex-wrap justify-center ' >
            <div className='lg:basis-1/3 flex flex-col  py-4 px-4'>
           <ApplyWitProfile/>
           {/* <ApplyWitExternLink/> */}
           {/* <OfferApplyCard id={id}/> */}
           {offer.map((ofr,index)=>(
           <OfferCard key={index} offer={ofr}/>

           ))}
           </div>
            <div className='w-full lg:basis-2/3 px-4'>
            {/* <ApplyForm/> */}
            <ApplicationUploadForm/>
            </div>
            </div>
    )
}
export default Applypage;