import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Dialog } from '@material-tailwind/react';

export default function OfferOverview({ offer, open, handleOpen, actionType }) {
    
    
    // if (!offer) {
    //     return <div>No offer selected</div>;
    // }
    // else{
    //     // console.log('dd',offer);
    // }
    const {Company} = offer
    const sanitizedDescription = DOMPurify.sanitize(offer.description);

    return (
<Dialog open={open} handler={handleOpen}>
        <div className="flex flex-col gap-4 p-6  bg-white items-start rounded-md h-[600px] overflow-scroll ">
            {/* <div className='sticky top-0 z-50 pt-6 -m-6 rounded-xl bg-clip-border bg-white h-20 shadow-md shadow-gray-900/10'> */}
            <button onClick={handleOpen} >
            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14l-4 -4l4 -4" /><path d="M5 10h11a4 4 0 1 1 0 8h-1" />
            </svg>
            </button>
            <h4
                className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                {offer.title}
            </h4>
            <div className='self-end  mr-0 sm:mr-4'>
            {actionType === 'edit' ? (
                            <Link
                                className=" group flex items-center justify-around gap-4 px-4 py-2 rounded-lg border border-indigo-600 bg-indigo-600 transition-colors hover:bg-transparent focus:outline-none focus:ring"
                                to={`edit/${offer.id}`}
                            >
                                <span className="font-medium text-sm text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500">
                                    Edit
                                </span>
                                <span
                        className="shrink-0 rounded-full border border-current bg-white p-1 text-indigo-600 group-active:text-indigo-500"
                    >
                        <svg
                            className="size-5 rtl:rotate-180"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                        </svg>
                    </span>
                            </Link>
                        ) : actionType === 'empty' ?(
                            <></>
                        ): (
                            <Link
                                className="group flex items-center justify-around gap-4 px-4 py-2 rounded-lg border border-indigo-600 bg-indigo-600 transition-colors hover:bg-transparent focus:outline-none focus:ring"
                                to={`/offers/apply/${offer.id}`}
                            >
                                <span className="font-medium text-sm text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500">
                                    Apply
                                </span>
                                <span
                        className="shrink-0 rounded-full border border-current bg-white p-1 text-indigo-600 group-active:text-indigo-500"
                    >
                        <svg
                            className="size-5 rtl:rotate-180"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                        </svg>
                    </span>
                            </Link>
                          )}
                </div>
                <div className='flex px-4 justify-between items-center w-full'>
                    <div className=' flex flex-col gap-4 '>
                        <div className=' flex flex-row gap-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                            </svg>
                            <Link className='hover:underline  text-md font-medium text-gray-900'>
                                {Company.companyName}
                            </Link>
                            <h1 className=" self-start  text-md font-xs ">
                                <span class="">  {Company.location}</span>
                                <span> · </span>
                                <span> {Company.industry}</span>
                            </h1>


                    </div>
                    <div className=' flex items-center gap-1'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                        </svg>
                        <span className='ml-2 text-md font-xs'>{offer.locationType} </span>
                        <span> · </span>
                        <span className='text-md font-xs'>{offer.employmentType}</span>
                        <span> · </span>
                        <span className='text-md font-xs'>{offer.duration}</span>
                    </div>
                    <div className='flex items-center gap-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z" />
                            </svg>

                        <span className='ml-2 text-md font-xs'>{offer.University.universityName}</span>
                    </div>
                    <div className='flex items-center gap-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />

                            </svg>

                        <span className='ml-2 text-md font-xs'>{offer.salary}</span>
                    </div>
                </div>
                
                {/* <Link to={`/offers/apply/${offer.id}`}

                 className=' group flex items-center justify-around gap-4 px-4 py-2 rounded-lg border border-indigo-600 bg-indigo-600  transition-colors hover:bg-transparent focus:outline-none focus:ring'>
                    <span
                        className="font-medium text-sm text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
                    >
                        Apply
                    </span>

                    <span
                        className="shrink-0 rounded-full border border-current bg-white p-1 text-indigo-600 group-active:text-indigo-500"
                    >
                        <svg
                            className="size-5 rtl:rotate-180"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                        </svg>
                    </span>
                </Link> */}

            </div>
            <h4 className='mt-4 block font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900'>
                About the job
            </h4>
            {/* <p className=' p-4 text-left indent-8 text-sm' dangerouslySetInnerHTML={{ __html: sanitizedDescription }}> */}
            <p className=' px-3 text-left prose' dangerouslySetInnerHTML={{ __html: sanitizedDescription }}>
            
            </p>






            {/* </div> */}
            {/* <p className="block mb-3 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
          Enter your email and password to Sign In.
        </p> */}


            {/* <div className=' p-4  flex flex-col gap-3 '>
                    <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                        offer description
                    </label> */}
            {/* <input
                        type='text'
                        placeholder='Active Input'
                        className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                    /> */}

            {/* </div> */}
        </div>

</Dialog>
    )
}