import React, { useEffect, useState } from 'react';
import { getAllExperiences, getExperienceById } from '../../api/experienceApi';

export default function ExperienceProfileCard({ experiences }) {


    function calculateDuration(start, end) {
        const startDate = new Date(start);
        const endDate = end ? new Date(end) : new Date(); // If endDate is null, use current date
        const diffInMs = Math.abs(endDate - startDate);
        const months = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30)); // Approximation for months
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        return `${years} yr ${remainingMonths} mos`;
    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    }
    return (
        <div className='flex flex-col space-y-4'>
            {experiences.map((experience, index)=> (

                <article key={index} className="rounded-xl bg-white p-4 ring ring-indigo-50 sm:p-6 lg:p-8">

                    <div className="flex items-start sm:gap-8">
                        <div
                            className="hidden sm:grid sm:size-20 sm:shrink-0 sm:place-content-center sm:rounded-full sm:border-2 sm:border-indigo-500"
                            aria-hidden="true"
                        >
                            <div className="flex items-center gap-1">
                                <span className="h-8 w-0.5 rounded-full bg-indigo-500"></span>
                                <span className="h-6 w-0.5 rounded-full bg-indigo-500"></span>
                                <span className="h-4 w-0.5 rounded-full bg-indigo-500"></span>
                                <span className="h-6 w-0.5 rounded-full bg-indigo-500"></span>
                                <span className="h-8 w-0.5 rounded-full bg-indigo-500"></span>
                            </div>
                        </div>

                        <div 
                            className='flex flex-col grow'>
                            {/* <strong
                        className="rounded border border-indigo-500 bg-indigo-500 px-3 py-1.5 text-[10px] font-medium text-white"
                    >
                        Episode #101
                    </strong> */}
                           <h3 className=" self-start  text-lg font-medium sm:text-xl">
                                <span href="#" className="hover:underline"> {experience.jobTitle}</span>
                            </h3>
                            <h1 className=" self-start   font-xs text-sm">
                                <span href="#" className="hover:underline">{experience.companyName} </span>
                                <span> · </span>
                                <span>{experience.employmentType}</span>
                            </h1>

                            {/* <h1 className= " self-start  text-lg font-xs sm:text-sm">
                        <span href="#" className="hover:underline"> {startDate} - present </span>
                        <span> · </span>
                        <span>1 yr 10 mos </span>
                    </h1> */}
                            <h1 className="self-start font-xs text-sm">
                                {experience.currentlyWorking ? (
                                    <>
                                        <span>{formatDate(experience.startDate)} - present</span>
                                        <span> · </span>
                                        <span>{calculateDuration(experience.startDate)}</span>
                                    </>
                                ) : (
                                    <>
                                        <span>{formatDate(experience.startDate)}</span>
                                        <span> ·  </span>
                                        <span>{formatDate(experience.endDate)}</span>
                                    </>
                                )}
                            </h1>

                            <h1 className=" self-start   font-xs text-sm">
                                <span href="#" className="hover:underline"> {experience.location} </span>
                                <span> · </span>
                                <span>{experience.locationType}</span>
                            </h1>
                            <p className="mt-2 text-sm text-gray-700 text-start line-clamp-2">
                                {experience.description}
                            </p>
 </div>
                            {/* <div className="mt-4 sm:flex sm:items-center sm:gap-2">
                        <div className="flex items-center gap-1 text-gray-500">

                            <svg
                                className="h-4 w-4"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                            </svg>

                            <p className="text-xs font-medium">javascript</p>
                        </div>

                        <span className="hidden sm:block" aria-hidden="true">&middot;</span>

                        <p className="mt-2 text-xs font-medium text-gray-500 sm:mt-0">
                            Featuring <a href="#" className="underline hover:text-gray-700">Barry</a>,
                            <a href="#" className="underline hover:text-gray-700">Sandra</a> and
                            <a href="#" className="underline hover:text-gray-700">August</a>
                        </p>
                    </div> */}
                   
                       
                        

                    </div>

                </article>
            ))}

        </div>
    )
}