import React from 'react';
import Select from 'react-select';

const AllSkills = ({ selectedSkills, onChange }) => {
  // List of skills (adjust as per your needs)
  const skills = [
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'Python', label: 'Python' },
    { value: 'Java', label: 'Java' },
    { value: 'C++', label: 'C++' },
    { value: 'C#', label: 'C#' },
    { value: 'Ruby', label: 'Ruby' },
    { value: 'Swift', label: 'Swift' },
    { value: 'PHP', label: 'PHP' },
    { value: 'TypeScript', label: 'TypeScript' },
    { value: 'HTML', label: 'HTML' },
    { value: 'CSS', label: 'CSS' },
    { value: 'React.js', label: 'React.js' },
    { value: 'Angular', label: 'Angular' },
    { value: 'Vue.js', label: 'Vue.js' },
    { value: 'Node.js', label: 'Node.js' },
    { value: 'Express.js', label: 'Express.js' },
    { value: 'Django', label: 'Django' },
    { value: 'Flask', label: 'Flask' },
    { value: 'REST API', label: 'REST API' },
    { value: 'GraphQL', label: 'GraphQL' },
    { value: 'MongoDB', label: 'MongoDB' },
    { value: 'MySQL', label: 'MySQL' },
    { value: 'PostgreSQL', label: 'PostgreSQL' },
    { value: 'Git', label: 'Git' },
    { value: 'UI/UX Design', label: 'UI/UX Design' },
    { value: 'Adobe Photoshop', label: 'Adobe Photoshop' },
    { value: 'Adobe Illustrator', label: 'Adobe Illustrator' },
    { value: 'Figma', label: 'Figma' },
    { value: 'Sketch', label: 'Sketch' },
    { value: 'Arabic', label: 'Arabic' },
    { value: 'English', label: 'English' },
    { value: 'French', label: 'French' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'German', label: 'German' },
  ];

  // Format selected skills for react-select
  const selectedOptions = selectedSkills.map(skill => ({
    value: skill,
    label: skill,
  }));

  return (
    <Select
      options={skills}
      isMulti
      value={selectedOptions}
      onChange={onChange}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder="Select skills..."
    />
  );
};

export default AllSkills;
