import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');

let headers = {};

if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject.token;

  headers = {
    Authorization: token,
  };
}
export const createMessage = async (messageData) => {
    try {
      const response = await axios.post(`${BASE_URL}/chats`, messageData
      // ,{headers: headers,  }
      );
      return response.data;
    } catch (error) {
      // Handle error
      console.error('Error creating message:', error);
      throw new Error('error to create message');
    }
  };
  export const getAllMessages = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/chats`
      /* , { headers } */);
      return response.data;
    } catch (error) {
      console.error('Error fetching all messages:', error);
      throw new Error('Error fetching all messages');
    }
  };
  
  // export const sendMessage = async (messageData) => {
  //   try {
  //     const response = await axios.post(`${BASE_URL}/chats`, messageData/* , { headers } */);
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error sending message:', error);
  //     throw new Error('Error sending message');
  //   }
  // };
  
  export const getMessagesBySender = async (senderId) => {
    try {
      const response = await axios.get(`${BASE_URL}/chats/sender/${senderId}`/* , { headers } */);
      return response.data;
    } catch (error) {
      console.error('Error fetching messages by sender:', error);
      throw new Error('Error fetching messages by sender');
    }
  };
  
  export const getMessagesByConversation = async (conversationId, limit = 20, offset = 0) => {
    try {
        const response = await axios.get(`${BASE_URL}/chats/conversation/${conversationId}`, {
            params: { limit, offset },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching messages by conversation:', error);
        throw new Error('Error fetching messages by conversation');
    }
};
  
  export const getMessagesBySenderAndConversation = async (senderId, conversationId) => {
    try {
      const response = await axios.get(`${BASE_URL}/chats/sender/${senderId}/conversation/${conversationId}`/* , { headers } */);
      return response.data;
    } catch (error) {
      console.error('Error fetching messages by sender and conversation:', error);
      throw new Error('Error fetching messages by sender and conversation');
    }
  };
  
// Fetch a single offer by ID
// export const getOfferById = async (id) => {
//     try {
//       const response = await axios.get(`${BASE_URL}/offers/${id}`,{headers: headers,  });
//       return response.data;
//     } catch (error) {
//       // Handle error
//       console.error(`Error fetching offer with ID ${id}:`, error);
//       throw new Error(`Failed to fetch offer with ID ${id}`);
//     }
//   };