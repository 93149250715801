import React from 'react';

const getStatusSteps = (context) => {

    
    switch (context) {
        case 'validation':
            return [
                { name: 'Accepted', icon: 'M5 13l4 4L19 7' },
                { name: 'Under_Review', icon: 'M6 18L18 6M6 6l12 12' },
                { name: 'Validated', icon: 'M5 13l4 4L19 7' },
                // { name: 'Invalidated', icon: 'M6 18L18 6M6 6l12 12' }
            ];
        case 'hired':
            return [
                { name: 'Accepted', icon: 'M5 13l4 4L19 7' },
                { name: 'Validated', icon: 'M5 13l4 4L19 7' }
            ];
        case 'shortlisted':
            return [
                { name: 'Pending', icon: 'M5 13l4 4L19 7' },
                { name: 'Shortlisted', icon: 'M6 18L18 6M6 6l12 12' },
                { name: 'Interviewed', icon: 'M5 13l4 4L19 7' },
                { name: 'Accepted', icon: 'M5 13l4 4L19 7' }
            ];
        default:
            return [
                { name: 'Pending', icon: 'M5 13l4 4L19 7' },
                { name: 'Shortlisted', icon: 'M6 18L18 6M6 6l12 12' },
                { name: 'Interviewed', icon: 'M5 13l4 4L19 7' },
                { name: 'Accepted', icon: 'M5 13l4 4L19 7' },
                // { name: 'Rejected', icon: 'M6 18L18 6M6 6l12 12' }
            ];
    }
};

const StatusStep = ({ selectedStatus, context }) => {
    const getStatusStep = (status) => {
        switch (status) {
          case 'pending':
           
            return 0;
          case 'shortlisted':
            return 1;
          case 'accepted':
          case 'rejected':
    
            return 2;
           
          case 'validated':
          case 'invalidated':
            return 3;
          
          default:
            return 0;
      }
      };
      const totalSteps = 3;
const progressWidth = (getStatusStep(selectedStatus) / totalSteps) * 100 
    const statuses = getStatusSteps(context);
    
    const currentStatusIndex = statuses.findIndex((s) => s.name.toLowerCase() === selectedStatus.toLowerCase());

    return (
        // <div className="flex flex-wrap my-5 gap-4 w-full">
        //     {statuses.map((step, index) => (
        //         <div key={step.name} className={`shadow-md rounded-xl flex-grow border`}>
        //             <div className="flex flex-col p-4">
        //                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-8 h-8 rounded-full ${index <= currentStatusIndex ? 'bg-green-100' : 'bg-red-100'}`}>
        //                     <path strokeLinecap="round" strokeLinejoin="round" d={index <= currentStatusIndex ? 'M5 13l4 4L19 7' : 'M6 18L18 6M6 6l12 12'} />
        //                 </svg>
        //                 <h3 className="self-center mt-3 font-semibold">{step.name}</h3>
        //             </div>
        //         </div>
        //     ))}
        // </div>
        <div className='p-6 w-full'>
        <h2 className="text-left font-semibold mb-4">Application Status</h2>
        <div>
          <div className="overflow-hidden rounded-full bg-gray-200">
            <div
              className={`h-2 rounded-full bg-blue-500`}
              style={{ width: `${progressWidth}%` }}
            ></div>
          </div>
          <ol className="mt-4 flex justify-between text-sm font-medium text-gray-500 items-center">
            <li className={`flex items-center ${getStatusStep(selectedStatus) >= 0 ? 'text-blue-600' : ''} sm:gap-1.5`}>
              <span className="hidden sm:inline"> Pending </span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 sm:size-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </li>
            <li className={`flex items-center ${getStatusStep(selectedStatus) >= 1 ? 'text-blue-600' : ''} sm:gap-1.5`}>
              <span className="hidden sm:inline"> Shortlisted </span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 sm:size-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
              </svg>
            </li>
            {selectedStatus !== 'rejected' && (
              <li className={`flex items-center ${getStatusStep(selectedStatus) >= 2 ? 'text-blue-600' : ''} sm:gap-1.5`}>
                <span className="hidden sm:inline"> Accepted </span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 sm:size-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                </svg>
              </li>
            )}
            {selectedStatus === 'rejected' && (
              <li className={`flex items-center text-red-600 sm:gap-1.5`}>
                <span className="hidden sm:inline"> Rejected </span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 sm:size-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </li>
            )}
             {selectedStatus !== 'invalidated' &&  (
                <li className={`flex items-center ${getStatusStep(selectedStatus) >= 3 ? 'text-green-600' : ''} sm:gap-1.5`}>
              <span className="hidden sm:inline"> Validated </span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 sm:size-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
              </svg>
            </li>
             )}
            {/* {selectedStatus === 'validated' && (
              <li className={`flex items-center text-green-600 sm:gap-1.5`}>
                <span className="hidden sm:inline"> Validated </span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 sm:size-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
              </li>
            )} */}
            {selectedStatus === 'invalidated' && (
              <li className={`flex items-center text-red-600 sm:gap-1.5`}>
                <span className="hidden sm:inline"> Invalidated </span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 sm:size-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </li>
            )}
            
          </ol>
        </div>
      </div>
    );
};

export default StatusStep;
