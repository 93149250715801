import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');

let headers = {};

if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject.token;

  headers = {
    Authorization: token,
  };
}

// Fetch all conversations
export const getConversations = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/conversations`
    // , { headers }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching conversations:', error);
    throw new Error('Failed to fetch conversations');
  }
};

// Fetch a single conversation by ID
export const getConversationById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/conversations/${id}`
    // , { headers }
    );
    return response.data;
  } catch (error) {
    console.error(`Error fetching conversation with ID ${id}:`, error);
    throw new Error(`Failed to fetch conversation with ID ${id}`);
  }
};

export const getConversationsByParticipant = async (participantId) => {
  try {
    const response = await axios.get(`${BASE_URL}/conversations/participant/${participantId}`/* , { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error fetching conversations for participant with ID ${participantId}:`, error);
    throw new Error(`Failed to fetch conversations for participant with ID ${participantId}`);
  }
};

export const getParticipants = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/conversations/${id}/participants`
    // , { headers }
    );
    return response.data;
  } catch (error) {
    console.error(`Error fetching conversation with ID ${id}:`, error);
    throw new Error(`Failed to fetch conversation with ID ${id}`);
  }
};
// Fetch conversations by company ID
export const getConversationsByCompany = async (companyId) => {
  try {
    const response = await axios.get(`${BASE_URL}/conversations/company/${companyId}`/* , { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error fetching conversations for company with ID ${companyId}:`, error);
    throw new Error(`Failed to fetch conversations for company with ID ${companyId}`);
  }
};

// Create a new conversation
export const createConversation = async (conversationData) => {
  try {
    const response = await axios.post(`${BASE_URL}/conversations`, conversationData
    // , { headers }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating conversation:', error);
    throw new Error('Failed to create conversation');
  }
};

// Update an existing conversation
export const updateConversation = async (id, conversationData) => {
  try {
    const response = await axios.put(`${BASE_URL}/conversations/${id}`, conversationData
    // , { headers }
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating conversation with ID ${id}:`, error);
    throw new Error(`Failed to update conversation with ID ${id}`);
  }
};

// Delete a conversation by ID
export const deleteConversation = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/conversations/${id}`/* , { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error deleting conversation with ID ${id}:`, error);
    throw new Error(`Failed to delete conversation with ID ${id}`);
  }
};
