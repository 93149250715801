import React, { useContext, useEffect, useState } from 'react';
import ChatList from '../components/chat/chatList';
import ChatMessages from '../components/chat/chatMessages';
import { useNavigate, useParams } from 'react-router-dom';
import { getConversationById } from '../api/conversationApi';
import { UserContext } from '../contexts/userContext';
import { getUserProfileById } from '../api/userApi';

const ChatPage = () => {
  // const [selectedConversation, setSelectedConversation] = useState(null);
  const { conversationId } = useParams();
  const navigate = useNavigate();
  const [participantId, setParticipantId] = useState(null);
  const [participantName, setParticipantName] = useState('');
  const { user } = useContext(UserContext);
  const currentUserId = user && user.userId;

  const handleConversationSelect = (conversation, participantId, participantName) => {
    // setSelectedConversation(conversation.id);
    setParticipantId(participantId);
    setParticipantName(participantName);
    navigate(`/chat/${conversation.id}`);

  };

  const fetchConversationDetails = async (convId) => {
    const conversation = await getConversationById(convId);
    const participantId = conversation.participant1Id !== user.userId
      ? conversation.participant1Id
      : conversation.participant2Id;
    const profileData = await getUserProfileById(participantId);
    const participantName = profileData.role === 'company'
      ? profileData.profileData.companyName
      : profileData.role === 'university'
      ? profileData.profileData.universityName
      : profileData.profileData.firstName;
    setParticipantId(participantId);
    setParticipantName(participantName);
  };


  useEffect(() => {
   if (conversationId) {
      fetchConversationDetails(conversationId);
    } else {
      setParticipantId(null);
      setParticipantName('');
    }
  }, [conversationId]);
  return (
    <div className="flex h-screen bg-white">
      <div className="w-1/4 p-4 border-r border-gray-300">
        <input type="text" placeholder="Search..." className="w-full p-2 mb-4 border rounded-full" />
        <ChatList onConversationSelect={handleConversationSelect} />
      </div>
      <div className="w-3/4 p-4 flex flex-col">
         {conversationId ? (
          <ChatMessages
            selectedConversation={conversationId}
            participantId={participantId}
            participantName={participantName}
          />
        ) : (
          <div className="flex justify-center items-center h-full text-gray-500">
            Select a conversation to start chatting
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatPage;
