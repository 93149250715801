import React, { useEffect ,useContext, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import NavbarIn from './navbarIn';
import NavbarOut from './navbarOut';


function Header() {
    
    const { isAuthenticated } = useContext(AuthContext);
    useEffect(() => {
        // console.log('Is authenticated:', isAuthenticated());
    }, [isAuthenticated]);
    
    return (
         <>
                {isAuthenticated() ? < NavbarOut/> : <NavbarIn />}
         

     </>
 )
}
export default Header;