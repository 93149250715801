
import React, { useState } from 'react';
import { Dialog } from '@material-tailwind/react';
import { updateStudent } from '../../api/studentApi';
import { useNavigate } from 'react-router-dom';
import AllSkills from '../common/allskills';

export default function SkillDialog({ open, handleOpen, studentId, currentSkills, setSkills, skills }) {


    const [items, setItems] = useState([]);
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [selectedSkills, setSelectedSkills] = useState([]);

    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSkillChange = (selectedOptions) => {
        setSelectedSkills(selectedOptions.map(option => option.value));
      };

    // const handleAddItem = () => {
    //     if (inputValue.trim() !== '') {
    //         setSkills((prevSkills) => {
    //             const newSkills = prevSkills ? [...prevSkills, inputValue] : [inputValue];
    //             return newSkills;
    //         });
    //         setInputValue(''); // Clear input after adding item
    //     }
    // };
    const handleSaveSkills = async () => {
        try {
            await updateStudent(studentId, { skills: selectedSkills });
            setSkills(selectedSkills); // Update local state with selected skills

            setAlert({ type: 'success', message: 'skills updated successfully!' });
            handleOpen();

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);

                } else if (error.response.status === 403) {
                    setAlert({ type: 'error', message: 'Forbidden. You do not have permission to add education.' });
                    setTimeout(() => {
                        navigate('/unauthorized');
                    }, 1000);


                } else {
                    setTimeout(() => {
                        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

                    }, 1000);
                }
            } else {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

            }

        }
    };

    const handleCloseAlert = () => {
        setAlert({ type: '', message: '' });
    };
    return (

        <Dialog open={open} handler={handleOpen}>
            {alert.type === 'success' && (
                <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-green-500 rounded-lg shadow-lg opacity-100 font-regular">
                    {alert.message}
                    <button
                        className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                        type="button"
                        onClick={handleCloseAlert}
                    >
                        Close
                    </button>
                </div>
            )}
            {alert.type === 'error' && (
                <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
                    {alert.message}
                    <button
                        className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                        type="button"
                        onClick={handleCloseAlert}
                    >
                        Close
                    </button>
                </div>
            )}

            <div class="flex flex-col gap-4 px-6 max-h-screen ">
                <div className='sticky top-0 z-50 p-4 rounded-xl bg-clip-border bg-white '>
                    <h4
                        className=" block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                        Add Skill
                    </h4>
                </div>



                <div className=' p-4 pt-1 flex flex-col gap-3  '>
                    <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                        Skills
                    </label>
                    <div className='flex justify-between items-center gap-4'>
                        <div className='grow'>
                        <AllSkills selectedSkills={selectedSkills} onChange={handleSkillChange}/>
                            {/* <input
                                type='text'
                                placeholder='ex : project management'
                                className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                                value={inputValue}
                                onChange={handleInputChange}
                            /> */}
                        </div>
                        <div>
                            {/* <button
                                className="inline-flex items-center justify-center rounded-full border border-black bg-transparent   px-3 py-1 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-100 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3"
                                type="button"
                                onClick={handleAddItem}
                            >
                                <span className=''>

                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" className="w-6 h-6">
                                        <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                                    </svg>
                                </span>
                            </button> */}
                        </div>
                    </div>
                </div>
                {/* <div className=" rounded-xl bg-gray-200  ring ring-indigo-50 sm:p-6 lg:p-6 mt-12 lg:flex lg:flex-wrap lg:items-center  lg:justify-start space-x-3 space-y-3">

                    {currentSkills && currentSkills.length > 0 ? (
                        currentSkills.map((item, index) => (
                            <span
                                key={index}
                                className="inline-flex items-center justify-center rounded-full border border-transparent bg-amber-200 px-7 py-3 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-4 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3"
                            >
                                {item}
                            </span>
                        ))
                    ) : (
                        <p>No skills available.</p>

                    )
                    }


                </div> */}

                <div class="flex p-6 pt-4 gap-4">
                    <button
                        class="block w-48 select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                        onClick={handleSaveSkills}>
                        Save
                    </button>
                    <button
                        class="block w-48 select-none rounded-lg bg-white py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-black border-black shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                        onClick={handleOpen}
                    >
                        Cancel
                    </button>
                </div>
            </div>





        </Dialog>
    )
}