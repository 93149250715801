import React, { useContext, useEffect, useState } from 'react';
import Editor from '../common/editor';
import { createOffer, getOfferById, updateOffer } from '../../api/offerApi';
import { UserContext } from '../../contexts/userContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createCanBoundTo } from '@casl/react';
import useDefineAbilities from '../../routes/ability';
import { getUniversities } from '../../api/universityApi';

export default function EditOffer() {
    const { id } = useParams(); // Extract offer ID from URL
    // const Can = createCanBoundTo(useDefineAbilities());
    // if (!ability.can('update', 'InternshipOffer')) {
    //     // If the user is not authorized to update InternshipOffer, redirect or show an error message
    //     return <div>You do not have permission to edit this offer.</div>;
    //   }
    const [editorValue, setEditorValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [offer, setOffer] = useState(null);
    const [universities, setUniversities] = useState([]);

    const { userData } = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUniversities = async () => {
            try {
                const data = await getUniversities();
                setUniversities(data);
            } catch (error) {
                console.error('Error fetching universities:', error);
            }
        };

        fetchUniversities();
    }, []);

    const [formData, setFormData] = useState({
        title: '',
        employmentType: '',
        location: '',
        locationType: '',
        salary: '',
        duration: '',
        description: editorValue,
        companyId: '',
        universityId: null,
    })


    const [alert, setAlert] = useState({ type: '', message: '' });

    useEffect(() => {
        const fetchOffer = async () => {
            try {
                const data = await getOfferById(id);
                const offer = data[0];

                setOffer(offer);
                // console.log(offer);
                setFormData({
                    title: offer.title,
                    employmentType: offer.employmentType,
                    location: offer.location,
                    locationType: offer.locationType,
                    salary: offer.salary,
                    duration: offer.duration,
                    description: offer.description,
                    companyId: offer.companyId,
                    universityId: offer.universityId,
                });
                // console.log(formData);
                setEditorValue(offer.description);
                setLoading(false);
            } catch (error) {
                setAlert({ type: 'error', message: 'Error fetching offer data. Please try again.' });
            }
        };

        fetchOffer();
    }, [id]);

    useEffect(() => {
        if (userData && userData.id) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                companyId: userData.id,
            }));
            setLoading(false);
        }
    }, [userData]);

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            description: editorValue,
        }));
    }, [editorValue]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // console.log(formData);
            // console.log(formData);
            await updateOffer(id, formData);
            //add model to say that offer added successfully 
            setAlert({ type: 'success', message: 'Offer created successfully!' });
            setTimeout(() => {
                navigate('/dashboard/offers');
            }, 2000);


        } catch (error) {
            // setAlert({ type: 'error', message: 'Error creating offer. Please try again.' });
            if (error.response) {
                if (error.response.status === 401) {
                    setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);

                } else if (error.response.status === 403) {
                    setAlert({ type: 'error', message: 'Forbidden. You do not have permission to edit this offer.' });
                    setTimeout(() => {
                        navigate('/unauthorized');
                    }, 1000);


                } else {
                    setTimeout(() => {
                        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

                    }, 1000);
                }
            } else {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

            }

        }
    };
    const handleCloseAlert = () => {
        setAlert({ type: '', message: '' });
    };
    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <>
            <div className='fixed  top-24 right-10 space-y-4 z-50'>
                {alert.type === 'success' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-green-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
                )}
                {alert.type === 'error' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
                )}
            </div>
            <div className='bg-white rounded-lg shadow-md p-6'>
                <span className='font-semibold text-2xl text-blue-gray-700'>

                    Edit Job/Internship offer
                </span>
                <form onSubmit={handleSubmit} className="space-y-4">

                    <div className="flex flex-col gap-3  ">

                        <label className='self-start block text-base  font-medium text-dark dark:text-white'>
                            Title *
                        </label>
                        <div className='relative'>
                            <input
                                type='text'
                                name='title'
                                value={formData.title}
                                onChange={handleChange}
                                placeholder='Enter Title'
                                className='w-full  px-5 bg-transparent rounded-md border border-primary py-[10px]  text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                            />
                            {/* <span className='absolute top-1/2 left-4 -translate-y-1/2'>
                                        <svg
                                            width={20}
                                            height={20}
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M3.72 12.886a4.167 4.167 0 0 1 2.947-1.22h6.666a4.167 4.167 0 0 1 4.167 4.167v1.666a.833.833 0 1 1-1.667 0v-1.666a2.5 2.5 0 0 0-2.5-2.5H6.667a2.5 2.5 0 0 0-2.5 2.5v1.666a.833.833 0 1 1-1.667 0v-1.666a4.17 4.17 0 0 1 1.22-2.947ZM10 3.333a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-4.166 2.5a4.167 4.167 0 1 1 8.333 0 4.167 4.167 0 0 1-8.333 0Z"
                                                opacity={0.8}
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                fill="#9CA3AF"
                                            />
                                        </svg>
                                    </span> */}
                        </div>
                        <div className='flex gap-4 items-end'>
                            <div className='flex flex-col gap-3 basis-1/2'>

                                <label className='self-start block text-base font-medium text-dark dark:text-white'>
                                    Employment type
                                </label>
                                <div className='relative z-20'>
                                    <select
                                        name="employmentType"
                                        className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
                                        value={formData.employmentType}
                                        onChange={handleChange} >

                                        <option value='' className='dark:bg-dark-2'>Select employment type</option>
                                        <option value='full time' className='dark:bg-dark-2'>Full time</option>
                                        <option value='half time' className='dark:bg-dark-2'>Half time</option>
                                        <option value='freelance' className='dark:bg-dark-2'>Freelance</option>
                                    </select>
                                    <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                                </div>
                            </div>
                            <div className='flex flex-col gap-3 basis-1/2'>
                                <label className='self-start block text-base  font-medium text-dark dark:text-white'>
                                    Duration
                                </label>
                                <div className='relative'>
                                    <input
                                        type='text'
                                        name='duration'
                                        value={formData.duration}
                                        onChange={handleChange}
                                        placeholder='Enter Duration'
                                        className='w-full  px-5 bg-transparent rounded-md border border-primary py-[10px]  text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-4'>
                            <div className='flex flex-col gap-3 basis-1/2'>
                                <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                                    Location
                                </label>
                                <input
                                    type='text'
                                    name='location'
                                    value={formData.location}
                                    onChange={handleChange}
                                    placeholder='Enter Title'
                                    className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                                />
                            </div>

                            <div className='flex flex-col gap-3 basis-1/2'>
                                <label className='self-start block text-base font-medium text-dark dark:text-white'>
                                    Location type
                                </label>
                                <div className='relative z-20'
                                >
                                    <select
                                        name="locationType"
                                        value={formData.locationType}
                                        onChange={handleChange}
                                        className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'>
                                        <option value='' className='dark:bg-dark-2'>Select employment type</option>

                                        <option value='on site' className='dark:bg-dark-2'>on site</option>

                                        <option value='remote' className='dark:bg-dark-2'>remote</option>
                                        <option value='hybrid' className='dark:bg-dark-2'>hybrid</option>
                                    </select>
                                    <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                                </div>

                            </div>
                        </div>

                        <label className='self-start block text-base  font-medium text-dark dark:text-white'>
                            Salary
                        </label>
                        <div className='relative'>
                            <input
                                type='text'
                                name='salary'
                                value={formData.salary}
                                onChange={handleChange}
                                placeholder='Enter Salary'
                                className='w-full  px-5 bg-transparent rounded-md border border-primary py-[10px]  text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                            />
                        </div>
                        <div className='flex flex-col gap-3 basis-1/2'>
                            <label className='self-start block text-base font-medium text-dark dark:text-white '>
                                University
                            </label>
                            <select
                                // type='text'
                                name='universityId'
                                className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
                                value={formData.universityId}
                                onChange={handleChange}
                            >
                                <option value=''>Select university</option>
                                {universities.map((uni) => (
                                    <option key={uni.id} value={uni.id} className='dark:bg-dark-2'>{uni.universityName}</option>
                                ))}
                            </select>

                        </div>

                        <label className='self-start block text-base  font-medium text-dark dark:text-white'>
                            Desciption
                        </label>
                        <Editor value={editorValue} onChange={setEditorValue} />



                    </div>
                    <div className='flex gap-4 justify-center'>
                    <button type="submit"
                        className="bg-blue-500 text-white py-2 px-4 rounded-md"

                    >
                        Edit
                    </button>
                    <Link to="/dashboard/offers"
                        className="bg-gray-500 text-white py-2 px-4 rounded-md"

                    >
                        Cancel
                    </Link>
                    </div>
                </form>



            </div>
        </>
    );
}