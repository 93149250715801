import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../contexts/userContext';
import { MessageDialog } from '../application/messageDialog';

export default function ProfileHeadline({ profileData }) {
    const { userData } = useContext(UserContext);
   
    // console.log(profileData.profileData);
    return (

        <div className="bg-white w-full mx-auto ">
            <div className="flex flex-col">

                <div className="w-full bg-gray-100 lg:h-24 h-12 ">

                </div>
                <div className='w-full rounded-lg '>
                    <div className="lg:-mt-20 -mt-10 ml-5  h-40 w-40 rounded-full ">
                        <img
                            src={
                                profileData && profileData.user === 'company'
                                    ? '/companyProfile.jpeg'
                                    : profileData && profileData.user === 'university'
                                        ? '/universityProfile.jpeg'
                                        : profileData && profileData.user === 'student'
                                            ? '/studentProfile.jpeg'
                                            : null
                            }
                            alt='profile Picture'
                            // {
                            //     profileData && profileData.user === 'company'
                            //         ? profileData.profileData.companyName
                            //         : profileData && profileData.user === 'university'
                            //         ? profileData.profileData.universityName
                            //         : profileData && profileData.user === 'student'
                            //             ? profileData.profileData.firstName + ' ' + profileData.profileData.lastName
                            //             : null
                            //     }
                            className="relative inline-block h-full w-full rounded-full  object-cover object-center "
                        />
                    </div>
                </div>
                {/* <div >
                            <button className=" -left-9 relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase text-gray-900 transition-all  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button">
                            <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 z-50">
                                    <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32l8.4-8.4Z" />
                                    <path d="M5.25 5.25a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3V13.5a.75.75 0 0 0-1.5 0v5.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V8.25a1.5 1.5 0 0 1 1.5-1.5h5.25a.75.75 0 0 0 0-1.5H5.25Z" />
                                </svg>
                                </span>
                            </button>
                        </div> */}
                {/* </div> */}

                <div className="bg-white  shadow-md rounded-md bg-clip-border   px-12 py-8 flex flex-col gap-2">

                    <div class="flex justify-between items-center text-left">
                        <div className=''>
                            {profileData && (
                                <>
                                    <h2 className="text-3xl font-bold text-zinc-700">
                                        {profileData && profileData.user === 'company'
                                            ? profileData.profileData.companyName
                                            : profileData && profileData.user === 'university'
                                                ? profileData.profileData.universityName
                                                : profileData && profileData.user === 'student'
                                                    ? `${profileData.profileData.firstName} ${profileData.profileData.lastName}`
                                                    : null}
                                    </h2>
                                    <p className="text-zinc-700 text-lg">{profileData.profileData.headline}</p>
                                    <p className="font-light text-lg text-zinc-500">
                                        {profileData && profileData.user === 'student'
                                            ? profileData.profileData.location
                                            : profileData && profileData.user === 'company'
                                                ? `${profileData.profileData.location}  -  ${profileData.profileData.industry}`
                                                : profileData && profileData.user === 'university'
                                                    ? profileData.profileData.location
                                                    : null}
                                    </p>
                                </>
                            )}
                        </div>

                        
                            {userData && userData.userId !== profileData.profileData.userId && (
                                <MessageDialog
                                    recipientId={profileData.profileData.userId}
                                    firstname={
                                        profileData && profileData.user === 'company'
                                            ? profileData.profileData.companyName
                                            : profileData && profileData.user === 'university'
                                                ? profileData.profileData.universityName
                                                : profileData && profileData.user === 'student'
                                                    ? `${profileData.profileData.firstName} ${profileData.profileData.lastName}`
                                                    : null
                                    }
                                />
                            )}



                        
                    </div>
                </div>
            </div>
        </div>
    )
}