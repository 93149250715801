import React, { useState, useEffect, useContext, useRef } from 'react';
import { io } from 'socket.io-client';
import { useSocket } from '../../contexts/socketContext';
import { UserContext } from '../../contexts/userContext';
import { createMessage, getMessagesByConversation } from '../../api/chatApi';
// import ImageViewer from 'react-simple-image-viewer';
import AttachmentHandler from './attachmentHandler';
import { downloadFile } from '../../api/fileApi';
import Viewer from 'react-viewer';
import { Spinner } from '@material-tailwind/react';
const BASE_URL = process.env.REACT_APP_IMG_PREVIEW_URL; // Adjust this to your server address

const ChatMessages = ({ selectedConversation, participantId, participantName }) => {
    const socket = useSocket();
    const { user } = useContext(UserContext);
    const currentUserId = user && user.userId;
    // const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');

    const [username, setUsername] = useState('');
    const [viewerVisible, setViewerVisible] = useState(false);
    const [viewerImages, setViewerImages] = useState([]);
    //   const { conversationId } = useParams();
    // console.log(participantId);

    const [offset, setOffset] = useState(null)
    // const [limit, setLimit] = useState(10)

    const [isFetching, setIsFetching] = useState(false);
    const messagesEndRef = useRef(null);
    const messagesTopRef = useRef(null);
    const limit = 10;

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToTop = () => {
        messagesTopRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (!selectedConversation) {
            return;
        }
        // Reset messages and offset when selectedConversation changes
        setMessages([]);
        setOffset(null);

    }, [selectedConversation]);

    

    useEffect(() => {
        if (!selectedConversation || isFetching) {
            return;
        }
        const fetchMessages = async () => {
            setIsFetching(true);

            try {
                const { transformedMessages: fetchedMessages, offset: fetchedOffset } = await getMessagesByConversation(selectedConversation, limit, offset);

                // console.log(fetchedMessages);
                const formattedMessages = fetchedMessages.map(message => ({
                    ...message,
                    direction: message.senderId === currentUserId ? 'outgoing' : 'incoming',
                    content: message.ChatFiles && message.ChatFiles.length > 0 ? '' : message.content,
                }));
                scrollToBottom();
                setMessages(prevMessages => [...formattedMessages, ...prevMessages]);
                setOffset(fetchedOffset);


                setIsFetching(true)
                // console.log(messages);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
            setIsFetching(false);

            // console.log(offset);
        };
        fetchMessages();
    }, [currentUserId, offset]);



    useEffect(() => {
        setMessages([]);
        setOffset(null);
        // console.log(selectedConversation);
    }, [selectedConversation, participantId]);



    const handleScroll = (e) => {
        if (e.target.scrollTop === 0 && offset > 0 && !isFetching) {
            setOffset(prevOffset => Math.max(prevOffset - limit, 0)); // Adjust offset when scrolling to fetch more messages
        }

    };


    useEffect(() => {
        if (!socket || !selectedConversation) return;
        socket.emit('leaveRoom');

        socket.emit('joinRoom', selectedConversation);

        socket.on('message', (data) => {
            if (data.conversationId === selectedConversation) {
                // console.log('yes');

                setMessages((prevMessages) => [...prevMessages, data]);
            }
        });
        socket.on('file', (fileData) => {
            
            if (fileData.conversationId == selectedConversation) {
                // console.log('yes');
                const content = fileData.content.startsWith('ChatFile:') ? '' : fileData.content
                // console.log(content);
                setMessages((prevMessages) => [...prevMessages, fileData]);
            }
        });


        return () => {
            socket.off('message');
            socket.off('file');
        };
    }, [socket, selectedConversation]);

    //   useEffect(() => {
    //     messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    //   }, [messages]);

    const sendMessage = async () => {
        if (!socket || message.trim() === '' || !selectedConversation) return;
        try {
            const newMessage = {
                conversationId: selectedConversation,
                sender: username,
                senderId: currentUserId,
                recipientId: participantId,
                direction: 'outgoing',
                content: message,
            };
            // console.log(newMessage);
            await createMessage(newMessage);
            socket.emit('message', newMessage);
            setMessages(prevMessages => [...prevMessages, newMessage]);
            setMessage('');
        } catch (error) {
            console.error('Failed to send message:', error);
        }
    };

    const handleAttachmentUpload = (uploadedData) => {
        const { message, file } = uploadedData;
        const newMessageWithFile = { ...message, ChatFiles: [file], direction: 'outgoing', content: '' };

        const reader = new FileReader();
        reader.readAsDataURL(new Blob([file.filePath], { type: file.fileType }));
        reader.onloadend = () => {
            const base64File = reader.result;
            const fileData = {
                ...file,
                fileContent: base64File,
            };
            //   console.log(base64File,"salut",fileData);
            socket.emit('file', { ...newMessageWithFile, ChatFiles: [fileData] });
            setMessages((prevMessages) => [...prevMessages, newMessageWithFile]);
        };

        reader.onerror = (error) => {
            console.error('Error reading file:', error);
        };
    };
    //   const handleAttachmentUpload = (uploadedData) => {
    //     const { message, file } = uploadedData;
    //     // console.log(uploadedData);
    //     const newMessageWithFile = { ...message, files: [file], direction: 'outgoing' };
    //         console.log(newMessageWithFile,file);
    //     socket.emit('file', file);
    //     setMessages(prevMessages => [...prevMessages, file]);
    //     console.log(messages);
    //   };

    const handleAttachClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleDownload = async (fileId) => {
        try {
            await downloadFile(fileId);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const renderFileIcon = (fileType) => {
        if (!fileType) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3v18h18V3H3z" />
            </svg>; // Default icon for undefined fileType
        }

        if (fileType.startsWith('image/')) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-file-type-jpg"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" /><path d="M11 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" /><path d="M20 15h-1a2 2 0 0 0 -2 2v2a2 2 0 0 0 2 2h1v-3" /><path d="M5 15h3v4.5a1.5 1.5 0 0 1 -3 0" /></svg>;
            // Image icon
        } else if (fileType === 'application/pdf') {
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-file-type-pdf"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" /><path d="M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" /><path d="M17 18h2" /><path d="M20 15h-3v6" /><path d="M11 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z" /></svg>;

        } else if (fileType === 'application/png') {
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-file-type-png"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" /><path d="M20 15h-1a2 2 0 0 0 -2 2v2a2 2 0 0 0 2 2h1v-3" /><path d="M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" /><path d="M11 21v-6l3 6v-6" /></svg>

        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-file"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /></svg>;
            // Default icon for other file types
        }
    };

    //   const renderFiles = (files,msg) => {
    //     return files.map((file, idx) => (
    //         <div key={idx} className="file-preview  p-2 border rounded-lg flex items-center justify-center">
    //             <div className=''>
    //             {renderFileIcon(file.fileType)}
    //             </div>
    //             <button
    //                 onClick={() => handleDownload(file.filePath, file.fileName)}
    //                 className={`ml-2 underline ${msg.direction === 'outgoing' ? 'text-white' : 'text-blue-500'}`}
    //                 >
    //                 {file.fileName}
    //             </button>
    //         </div>
    //     ));
    // };

    const renderFiles = (files, msg) => {
        return files.map((file, idx) => {
            if (file.fileType.startsWith('image/')) {
                const imageUrl = `${BASE_URL}/${file.filePath}`;
                return (
                    <div key={idx} className=" px-2 py-1 items-start flex ">
                        <img
                            src={imageUrl}
                            alt={file.fileName}
                            className="w-24 h-24 object-cover cursor-pointer"
                            onClick={() => {
                                setViewerImages([{ src: imageUrl, alt: file.fileName }]);
                                setViewerVisible(true);
                            }}
                        />
                        <button
                            onClick={() => handleDownload(file.id)}
                            className={`ml-2 underline ${msg.direction === 'outgoing' ? 'text-white' : 'text-blue-500'}`}
                        >
                            {file.fileName}
                        </button>
                    </div>
                );
            } else {
                return (
                    <div key={idx} className=" px-2 py-1 rounded-lg flex items-center">
                        {renderFileIcon(file.fileType)}

                        <button
                            onClick={() => handleDownload(file.id)}
                            className={`ml-2 underline ${msg.direction === 'outgoing' ? 'text-white' : 'text-blue-500'}`}
                        >
                            {file.fileName}
                        </button>
                    </div>
                );
            }
        });
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };


    return (
        <div className="flex flex-col flex-1 max-h-full ">
            <div className="flex items-center justify-normal gap-6 p-4 border-b border-gray-300">
                <div className="flex-shrink-0">
                    <img
                        src="/studentProfile.jpeg"
                        alt="Avatar"
                        className="w-10 h-10 rounded-full"
                    />
                </div>
                <div className="text-lg text-center font-semibold">{participantName}</div>
            </div>
            <div className="flex-1 p-4 overflow-y-auto flex flex-col" onScroll={handleScroll}>
                {isFetching  && <Spinner />}
                <div ref={messagesTopRef} />
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`message-item ${msg.direction === 'outgoing' ? 'self-end bg-blue-500 text-white' : 'self-start bg-gray-200'} p-1 rounded-lg mb-2 max-w-xs text-left`}
                    >
                        {msg.content && <div className='px-2 py-1 rounded-lg flex items-center'>{msg.content}</div>}
                        {msg.ChatFiles && renderFiles(msg.ChatFiles, msg)}
                    </div>
                ))}
                {/* <div ref={messagesEndRef} /> */}
                <Viewer
                    visible={viewerVisible}
                    onClose={() => setViewerVisible(false)}
                    images={viewerImages}

                />
            </div>
            <div className="p-4 border-t border-gray-300 flex items-center">
                <button onClick={handleAttachClick} className="mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                    </svg>

                </button>
                <AttachmentHandler senderId={currentUserId} recipientId={participantId} onUpload={handleAttachmentUpload} />
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyDown}

                    className="flex-1 mr-2 border p-2 rounded-lg w-40 sm:w-auto"
                />
                <button onClick={sendMessage} className=" text-white p-2 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="blue" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                    </svg>
                </button>
            </div>

            {/* {viewerVisible && (
        <ImageViewer
          src={viewerImages.map(img => img.src)}
          currentIndex={0}
          onClose={() => setViewerVisible(false)}
        />
      )} */}
        </div>
    );
};

export default ChatMessages;
