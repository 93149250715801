import React from 'react';
import Sidebar from '../components/sideBar/sideBar';
import Header from '../components/common/header';
import { Outlet } from 'react-router-dom';
import NavbarDashboard from '../components/common/navbarDashboard';

function LayoutDashboard() {
    return (
        <div className=" flex min-h-screen overflow-hidden  ">
            <Sidebar />

            <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden ">
                <NavbarDashboard />
                <main>
                    <div className="bg-gray-100  p-4 md:p-6 2xl:p-10 ">
                        {/* {children} */}
                        <Outlet/>
                    </div>
                </main>
            </div>
        </div>
    )
}
export default LayoutDashboard;