import React from 'react';

export default function CertificateProfilCard({certificates}) {
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    }
    return (
        <div className='flex flex-col space-y-4'>
            {certificates.map(certificate => (
        <article class="rounded-xl bg-white p-4 ring ring-indigo-50 sm:p-6 lg:p-8">
            <div class="flex items-start sm:gap-8">
                <div
                    class="hidden sm:grid sm:size-20 sm:shrink-0 sm:place-content-center sm:rounded-full sm:border-2 sm:border-indigo-500"
                    aria-hidden="true"
                >
                    <div class="flex items-center gap-1">
                        <span class="h-8 w-0.5 rounded-full bg-indigo-500"></span>
                        <span class="h-6 w-0.5 rounded-full bg-indigo-500"></span>
                        <span class="h-4 w-0.5 rounded-full bg-indigo-500"></span>
                        <span class="h-6 w-0.5 rounded-full bg-indigo-500"></span>
                        <span class="h-8 w-0.5 rounded-full bg-indigo-500"></span>
                    </div>
                </div>

                <div className='flex flex-col grow '>
                    {/* <strong
                        class="rounded border border-indigo-500 bg-indigo-500 px-3 py-1.5 text-[10px] font-medium text-white"
                    >
                        Episode #101
                    </strong> */}

                    <h3 class= " self-start  text-lg font-medium sm:text-xl">
                        <span href="#" class="hover:underline">{certificate.certificateName }</span>
                    </h3>
                    <h1 class= " self-start   font-xs text-sm">
                        <span href="#" class="hover:underline"> {certificate.organizationName}  </span>
                        {/* <span> · </span>
                        <span>field of study </span> */}
                    </h1>

                    <h1 class= " self-start   font-xs text-sm">
                        <span href="#" class="hover:underline"> {formatDate(certificate.certificateDate)}  </span>
                        {/* <span> · </span>
                        <span>1 yr 10 mos </span> */}
                    </h1>

                    
                </div>



                        

                    </div>
                </article>
            ))}

        </div>
    )
} 