import React, { useState, useEffect, useContext } from 'react';

// import { getApplicationByCompanyId } from '../api'; // Import your API function
import ApplicationData from '../../components/application/applicantData';
import { getApplicationByCompanyId, getApplicationsByStatuses, getApplicationsByUniversityId, updateApplication, updateApplicationStatus } from '../../api/applicationApi';
import { UserContext } from '../../contexts/userContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getOfferByCompanyId } from '../../api/offerApi';
import ApplicantShortListedSidebar from '../../components/application/applicantShortlistedSidebar';
import { Drawer, IconButton, Spinner } from '@material-tailwind/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
const ApplicationHiredDashboard = (/* { userData } */) => {
  const [applications, setApplications] = useState([]);
  const [offers, setOffers] = useState([]);
  const [alert, setAlert] = useState({ type: '', message: '' });

  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const { userData, user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const { applicationId } = useParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(!userData)      setLoading(true);

    const fetchShortlistedApplicants = async () => {
        try {
          let applicationData;
          if (user.role === 'university') {
              applicationData = await getApplicationsByUniversityId(userData.id, ["validated"]);
          } else if (user.role === 'company') {
              applicationData = await getApplicationByCompanyId(userData.id, ["validated"]);
          }        const offerData = await getOfferByCompanyId(userData.id);
        setApplications(applicationData);
        setOffers(offerData.offers);
        setLoading(false);
      } catch (error) {
        setAlert({ type: 'error', message: 'Error fetching shortlisted applicants. Please try again.' });
        setLoading(false);
      }
    };
// console.log(offers);
    fetchShortlistedApplicants();
  }, [userData]);

  useEffect(() => {
    if (applicationId) {
      const selected = applications.find(app => app.id === parseInt(applicationId));
      setSelectedApplicant(selected);
    }

  }, [applicationId, applications]);

  const handleUpdateStatus = async (applicantId, newStatus) => {
    try {
        // console.log(applicantId, newStatus);

      await updateApplicationStatus(applicantId, newStatus);
      setApplications((prev) =>
        prev.map((app) =>
          app.id === applicantId ? { ...app, status: newStatus } : app
        )
      );
      if (selectedApplicant && selectedApplicant.id === applicantId) {
        setSelectedApplicant((prev) => ({ ...prev, status: newStatus }));
      }
    } catch (error) {
      console.error('Error updating application status:', error);
    }
  };
  if (loading) {
    return <div className="flex justify-center items-center"><Spinner /></div>;
}
  return (
    <>
    <div className='fixed top-24 right-10 space-y-4 z-50'>
        {alert.type === 'error' && (
          <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
            {alert.message}
            <button
              className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
              type="button"
              onClick={() => setAlert({ type: '', message: '' })}
            >
              Close
            </button>
          </div>
        )}
      </div>
      <div className="flex bg-white lg:bg-transparent">
      <div className="lg:w-1/3 hidden lg:flex">
      <ApplicantShortListedSidebar
       applications={applications}
       offers={offers}
       onSelectApplicant={(applicant) => navigate(`/dashboard/applications/hired/${applicant.id}`)}
     />
   </div>
   <IconButton
     className="lg:hidden"
     variant="text"
     size="lg"
     onClick={isDrawerOpen ? closeDrawer : openDrawer}
   >
     {isDrawerOpen ? (
       <XMarkIcon className="h-8 w-8 stroke-2" />
     ) : (
       <Bars3Icon className="h-8 w-8 stroke-2" />
     )}
   </IconButton>
   <Drawer
     className="drawer"
     open={isDrawerOpen}
     onClose={closeDrawer}
   >
     <ApplicantShortListedSidebar
       applications={applications}
       offers={offers}
       onSelectApplicant={(applicant) => {
         navigate(`/dashboard/applications/hired/${applicant.id}`);
         closeDrawer();
       }}
     />
   </Drawer>
   <div className="lg:w-2/3 w-full">
          <ApplicationData
            selectedApplicant={selectedApplicant}
            onUpdateStatus={handleUpdateStatus}
            context="hired" // or "hired", "shortlisted", etc.
          />
    </div>
    </div>
    </>
  );
};

export default ApplicationHiredDashboard;
