import React, { useContext, useEffect, useState } from 'react';
import ExperienceCard from './experienceCard';
import { createExperience, deleteExperience, getAllExperiences, getExperiencesByStudent, updateExperience } from '../../api/experienceApi';
import Editor from '../common/editor';
// import { Dialog } from '@material-tailwind/react';
import { UserContext } from '../../contexts/userContext';
import { createCanBoundTo } from '@casl/react';
import useDefineAbilities from '../../routes/ability';
import {

    Dialog,

} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import ExperienceDialog from './experienceDialog';

export default function Experience() {
    const [experiences, setExperiences] = useState([]);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [selectedExperience, setSelectedExperience] = useState(null);

    // const [editorValue, setEditorValue] = useState('');
    const { userData } = useContext(UserContext);
    const id = userData.id
    // const Can = createCanBoundTo(useDefineAbilities());
    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(!open);
        navigate('/account/experience/add');
    };
    const handleEditOpen = (experience = null) => {
        setSelectedExperience(experience);
        setOpen(!open);
        if(experience){
            setFormData({
                jobTitle: experience.jobTitle,
                employmentType: experience.employmentType,
                companyName: experience.companyName,
                location: experience.location,
                locationType: experience.locationType,
                currentlyWorking: experience.currentlyWorking,
                startDate: experience.startDate,
                endDate: experience.endDate,
                description: experience.description,
                studentId: id,
            })
            
            const [startYear, startMonth, startDay ] = experience.startDate.split('-');
        const [endYear, endMonth, endDay] = experience.endDate.split('-');
            
            setDate({
                startMonth: startMonth,
                startYear: startYear,
                endMonth: endMonth,
                endYear: endYear
                
            });
            // console.log(date);
        } else {
            setFormData({
                jobTitle: '',
                employmentType: '',
                companyName: '',
                location: '',
                locationType: '',
                currentlyWorking: false,
                startDate: '',
                endDate: '',
                description: '',
            })
            setDate({
                startMonth: '',
                startYear: '',
                endMonth: '',
                endYear: ''
            });
        }
        navigate('/account/experience/edit');
    };
    useEffect(() => {
        if (!open) {
            navigate('/account/experience');
        }
    }, [open, navigate]);

    const [date, setDate] = useState({
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: ''
    })

    const [formData, setFormData] = useState({
        jobTitle: '',
        employmentType: '',
        companyName: '',
        location: '',
        locationType: '',
        currentlyWorking: false,
        startDate: '',
        endDate: '',
        description: '',
        studentId: id,

    });

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDate({
            ...date,
            [name]: value,
        });
    };
    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        setFormData({
            ...formData,
            // [name]: value,
            [name]: type === 'checkbox' ? checked : value,
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        formData.startDate = `${date.startYear}-${date.startMonth}-01`;
        formData.endDate = formData.currentlyWorking ? null : `${date.endYear}-${date.endMonth}-01`;

        // formData.description = editorValue;
        if(!formData.jobTitle ||  !formData.companyName ||   !formData.startDate){
            setAlert({ type: 'error', message: 'Please fill all the fields' });
            return;
        }

        try {
            if(selectedExperience){
                await updateExperience(selectedExperience.id, formData);
                // setExperiences(experiences.map((experience) => (experience.id === selectedExperience.id ? formData : experience)));
                setAlert({ type: 'success', message: 'Experience updated successfully!' });
                setOpen(!open);
                setSelectedExperience(null);
            } else {
                await createExperience(formData);
                setExperiences([...experiences, formData]);
                setAlert({ type: 'success', message: 'Experience added successfully!' });
            }
            
            
            setFormData({
                jobTitle: '',
                employmentType: '',
                companyName: '',
                location: '',
                locationType: '',
                currentlyWorking: false,
                startDate: '',
                endDate: '',
                description: '',
                studentId: id,
            });
            setDate({
                startMonth: '',
                startYear: '',
                endMonth: '',
                endYear: ''
            });
            setOpen(!open);

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);

                } else if (error.response.status === 403) {
                    setAlert({ type: 'error', message: 'Forbidden. You do not have permission to add experience.' });
                    setTimeout(() => {
                        navigate('/unauthorized');
                    }, 1000);


                } else {
                    setTimeout(() => {
                        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

                    }, 1000);
                }
            } else {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

            }

        }
    };

    useEffect(() => {
        // Fetch data when component mounts
        const fetchExperience = async () => {
            try {
                const data = await getExperiencesByStudent(id); // Call the API function
                setExperiences(data);
                // Set the fetched data to state
            } catch (error) {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

                // console.error('Error fetching experiences:', error);
            }
        };
        fetchExperience();
        // console.log('helo',experiences);

        // Clean up function
        return () => {
            // Any cleanup code if needed
        };
    }, []);


    const handleDelete = async (experienceId) => {
        try {
          await deleteExperience(experienceId);
          setExperiences(experiences.filter((experience) => experience.id !== experienceId));
          setAlert({ type: 'success', message: 'Certificate deleted successfully!' });
        } catch (error) {
          setAlert({ type: 'error', message: 'Failed to delete certificate. Please try again.' });
        }
      };

    const handleCloseAlert = () => {
        setAlert({ type: '', message: '' });
    };
    return (
        <div className='bg-white px-8 py-8  shadow-md rounded-xl bg-clip-border '>

            <div className='fixed  top-24 right-10 space-y-4 z-50'>

                {alert.type === 'success' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-green-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
                )}
                {alert.type === 'error' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
                )}
            </div>
            <div className='flex flex-col justify-end'>
                <div className='flex justify-between'>

                    <h3 className="self-start block font-sans text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                        Experiences / Activities
                    </h3>
                    <div className=' '>
                        <button
                            className="flex select-none items-center rounded-lg bg-gray-900 py-3 px-6 text-center align-middle font-sans text-xs font-bold  text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            type="button"
                            onClick={handleOpen}
                        // data-dialog-target="experience-dialog"
                        >
                            <span className='mr-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                                </svg></span>
                            Add Experience / Activity
                        </button>
                      
                        {/* </div>
                        </div> */}

                    </div>
                </div>
                {experiences.length === 0 ? (
                    <div className='flex flex-col '>
                        <div className='self-center'>
                            <span className=''>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="gray" className="w-36 h-36">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                                </svg>
                            </span>
                        </div>

                        <div>
                            <h1> No additional work experiences are available</h1>
                            <h1> Complete your profile information for a strong resume</h1>
                        </div>
                    </div>
                ) : (
                    <div className=' mt-12'>
                        <ExperienceCard experiences={experiences} onDelete={handleDelete} onEdit={handleEditOpen} />

                    </div>
                )}




            </div>
            <ExperienceDialog
                        open={open}
                        handleOpen={handleOpen}
                        
                        handleSubmit={handleSubmit}
                        formData={formData}
                        handleChange={handleChange}
                        date={date}
                        handleDateChange={handleDateChange}/>

        </div>

    )
}