import React, { useContext } from 'react';
import ProfilePicture from '../account/profilePicture';
import AboutSection from './aboutSection';
import CertificateProfile from './certificateProfile';
import ExperienceProfile from './experienceProfile';
import EducationProfile from './educationProfile';
import ProfileHeadline from './profileHeadline';
import RecentJob from './RecentJob';
import ContactInformations from './contactInformation';
import RatingBar from './ratingBar';
import UniversityProgram from './universityProgram';
// import { UserContext } from '../../contexts/userContext';

export default function MainSideProfile({ profileData }) {
    // const { user } = useContext(UserContext);
    // console.log(profileData)

    return (
        <div className='flex flex-col  justify-center space-y-2 overflow-y-auto overflow-x-hidden '>
            <ProfileHeadline profileData={profileData}/>
            <AboutSection profileData={profileData}/>
            <ContactInformations profileData={profileData}/>

            {profileData && profileData.user === 'student' && (
                <>
                    <ExperienceProfile profileData={profileData.profileData} />
                    <EducationProfile profileData={profileData.profileData} />
                    <CertificateProfile profileData={profileData.profileData} />
                </>
            )}

            {profileData && profileData.user  === 'company'&& (
                <>
                <RecentJob profileData={profileData.profileData} />
                <RatingBar />

                </>
                ) }

            {profileData && profileData.user  === 'university' && (
                <>
                    <UniversityProgram profileData={profileData.profileData} />
                    <RatingBar />
                </>
            )}
            {/* {profileData && profileData.user === 'student' &&  <ExperienceProfile profileData={profileData.profileData}/>}
            {profileData && profileData.user === 'student' &&       <EducationProfile profileData={profileData.profileData}/>}

            {profileData && profileData.user === 'student' &&<CertificateProfile profileData={profileData.profileData}/>}

            {profileData && profileData.user  === 'company' && <RecentJob profileData={profileData.profileData} />}
            {profileData && profileData.user  === 'university' && <UniversityProgram profileData={profileData.profileData} />}
            {profileData && profileData.user  === 'university' && <RatingBar />} */}

        </div>
    )
}