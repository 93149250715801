import React, { useContext } from 'react';
import { UserContext } from '../../contexts/userContext';
import { Link } from 'react-router-dom';

export default function AboutSection({ profileData }) {
    const { userData } = useContext(UserContext);
    // console.log(profileData.profileData.userId , userData.id);

    return (
        <div className='bg-white px-8 py-8  shadow-md rounded-md bg-clip-border '>


            <div className='flex flex-col justify-end'>
                <div className='flex items-center w-full justify-between'>
                    <h3 className=" self-start block font-sans text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                        About
                    </h3>
                    {userData && userData.userId === profileData.profileData.userId && (
                        <Link to='/account' className="self-start flex select-none items-center gap-3 rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        >

                            <span className=''>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                </svg>
                            </span>
                        </Link>
                    )}
                </div>
                <p className='text-left'>
                    {profileData && profileData.profileData.about}
                </p>
                <div className="mt-2">
                    <div className="flex flex-wrap gap-2 mt-2 ">
                        {profileData && profileData.profileData.skills && profileData.profileData.skills.map((skill, index) => (
                            <span
                                key={index}
                                className="inline-flex items-center justify-center  text-gray-700 px-2 py-1 text-sm font-medium text-dark"
                            >
                                {skill}
                            </span>
                        ))}
                    </div>
                </div>
            </div>



        </div>

    )
}