import React, { useContext, useEffect,useState } from 'react';
import CertificateCard from '../account/certificateCard';
import { getAllCertificates, getCertificatesByStudent } from '../../api/certificateApi';
import { UserContext } from '../../contexts/userContext';
import { Link } from 'react-router-dom';
import CertificateProfileCard from './certificateProfileCard';

export default function CertificateProfile({profileData}) {
    const { userData } = useContext(UserContext);

    const [certificates, setCertificates] = useState([]);

    useEffect(() => {
        // Fetch data when component mounts
        const fetchCertificate = async () => {
            try {
                const data = await getCertificatesByStudent(profileData.id); // Call the API function
                setCertificates(data);
                // Set the fetched data to state
            } catch (error) {
                console.error('Error fetching certificates:', error);
            }
        };
        fetchCertificate();
        // console.log(certificates);

        // Clean up function
        return () => {
            // Any cleanup code if needed
        };
    }, [profileData.id]);
    return (
        <div className='bg-white px-8 py-8  shadow-md rounded-md bg-clip-border '>
            <div className='flex flex-col justify-end'>
            <div className='flex items-center w-full justify-between'>

                    <h3 className="self-start block font-sans text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                        Certificate
                    </h3>
                    {userData && userData.userId=== profileData.userId && (
                    <Link to='/account/experience' className="self-start flex select-none items-center gap-3 rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    >

                        <span className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                            </svg>
                        </span>
                    </Link>
                    )}
                </div>

                    {certificates.length === 0 ? (
                        <div className='flex flex-col '>

                            <div className='self-center'>
                                <span className=''>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="gray" className="w-36 h-36">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path><path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5"></path><path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73">
                                        </path><path d="M6 9l12 0"></path><path d="M6 12l3 0"></path><path d="M6 15l2 0"></path>
                                    </svg>

                                </span>
                            </div>
                            <div>
                                <h1> No additional certificate are available</h1>
                                <h1> Complete your profile information for a strong resume</h1>
                            </div>
                        </div>
                    ) : (

                        <div className='mt-5'>
                            <CertificateProfileCard certificates={certificates} />

                        </div>
                    )}

            </div>
        </div>
            );
}