import React from 'react';
import RegisterForm from '../components/authentification/registerForm';


const RegisterPage = () => {
  return (
    <RegisterForm/>
    
  );
}

export default RegisterPage;
