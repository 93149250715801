import React, { createContext, useState, useEffect } from 'react';
// const jwt_decode = require("jwt-decode");
import { jwtDecode } from 'jwt-decode';
import { getStudentByUserId } from '../api/studentApi';
import { getUniversityByUserId } from '../api/universityApi';
import { getCompanyByUserId } from '../api/companyApi';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const decodedToken = jwtDecode(token);
      const { id: userId, email, role, userName } = decodedToken;
      setUser({ userId, email, role, userName });
    }else {
      setLoading(false); // No token found, stop loading
    }
  }, []);

  useEffect(() => {
    if (user && user.role && user.userId) {
      fetchUserData(user.role, user.userId);
    }
  }, [user]);

  const fetchUserData = async (role, userId) => {
    try {
      let userData;
      switch (role) {
        case 'student':
          userData = await getStudentByUserId(userId);
          break;
        case 'university':
          userData = await getUniversityByUserId(userId);
          break;
        case 'company':
          userData = await getCompanyByUserId(userId);
          break;
        default:
          console.error('Invalid role');
          return;
      }
      setUserData(userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false); // Stop loading after data is fetched
    }
  };
  
  if (loading) {
    return <div>Loading...</div>; // Display a loading indicator while fetching user data
  }
  return (
    <UserContext.Provider value={{ user, userData }}>
      {children}
    </UserContext.Provider>
  );
};