import React, { useContext, useEffect, useState } from 'react';
import DashboardPageStudent from './dashboardPageStudent';
import DashboardPageUniversity from './dashboardPageUniversity';
import DashboardPageCompany from './dashboardPageCompany';
import useUserRoles from '../../routes/roles';
import { UserContext } from '../../contexts/userContext';
import { Spinner } from '@material-tailwind/react';

export default function DashboardPage () {
    const [loading, setLoading] = useState(true);
    const [dashboard, setDashboard] = useState(null);
    // const role = useUserRoles();
    const { user } = useContext(UserContext);
    // console.log(user);

    useEffect(() => {
      if (user && user.role) {
        switch (user.role) {
          case 'student':
            setDashboard(<DashboardPageStudent />);
            break;
          case 'company':
            setDashboard(<DashboardPageCompany />);
            break;
          case 'university':
            setDashboard(<DashboardPageUniversity />);
            break;
          default:
            break;
        }
        setLoading(false);
      }
    }, [user]);
  


    if (loading) {
        return <div className="flex justify-center items-center h-screen">
        <Spinner className="h-12 w-12" />
      </div>; // Or your loading component
    }

    return (
        <>
        {dashboard}
        </>
    );
}