import React from 'react';


const UniversityForm = ({universityName,setUniversityName,location,setLocation}) => {
  return (
    <div className='flex flex-col gap-2'>
                        <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                        University name
                                </label>

                            <div className="relative">
                                <input
                                    type="text"
                                    value={universityName}
                                    onChange={(e) => setUniversityName(e.target.value)}
                                    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                    placeholder="Enter University name"
                                />

                                
                            </div>
                        
                            <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                            Location
                                </label>

                            <div className="relative">
                                <input
                                    type="text"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                    placeholder="Enter Location"
                                />

                                
                            </div>
                        </div>
  );
}

export default UniversityForm;
