import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
    

    return (
        <header className="bg-white dark:bg-gray-900">
            <div className="container px-6 py-8 mx-auto">
                <div className=" lg:flex gap-y-6 items-center ">
                    
                    <div className="w-full lg:w-1/2 mt-6 lg:mt-0 mx-auto">
                        <div className="lg:max-w-lg">
                            <h1 className="text-3xl font-semibold text-gray-800 dark:text-white lg:text-4xl">
                            Discover the Perfect <br /> <span className="text-blue-500">Carrer path</span> for You

                                </h1>
                            
                            <p className="mt-3 text-gray-600 dark:text-gray-400">
                            Start your career journey with us. Find the best internships that match your skills and aspirations. Your future begins here.
                            </p>
                            <div className='flex space-x-6 justify-center'>
                            <Link  to = '/login' className="w-full px-5 py-2 mt-6 text-sm tracking-wider text-white uppercase transition-colors duration-300 transform bg-blue-600 rounded-lg lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500">
                            Log In
                                </Link>
                            <Link to = '/register' className="w-full px-5 py-2 mt-6 text-sm tracking-wider text-white uppercase transition-colors duration-300 transform bg-blue-600 rounded-lg lg:w-auto hover:bg-blue-500 focus:outline-none focus:bg-blue-500">
                                Join now

                            </Link>
</div>
                        </div>
                    </div>
<div className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2 ">
                        <img className="w-full h-full lg:max-w-3xl rounded-l-full" src="/hero2.jpeg" alt="" />
                    </div>
                    
                </div>
            </div>
        </header>
    );
};

export default Hero;
