import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../contexts/userContext';
import { getStudentById, updateStudent, updateStudentSocialMedia } from '../../api/studentApi';
import { useNavigate } from 'react-router-dom';

export default function SocialNetwork() {
  const { userData } = useContext(UserContext);
  const [alert, setAlert] = useState({ type: '', message: '' });

  const [socialMedia, setSocialMedia] = useState({
    LinkedIn: '',
    GitHub: '',
    HuggingFace: '',
    Website: ''
  }); 

  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudentData = async () => {
      if(userData)
         try {
           const response = await getStudentById(userData.id)
          //  console.log(response);
           const socialMediaLinks = response.socialMedia || {
            LinkedIn: '',
            GitHub: '',
            HuggingFace: '',
            Website: ''
          };
          setSocialMedia(socialMediaLinks);
   
   
         } catch (error) {
           console.error('Error fetching student data:', error);
         }
       };
   
       fetchStudentData();
      }, [userData]);

      const handleChange = (platform, e) => {
        setSocialMedia({
          ...socialMedia,
          [platform]: e.target.value
        });
      };
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // console.log(socialMedia);
      await updateStudentSocialMedia(userData.id, socialMedia);
      setAlert({ type: 'success', message: 'information updated successfully!' });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
            setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
            setTimeout(() => {
                 navigate('/login');
            }, 1000);
         
        } else if (error.response.status === 403) {
        setAlert({ type: 'error', message: 'Forbidden. You do not have permission to edit this offer.' });
        setTimeout(() => {
            navigate('/unauthorized');
        }, 1000);  
        

        } else {
            setTimeout(() => {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

            }, 1000);
        }
      } else {
        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

      }
    }
  };
  const handleCloseAlert = () => {
    setAlert({ type: '', message: '' });
};
  return (
    <div className="bg-white py-4 px-12 container mx-auto shadow-md rounded-xl bg-clip-border">
     <div className='fixed  top-24 right-10 space-y-4 z-50'>

{alert.type === 'success' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-green-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
                )}
                {alert.type === 'error' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
            )}
            </div>
      <h4
        className="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Social Media
      </h4>
      <form onSubmit={handleSubmit} className="my-8 grid md:grid-cols-2 grid-cols-1 items-end gap-4">
        <div className='flex flex-col gap-3 w-full'>
          <label className='self-start block text-base font-medium text-dark dark:text-white'>
            LinkedIn
          </label>
          <input
            type='text'
            value={socialMedia.LinkedIn}
            onChange={(e) => handleChange('LinkedIn', e)}
            placeholder='https://www.linkedin.com/'
            className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
          />
        </div>
        <div className='flex flex-col gap-3 w-full'>
          <label className='self-start block text-base font-medium text-dark dark:text-white'>
            GitHub
          </label>
          <input
            type='text'
            value={socialMedia.GitHub}
            onChange={(e) => handleChange('GitHub', e)}
            placeholder='https://github.com/'
            className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
          />
        </div>
        <div className='flex flex-col gap-3 w-full'>
          <label className='self-start block text-base font-medium text-dark dark:text-white'>
            Hugging Face
          </label>
          <input
            type='text'
            value={socialMedia.HuggingFace}
            onChange={(e) => handleChange('HuggingFace', e)}
            placeholder='https://huggingface.co/'
            className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
          />
        </div>
        <div className='flex flex-col gap-3 w-full'>
          <label className='self-start block text-base font-medium text-dark dark:text-white'>
            Personal Website
          </label>
          <input
            type='text'
            value={socialMedia.Website}
            onChange={(e) => handleChange('Website', e)}
            placeholder='https://personalLink.com'
            className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
          />
        </div>
        <button
          className="block w-48 select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="submit"
        >
          Save
        </button>
      </form>
    </div>
  )
}