import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { getApplicationBystudentId } from '../../../api/applicationApi';
import { UserContext } from '../../../contexts/userContext';
import { Spinner } from '@material-tailwind/react';

export default function RecentStudentApplication() {

    const [loading, setLoading] = useState(true); // Add loading state
    const [applications, setApplications] = useState([])

    const { userData } = useContext(UserContext);

    useEffect(() => {
        const fetchApplications = async () => {
          try {
            const applicationData = await getApplicationBystudentId(userData.id);
            // console.log(applicationData);
            // const applicationData = await getApplications();
            setApplications(applicationData);
            setLoading(false); // Set loading to false when offers are fetched
    
          } catch (error) {
            console.error('Error fetching offers:', error);
          }
        };
    
        fetchApplications();
      }, [ userData]);
// console.log(applications);
      const sortedApplications = applications
      .sort((a, b) => new Date(b.applicationDate) - new Date(a.applicationDate)) // Assuming createdAt is a valid date field
      .slice(0, 4);

      function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return date.toLocaleString('en-US', options);
      }

      if (loading) {
        return <Spinner />; // Display a spinner while loading
    }
    return (
        <div class="bg-white shadow-md rounded-xl bg-clip-border p-4">

            <div className='flex flex-col gap-2'>
                <span className='font-semibold text-lg text-left mb-2 ml-2'>
                    Recent Applications status
                </span>
                {sortedApplications.map((application, index) => (

                <div key={index} className="flex justify-between gap-4 w-full border-y">
                    <div className='flex gap-4'>
                        {/* <div className=" hidden sm:block sm:shrink-0">
                            <img
                                alt=""
                                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                                className="size-16 rounded-lg object-cover shadow-sm"
                            />
                        </div> */}
                        <div className='flex flex-col'>
                            <h3 className=" text-left font-semibold text-gray-900 ">
                                {application.Offer.title}
                            </h3>   
                            <div className='flex gap-4'>
                            <p className=" mt-1 text-sm font-medium text-gray-600">
                                 {application.Offer.Company.companyName}

                            </p>
                            
                            <p className=" mt-1 text-sm font-medium text-gray-600">
                                {formatDate(application.applicationDate)}

                            </p>
                        </div>
                        </div>

                    </div>

                    <div className="  p-4 mr-4 border-blue-gray-50 ">
                    <div className="w-max">
                      <div className={`relative grid items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap ${ 
                        application.status === 'accepted' ? 'bg-blue-500/20 text-blue-900' :
                           application.status === 'rejected' ? 'bg-gray-500/20 text-gray-900' :
                           application.status === 'pending' ? 'bg-yellow-500/20 text-yellow-900' :
                           application.status === 'shortlisted' ? 'bg-orange-500/20 text-orange-900' :
                           application.status === 'validated' ? 'bg-green-500/20 text-green-900' :
                           application.status === 'invalidated' ? 'bg-red-500/20 text-red-900' :
                           'bg-gray-200 text-gray-700' // Default background color for other statuses
                       }`}>
                        <span>{application.status}</span>
                      </div>
                    </div>
                    </div>
                </div>

              ))}
                
                
                <Link to="/dashboard/applications" className=' '>
                    <span className='underline font-body mt-2'>Show more </span>
                </Link>
            </div>
        </div>
    )
}