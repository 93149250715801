// api/applicationFileApi.js
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');

// let headers = {};

// if (tokenString) {
//   const tokenObject = JSON.parse(tokenString);
//   const token = tokenObject.token;

//   headers = {
//     Authorization: token,
//   };
// }
export const downloadApplicationFile = async (fileId) => {
    try {
        const response = await axios.get(`${BASE_URL}/application-files/download/${fileId}`, {
            responseType: 'blob',  // Important to handle file downloads
        });

        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;

        // Ensure Content-Disposition header is present and correctly parsed
        const contentDisposition = response.headers['content-disposition'];
        let fileName = fileId; // Fallback to fileId if header is not present
        if (contentDisposition) {
            const match = contentDisposition.match(/filename="?(.+?)"?$/);
            if (match && match[1]) {
                fileName = decodeURIComponent(match[1]);
            }
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error downloading file:', error);
        throw error;
    }
};