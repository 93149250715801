import React from 'react';
import { Link } from 'react-router-dom';
import { getOfferById } from '../../api/offerApi';



export default function ApplyWitProfile() {
   
    return (

        <div className=" pb-4 pt-16 sm:px-6  w-full">


            <div className="rounded-lg bg-white p-4 shadow-md lg:p-12">
                <div className='flex items-center gap-4'>
                    <span className='text-xl'> you want to apply using your profile ?</span>
                    <div>
                    <button
                                    className="  group flex items-center justify-around gap-4 px-2 py-1 rounded-lg border border-indigo-600 bg-indigo-600  transition-colors hover:bg-transparent focus:outline-none focus:ring"

                                >

                                    <span
                                        className="font-medium text-sm text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
                                    >
                                        Apply
                                    </span>

                                    <span
                                        className="shrink-0 rounded-full border border-current bg-white p-1 text-indigo-600 group-active:text-indigo-500"
                                    >
                                        <svg
                                            className="size-5 rtl:rotate-180"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                                            />
                                        </svg>
                                    </span>

                                </button>
                    </div>
                </div>

            </div>
        </div>
    )
}