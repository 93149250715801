import React, { useState, useEffect, useContext } from 'react';
import Pagination from '../../components/common/pagination';
import { getApplicationByCompanyId, getApplicationById, getApplicationBystudentId, getApplications } from '../../api/applicationApi';


import { UserContext } from '../../contexts/userContext';
import useUserRoles from '../../routes/roles';
import ApplicationDashboardS from './applicationDashboardS';
import ApplicationDashboardC from './applicationDashboardC';
import ApplicationDashboardU from './applicationDashboardU';


export default function ApplicationDashboard() {
  
  const [applciation, setApplication] = useState(null);
// const role = useUserRoles();
const { user } = useContext(UserContext);
    let role = user.role;
  useEffect(() => {
    if (role) {
        if (role === 'student') {
          setApplication(<ApplicationDashboardS />);
        } else if (role === 'company') {
          setApplication(<ApplicationDashboardC />);
        } else if (role === 'university') {
          setApplication(<ApplicationDashboardU />);
        }
        // setLoading(false);
    }
}, [role]);


  // if (loading) {
  //   return <div>Loading...</div>;
  // }
  return (
    <>
    {applciation}
    
    </>
    )
}