import { AuthContext } from '../../contexts/authContext';
import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

// import Profile from '../components/profile/profile';


const LoginForm = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');


  const { login, logout, isAuthenticated } = useContext(AuthContext);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {

    e.preventDefault();

    try {
      await login(email, password);
      // await logout ()
      // console.log(token);

      navigate("/loading");
      // Login successful, perform any necessary actions
      // For example, you can navigate to a different page or update the component state
      // after successful login

      setError('');
      setIsAuthenticated(true);
      // localStorage.setItem('token', token);

    } catch (error) {
      setError('Failed to log in. Please check your credentials.');
    }
  };




  return (

    <div className="mx-auto bg-white max-w-lg my-12 p-4 shadow-md rounded-lg bg-clip-border">
      <h1 className="text-center pt-8 text-2xl font-bold text-indigo-600 sm:text-3xl">Welcome Back</h1>

      {/* <p className="mx-auto mt-4 max-w-md text-center text-gray-500 ">
        Welcome back
      </p> */}
        {error && (
        <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <form
        onSubmit={handleFormSubmit}
        className="flex flex-col gap-2 mb-0 mt-4   p-4  sm:p-6 lg:p-2">

        
          <label className='ml-2 self-start  block text-base font-medium text-dark dark:text-white'>
            Email
          </label>
          <div className="relative mb-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
              placeholder="Enter email"
            />

            <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            </span>
          </div>
        
          <label className='ml-2 self-start  block text-base font-medium text-dark dark:text-white'>
            Password
          </label>
          <div className="relative">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
              placeholder="Enter password"
            />

            <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </span>
          </div>
        

        <button
          type="submit"
          className="mt-10 block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
        >
          Log in
        </button>

        <p className="text-center text-sm text-gray-500">
          You don't have an account?
          <Link to="/register" className="underline" >Sign up</Link>
        </p>
      </form>
    </div>
  );
}

export default LoginForm;


