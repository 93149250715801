import React from 'react';
import IndustryInput from './industryInput';


const CompanyForm = ({ companyName, setCompanyName,location,setLocation, industry, handleIndustryChange }) => {
    return (
        <div className='flex flex-col gap-2'>
            <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                                                Company Name 
                                            </label>
                <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                    placeholder="Enter Company name"
                />

                {/* <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="size-4 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                                    />
                                </svg>
                            </span> */}

<label className='self-start  block text-base font-medium text-dark dark:text-white'>
                                                Location
                                            </label>
                                            <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                    placeholder="Enter Company name"
                />
                {/* <select

                    value={industry}
                    onChange={handleIndustryChange}
                    className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                    placeholder="Enter password"
                >
                    <option value="">Please select</option>
                    <option value="IT">IT</option>
                    <option value="DS">DS</option>
                    <option value="Cloud">Cloud</option>
                </select> */}
                <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                                                Industry
                                            </label>
                                            <IndustryInput industry={industry} handleIndustryChange={handleIndustryChange}/>
                
            </div>
    

    );
}

export default CompanyForm;
