import React from 'react';

export default function CertificateCard({certificates, onDelete, onEdit }) {
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    }
    return (
        <div className='flex flex-col space-y-4'>
            {certificates.map(certificate => (
        <article class="rounded-xl bg-white p-4 ring ring-indigo-50 sm:p-6 lg:p-8">
            <div class="flex items-start sm:gap-8">
                <div
                    class="hidden sm:grid sm:size-20 sm:shrink-0 sm:place-content-center sm:rounded-full sm:border-2 sm:border-indigo-500"
                    aria-hidden="true"
                >
                    <div class="flex items-center gap-1">
                        <span class="h-8 w-0.5 rounded-full bg-indigo-500"></span>
                        <span class="h-6 w-0.5 rounded-full bg-indigo-500"></span>
                        <span class="h-4 w-0.5 rounded-full bg-indigo-500"></span>
                        <span class="h-6 w-0.5 rounded-full bg-indigo-500"></span>
                        <span class="h-8 w-0.5 rounded-full bg-indigo-500"></span>
                    </div>
                </div>

                <div className='flex flex-col grow '>
                    {/* <strong
                        class="rounded border border-indigo-500 bg-indigo-500 px-3 py-1.5 text-[10px] font-medium text-white"
                    >
                        Episode #101
                    </strong> */}

                    <h3 class= " self-start  text-lg font-medium sm:text-xl">
                        <span href="#" class="hover:underline">{certificate.certificateName }</span>
                    </h3>
                    <h1 class= " self-start   font-xs text-sm">
                        <span href="#" class="hover:underline"> {certificate.organizationName}  </span>
                        {/* <span> · </span>
                        <span>field of study </span> */}
                    </h1>

                    <h1 class= " self-start   font-xs text-sm">
                        <span href="#" class="hover:underline"> {formatDate(certificate.certificateDate)}  </span>
                        {/* <span> · </span>
                        <span>1 yr 10 mos </span> */}
                    </h1>

                    
                </div>



                <div className='flex flex-col sm:flex-row gap-4'>
                            
                        <button
                                className="flex select-none items-center gap-3 rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                type="button"
                                onClick={() => onEdit(certificate)}
                            >
                                <span className=''>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                                    </svg>
                                </span>
                            </button>
                            <button
                className="flex select-none items-center gap-3 rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
                onClick={() => onDelete(certificate.id)}
              >
                                <span className=''>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>

                                </span>
                            </button>
                        </div>

                    </div>
                </article>
            ))}

        </div>
    )
}