import React from 'react';
import RecommendedOffer from './recommendedOffer';
import Updates from './updates';
function FeedContent(){
    return(
        <div className="mx-auto max-w-screen-xl py-1  space-y-4">

        {/* <div className="rounded-lg bg-white  px-1 sm:px-1 lg:px-1 shadow-lg lg:col-span-3 lg:p-12"> */}
{/* <div> */}
        <RecommendedOffer/>
        <Updates/>
        {/* </div> */}
        </div>
    )
}
export default FeedContent