import React from 'react';
function ExploreUni() {
    return (
<section className="bg-white dark:bg-gray-900 border-b border-light-blue-200">
            <div className="container lg:px-6  mx-auto">
                <div className="flex items-center justify-between p-6">
                    {/* <div className="w-full space-y-12 lg:w-1/2 ">
                        <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">
                            Discover the perfect internship opportunity for you.
                        </h1>


                    </div> */}
                    <div className=" lg:flex lg:flex-wrap lg:items-center lg:w-1/2 lg:justify-start space-x-3">
                        

                    <img className="size-110 object-cover  rounded-full" src="/exploreEducation.jpeg" alt=""/>


                    </div>
                    <div className="w-full space-y-6 lg:w-1/2 ">
                        <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">

                        Achieve your academic goals at a prestigious university                        
                        </h1>
                        <p class="text-xl mt-3 text-gray-500 dark:text-gray-300">

Excel in Higher Education: Elevate Your Academic Journey by Enrolling in a Premier University
</p>
                    </div>
                </div>
            </div>

        </section>
    )
}
export default ExploreUni