import React, { useContext, useEffect, useState } from 'react';
import EducationCard from './educationCard';
import { createEducation, deleteEducation, getAllEducations, getEducationsByStudent, updateEducation } from '../../api/educationApi';
import Editor from '../common/editor';
import { UserContext } from '../../contexts/userContext';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@material-tailwind/react';
import EducationDialog from './educationDialog';

export default function Education() {


    const [educations, setEducations] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedEducation, setSelectedEducation] = useState(null);

    const [alert, setAlert] = useState({ type: '', message: '' });

    const { userData } = useContext(UserContext);
    const id = userData.id

    const navigate = useNavigate();
    const handleOpen = () => {
        setOpen(!open);
        navigate('/account/education/add');
    };

    const handleEditOpen = (education = null) => {
        setSelectedEducation(education);
        setOpen(!open);
        if(education){
            setFormData({
                school: education.school,
                degree: education.degree,
                fieldOfStudy: education.fieldOfStudy,
                location: education.location,
                locationType: education.locationType,
                startDate: education.startDate,
                endDate: education.endDate,
                grade: education.grade,
                description: education.description,
                studentId: id,
                currentlyWorking: education.currentlyWorking,
            })
            
            const [startYear, startMonth, startDay ] = education.startDate.split('-');
        const [endYear, endMonth, endDay] = education.endDate.split('-');
            
            setDate({
                startMonth: startMonth,
                startYear: startYear,
                endMonth: endMonth,
                endYear: endYear
                
            });
            // console.log(date);
        } else {
            setFormData({
                school: "",
                degree: "",
                fieldOfStudy: "",
                location: "",
                locationType: "",
                startDate: "",
                endDate: "",
                grade: "",
                description: "",
                studentId: id,
                currentlyWorking: false,
            })
            setDate({
                startMonth: '',
                startYear: '',
                endMonth: '',
                endYear: ''
            });
        }
        navigate('/account/education/edit');
    };
    useEffect(() => {
        if (!open) {
            navigate('/account/education');
        }

    }, [open, navigate]);
    const [date, setDate] = useState({
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: ''
    })
    const [formData, setFormData] = useState({
        school: "",
        degree: "",
        fieldOfStudy: "",
        location: "",
        locationType: "",
        startDate: "",
        endDate: "",
        grade: "",
        description: "",
        studentId: id,
        currentlyWorking: false,
    });

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDate({
            ...date,
            [name]: value,
        });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // formData.startDate = `01-${date.startMonth.padStart(2, '0')}-${date.startYear}`;
        formData.startDate = `${date.startYear}-${date.startMonth}-01`;
        formData.endDate = `${date.endYear}-${date.endMonth}-01`;


        if(!formData.school || !formData.degree || !formData.fieldOfStudy || !formData.startDate || !formData.endDate )
            {
                setAlert({ type: 'error', message: 'Please fill all the fields' });
                return;
            }
        try {
            if(selectedEducation){
                // console.log(formData);
                await updateEducation(selectedEducation.id, formData);
                setAlert({ type: 'success', message: 'Education updated successfully!' });
                setOpen(!open);
                setSelectedEducation(null);
            } else {
                await createEducation(formData);
                setAlert({ type: 'success', message: 'Education added successfully!' });
            }
                // const updateCertificate = await getEducationsByStudent(id);
                // setEducations(updateCertificate);
            
            setFormData({
                school: "",
                degree: "",
                fieldOfStudy: "",
                location: "",
                locationType: "",
                startDate: "",
                endDate: "",
                grade: "",
                description: "",
                studentId: id,
                currentlyWorking: false,
            })
            setDate({
                startMonth: '',
                startYear: '',
                endMonth: '',
                endYear: ''
            });
            setOpen(!open);

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);

                } else if (error.response.status === 403) {
                    setAlert({ type: 'error', message: 'Forbidden. You do not have permission to add education.' });
                    setTimeout(() => {
                        navigate('/unauthorized');
                    }, 1000);


                } else {
                    setTimeout(() => {
                        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

                    }, 1000);
                }
            } else {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

            }

        }
    };




    useEffect(() => {
        // Fetch data when component mounts
        const fetchEducation = async () => {
            try {
                const data = await getEducationsByStudent(id); // Call the API function
                setEducations(data);
                // Set the fetched data to state
            } catch (error) {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });
            }
        };
        fetchEducation();
        // console.log(educations);

        // Clean up function
        return () => {
            // Any cleanup code if needed
        };
    }, [alert]);

    const handleDelete = async (educationId) => {
        try {
          await deleteEducation(educationId);
          setEducations(educations.filter((education) => education.id !== educationId));
          setAlert({ type: 'success', message: 'Certificate deleted successfully!' });
        } catch (error) {
          setAlert({ type: 'error', message: 'Failed to delete certificate. Please try again.' });
        }
      };

    const handleCloseAlert = () => {
        setAlert({ type: '', message: '' });
    };

    return (
        <div className='bg-white px-8 py-8  shadow-md rounded-xl bg-clip-border '>
            <div className='fixed  top-24 right-10 space-y-4 z-50'>

                {alert.type === 'success' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-green-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
                )}
                {alert.type === 'error' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
                )}
            </div>
            <div className='flex flex-col justify-end'>
                <div className='flex justify-between'>
                    <h3 className="self-start block font-sans text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                        Education
                    </h3>
                    <div className=''>
                        <button
                            className="flex select-none items-center rounded-lg bg-gray-900 py-3 px-6 text-center align-middle font-sans text-xs font-bold  text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            type="button"
                            onClick={handleOpen}
                        >
                            <span className='mr-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                                </svg></span>
                            Add Education level
                        </button>
                    </div>
                </div>
                {educations.length === 0 ? (
                    <div className='flex flex-col '>
                        <div className='self-center'>
                            <span className=''>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="gray" className="w-36 h-36">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                                </svg>

                            </span>
                        </div>
                        <div>
                            <h1> No additional education informations are available</h1>
                            <h1> Complete your profile information for a strong resume</h1>
                        </div>
                    </div>
                ) : (
                    <div className='mt-5'>
                        <EducationCard educations={educations} onDelete={handleDelete} onEdit={handleEditOpen}/>

                    </div>
                )}




            </div>
                        <EducationDialog
                        open={open}
                        handleOpen={handleOpen}
                        
                        handleSubmit={handleSubmit}
                        formData={formData}
                        handleChange={handleChange}
                        date={date}
                        handleDateChange={handleDateChange}/>

        </div>
    )
}