import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');

let headers = {};

if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject.token;

  headers = {
    Authorization: token,
  };
}

// Fetch all students
export const getStudents = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/students`,{headers: headers,  });;
    return response.data;
  } catch (error) {
    // Handle error
    // console.error('Error fetching students:', error);
    throw new Error('Failed to fetch students');
  }
};

// Fetch a single student by ID
export const getStudentById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/students/${id}`,{headers: headers,  });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(`Error fetching student with ID ${id}:`, error);
    throw new Error(`Failed to fetch student with ID ${id}`);
  }
};

export const getStudentByUserId = async (userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/students/user/${userId}`,{headers: headers,  });
    return response.data;
  } catch (error) {
    console.error(`Error fetching user with ID ${userId}:`, error);
    throw new Error(`Failed to fetch user with ID ${userId}`);
  }
};
// Create a new student
export const createStudent = async (studentData) => {
  try {
    const response = await axios.post(`${BASE_URL}/students`, studentData
    ,{headers: headers,  }
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error('Error creating student:', error);
    throw new Error('Failed to create student');
  }
};

// Update an existing student
export const updateStudent = async (id, studentData) => {
  try {
    const response = await axios.put(`${BASE_URL}/students/${id}`, studentData,{headers: headers,  });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(`Error updating student with ID ${id}:`, error);
    throw new Error(`Failed to update student with ID ${id}`);
  }
};

export const updateStudentSocialMedia = async (id, socialMedia ) => {
  try {
    const response = await axios.put(`${BASE_URL}/students/${id}`, { socialMedia }, { headers });
    return response.data;
  } catch (error) {
    console.error(`Error updating social media links for student with ID ${id}:`, error);
    throw new Error(`Failed to update social media links for student with ID ${id}`);
  }
};
// Delete a student by ID
export const deleteStudent = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/students/${id}`,{headers: headers,  });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(`Error deleting student with ID ${id}:`, error);
    throw new Error(`Failed to delete student with ID ${id}`);
  }
};
