import React , { useEffect,useState }from "react";

import {
  IconButton,
  Drawer,
  Spinner,

} from "@material-tailwind/react";

import {

  XMarkIcon,
  Bars3Icon
} from "@heroicons/react/24/outline";
import SideBarContentC from "./sideBarContentC";
import useUserRoles from "../../routes/roles";
import SideBarContentS from "./sideBarContentS";
import SideBarContentU from "./sideBarContentU";
import { useContext } from "react";
import { UserContext } from "../../contexts/userContext";



export default function Sidebar() {
  const [open, setOpen] = React.useState(0);
  const [openAlert, setOpenAlert] = React.useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [sidebarContent, setSidebarContent] = useState(null);
  const { user } = useContext(UserContext);

  // const role = useUserRoles();
        // console.log(role); 


  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const toggleMenu = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  
  
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  

  useEffect(() => {
    if (user && user.role) {
      // const role = user.role;
      switch (user.role) {
        case 'student':
          setSidebarContent(<SideBarContentS />);
          break;
        case 'company':
          setSidebarContent(<SideBarContentC />);
          break;
        case 'university':
          setSidebarContent(<SideBarContentU />);
          break;
        default:
          break;
      }
        // if (role === 'student') {
        //     setSidebarContent(<SideBarContentS />);
        // } else if (role === 'company') {
        //     setSidebarContent(<SideBarContentC />);
        // } else if (role === 'university') {
        //     setSidebarContent(<SideBarContentU />);
        // }
        setLoading(false);
    }
}, [user]);

if (loading ) {
    return <div className="flex justify-center items-center h-auto">
    <Spinner className="h-12 w-12" />
  </div>; // Or your loading component
}
  return (
    <div className="bg-white">
      <div className="hidden lg:flex">
        {/* <SideBarContentS /> */}
        {sidebarContent}
      </div>
      <IconButton
        className=" lg:hidden "
        variant="text"
        size="lg"
        onClick={openDrawer}
      >
        {isDrawerOpen ? (
          <XMarkIcon className="h-8 w-8 stroke-2" />
        ) : (
          <Bars3Icon className="h-8 w-8 stroke-2" />
        )}
      </IconButton>

      <Drawer
        className="drawer"
        open={isDrawerOpen}
        onClose={closeDrawer}
      >
        {sidebarContent}
      </Drawer>
    </div>
  );
}