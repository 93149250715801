import React, { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { useNavigate, Link } from 'react-router-dom';
import CompanyForm from './companyForm';
import StudentForm from './studentForm';
import UserForm from './userForm';
import UniversityForm from './universityForm';
import { createCompany } from '../../api/companyApi';


const RegisterForm = ({ setIsAuthenticated }) => {
    const [selectedOption, setSelectedOption] = useState('student');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [companyName, setCompanyName] = useState('');
    const [industry, setIndustry] = useState('');

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState(new Date())

    const [universityName, setUniversityName] = useState('')
    const [location, setLocation] = useState('')

    const [error, setError] = useState('');
    const [step, setStep] = useState(1);

    const { register, isAuthenticated } = useContext(AuthContext);
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();


    const resetCompanyForm = () => {
        setCompanyName('');
        setIndustry('');
    };

    const resetStudentForm = () => {
        setFirstName('');
        setLastName('');
        // setDateOfBirth('');
    };

    const resetUniversityForm = () => {
        setUniversityName('');
        setLocation('');
    };
    const handleNext = () => {
        setStep((prevStep) => prevStep + 1);

    };

    const handlePrevious = () => {
        setStep((prevStep) => {
            // Reset form data based on current step
            switch (prevStep) {
                case 2:
                    if (selectedOption === 'company') {
                        resetCompanyForm();
                    } else if (selectedOption === 'student') {
                        resetStudentForm();
                    } else if (selectedOption === 'university') {
                        resetUniversityForm();
                    }
                    break;
                // Add additional cases for other steps if needed
                default:
                    break;
            }

            return Math.max(1, prevStep - 1);
        });
    };


    const handleOptionChange = (value) => {
        setSelectedOption(value);
    };
    const handleIndustryChange = (industry) => {
        setIndustry(industry);
      };

    const handleDateSelect = (selectedDate) => {
        setDateOfBirth(selectedDate);
    }


    const handleFormSubmit = async (e) => {

        e.preventDefault();
        // console.log(step);

        // console.log('email : ', email);
        // console.log('password : ', password);
        // if (selectedOption === "company") {
        //     console.log('companyName : ', companyName);
        //     console.log('industry : ', industry);
        // }
        // if (selectedOption === 'student') {
        //     console.log('firstName : ', firstName);
        //     console.log('lastName : ', lastName);
        //     console.log('dateOfBirth : ', dateOfBirth);
        // }
        // if (selectedOption === 'university') {
        //     console.log(universityName);
        //     console.log(location);
        // }
        try {
            // console.log(step);
            // console.log(step);

            // if (step && step ===2) {
            if (selectedOption === "company" && companyName && industry) {
                const companyData = {
                    email: email,
                    password: password,
                    role: selectedOption, // Assuming selectedOption represents the user's role
                    companyName: companyName,
                    industry: industry,
                    location: location
                };
                await register(companyData, selectedOption);

                navigate("/loading");
                setError('');
                setIsAuthenticated(true);
                // console.log('Company created successfully:', createdCompany);

            }
            if (selectedOption === "student" && firstName && lastName && dateOfBirth) {
                const studentData = {
                    email: email,
                    password: password,
                    role: selectedOption, // Assuming selectedOption represents the user's role
                    firstName: firstName,
                    lastName: lastName,
                    dateOfBirth: dateOfBirth
                };
                await register(studentData, selectedOption);
                navigate("/loading");
                setError('');
                setIsAuthenticated(true);

                // console.log('student created successfully:', createdStudent);

            }
            if (selectedOption === "university" && universityName && location) {
                const universityData = {
                    email: email,
                    password: password,
                    role: selectedOption, // Assuming selectedOption represents the user's role
                    universityName: universityName,
                    location: location,
                };
                await register(universityData, selectedOption);
                //     
                navigate("/loading");
                setError('');
                setIsAuthenticated(true);

                // console.log('studen created successfully:', createduniversity);

            }

            // setError('');
            // setIsAuthenticated(true);

        }
        // }
        catch (error) {
            setError(error.message);

        }

    };



    return (

        <div className="mx-auto bg-white max-w-lg my-8 p-4 shadow-md rounded-lg bg-clip-border">
            <h1 className="text-center pt-8 text-2xl font-bold text-indigo-600 sm:text-3xl">Get started today</h1>

            {/* <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
                        hello mate
                    </p> */}
            <p className="text-center text-lg font-medium mt-2">New here ?</p>

            {step === 1 && (
                <div class="mt-6">
                    <h1 class="text-gray-500 dark:text-gray-300">Select type of account</h1>

                    <div className="mt-2 md:flex  justify-center  md:items-center md:-mx-2 rounded-lg  ">
                        <button
                            className={`flex justify-center w-full px-6 py-3 ${selectedOption === 'company'
                                ? 'text-white bg-indigo-600'
                                : 'text-indigo-600 border border-indigo-600'
                                } rounded-lg md:w-auto md:mx-2 focus:outline-none`}
                            value="Company"
                            disabled={selectedOption === 'company'}
                            onClick={() => handleOptionChange('company')}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="1"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                />
                            </svg>
                            <span className="mx-2">company</span>
                        </button>

                        <button
                            className={`flex justify-center w-full px-6 py-3 ${selectedOption === 'student'
                                ? 'text-white bg-indigo-600'
                                : 'text-indigo-600 border border-indigo-600'
                                } rounded-lg md:w-auto md:mx-2 focus:outline-none`}
                            value="Student"
                            disabled={selectedOption === 'student'}
                            onClick={() => handleOptionChange('student')}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="1"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                            </svg>
                            <span className="mx-2">student</span>
                        </button>
                        <button
                            className={`flex justify-center w-full px-6 py-3 ${selectedOption === 'university'
                                ? 'text-white bg-indigo-600'
                                : 'text-indigo-600 border border-indigo-600'
                                } rounded-lg md:w-auto md:mx-2 focus:outline-none`}
                            value="Student"
                            disabled={selectedOption === 'university'}
                            onClick={() => handleOptionChange('university')}>
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z" />
                            </svg>
                            

                            <span class="mx-2">
                                university
                            </span>
                        </button>
                    </div>
                </div>)}
            {error && <p>{error}</p>}
            <form
                onSubmit={handleFormSubmit}
                className="mb-0 mt-6 space-y-4  p-4  sm:p-6 lg:p-2">

                {step === 1 && (<UserForm
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                />

                )}
                {step === 2 && selectedOption === 'company' && (
                    <CompanyForm
                        companyName={companyName}
                        setCompanyName={setCompanyName}
                        location={location}
                        setLocation={setLocation}
                        industry={industry}
                        handleIndustryChange={handleIndustryChange}
                    />

                )}
                {step === 2 && selectedOption === 'student' && (
                    <StudentForm
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        date={dateOfBirth}
                        setDate={setDateOfBirth}
                        // handleDateSelect={handleDateSelect}
                    />
                )}
                {step === 2 && selectedOption === 'university' && (
                    <UniversityForm
                        universityName={universityName}
                        setUniversityName={setUniversityName}
                        location={location}
                        setLocation={setLocation}
                    />
                )}
                {/* <button
                            type="submit"
                            className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
                        >
                            SIGN IN
                        </button> */}
                <div className="flex justify-content-between gap-2">
                    {step > 1 && (
                        <button 
                        className="block border border-indigo-600 w-full rounded-lg bg-white px-5 py-3 text-sm font-medium text-indigo-600"

                        /* variant="secondary" */ onClick={handlePrevious}>
                            Previous
                        </button>
                    )}
                    {step < 2 ? (
                        <button /* variant="secondary" */ 
                        className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"

                        onClick={handleNext}>
                            Next
                        </button>
                    ) : (
                        <button /* variant="primary" */
                        className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
                            type="submit">
                            Submit
                        </button>
                    )}
                </div>


            </form>
            <p className="text-center text-sm text-gray-500">
                you have an account?
                <Link to="/login" className="underline" >
                    Log In
                </Link>
            </p>
        </div>


    );
}

export default RegisterForm;
