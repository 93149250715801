import React from 'react';

export default function NewApplicationCard() {
    return (
        <div className="bg-gradient-to-b from-blue-500/20  to-white shadow-md rounded-xl bg-clip-border lg:w-52 w-36 bg-white">
            <div className='flex flex-col items-start'>
                <div class="m-4 flex  self-start justify-center rounded-full ">
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-user-plus">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" /><path d="M16 19h6" /><path d="M19 16v6" /><path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                </svg>
                        <h3 className='self-start ml-4 text-sm font-semibold'> New applications </h3>

                </div>
                <h1 className='mb-4 ml-4 text-5xl text-blue-900 font-bold' > 0 </h1>

               
            </div>
        </div>
    )
}