import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import OfferCard from '../../components/offer/offerApplyCard'
import OfferOverview from '../../components/offer/offerOverview'
import { getOffers, getOffersByStatus } from '../../api/offerApi';
import Pagination from '../../components/common/pagination';
import { Spinner } from '@material-tailwind/react';
import debounce from 'lodash.debounce';
import OfferApplyCard from '../../components/offer/offerApplyCard';


export default function OfferDashboardS() {

    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [offers, setOffers] = useState([]);
    const [totalOffers, setTotalOffers] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [employmentType, setEmploymentType] = useState('');
    const [university, setUniversity] = useState('');
    const [status, setStatus] = useState('open'); // New state for status
    const itemsPerPage = 4;

    const fetchOffers = useCallback(async () => {
        setLoading(true);
        try {
            const data = await getOffersByStatus(currentPage, itemsPerPage, searchQuery, employmentType, university, status);
            setOffers(data.offers);
            setTotalOffers(data.totalOffers);
            setLoading(false);
            if (currentPage > 1 && data.offers.length === 0) {
                setCurrentPage(1);
            }
        } catch (error) {
            console.error('Error fetching offer by user:', error);
            setLoading(false);

        }
    }, [ currentPage, searchQuery]);


    useEffect(() => {
        fetchOffers();
    }, [fetchOffers]);

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const debouncedSearch = useCallback(
        debounce((query) => {
          setSearchQuery(query);
          setCurrentPage(1); // Reset to first page on search
        }, 300),
        []
      );
      
      const handleSearchChange = (event) => {
        const query = event.target.value;
        debouncedSearch(query);
      }
   
    return (
        <div class="relative flex flex-col w-full h-full text-gray-700 bg-white shadow-md rounded-xl bg-clip-border">
            <div class="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-white rounded-none bg-clip-border">
                <div class="flex items-center justify-between gap-8 mb-8">
                    <div>
                        <h5
                            class="block font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                            My Offers
                        </h5>
                        <p class="block mt-1 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                            See information about offers
                        </p>
                    </div>
                    <div class="relative h-10 w-72">
                            <div class="absolute grid w-5 h-5 top-2/4 right-3 -translate-y-2/4 place-items-center text-blue-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" aria-hidden="true" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
                                </svg>
                            </div>
                            <input
                              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200  focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                            //   placeholder="Search"
                            //   value={searchQuery}
                              onChange={handleSearchChange}
                              type="text"
                             />
                            <label
                                class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Search
                            </label>
                        </div>
                </div>
                {/* <div class="flex flex-col items-center justify-between gap-4 md:flex-row">
                    <div class="block w-full overflow-hidden md:w-max">
                        <nav>
                            <ul role="tablist" class="relative flex flex-row p-1 rounded-lg bg-blue-gray-50 bg-opacity-60">
                                <li role="tab"
                                    class="relative flex items-center justify-center w-full h-full px-2 py-1 font-sans text-base antialiased font-normal leading-relaxed text-center bg-transparent cursor-pointer select-none text-blue-gray-900"
                                    data-value="all">
                                    <div class="z-20 text-inherit">
                                        &nbsp;&nbsp;All&nbsp;&nbsp;
                                    </div>
                                    <div class="absolute inset-0 z-10 h-full bg-white rounded-md shadow"></div>
                                </li>
                                <li role="tab"
                                    class="relative flex items-center justify-center w-full h-full px-2 py-1 font-sans text-base antialiased font-normal leading-relaxed text-center bg-transparent cursor-pointer select-none text-blue-gray-900"
                                    data-value="monitored">
                                    <div class="z-20 text-inherit">
                                        &nbsp;&nbsp;Monitored&nbsp;&nbsp;
                                    </div>
                                </li>
                                <li role="tab"
                                    class="relative flex items-center justify-center w-full h-full px-2 py-1 font-sans text-base antialiased font-normal leading-relaxed text-center bg-transparent cursor-pointer select-none text-blue-gray-900"
                                    data-value="unmonitored">
                                    <div class="z-20 text-inherit">
                                        &nbsp;&nbsp;Unmonitored&nbsp;&nbsp;
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="w-full md:w-72">
                        <div class="relative h-10 w-full min-w-[200px]">
                            <div class="absolute grid w-5 h-5 top-2/4 right-3 -translate-y-2/4 place-items-center text-blue-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" aria-hidden="true" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
                                </svg>
                            </div>
                            <input
                              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200  focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                            //   placeholder="Search"
                            //   value={searchQuery}
                              onChange={handleSearchChange}
                              type="text"
                             />
                            <label
                                class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Search
                            </label>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="self-center px-6 w-full">
        {loading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="grid md:grid-cols-2 gap-4 p-6 px-4">
            {offers.map((offer, index) => (
              <OfferApplyCard key={index} offer={offer} />
            ))}
          </div>
        )}
      </div>
           
      {!loading && (
        <div className="self-center justify-between p-4 border-t border-blue-gray-50">
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={totalOffers}
            currentPage={currentPage}
            paginate={handlePageChange}
          />
        </div>
      )}

        </div>
    )
}