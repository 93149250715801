import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';

const jobData = [
  { name: 'Job A', applications: 30 },
  { name: 'Job B', applications: 45 },
  { name: 'Job C', applications: 20 },
  { name: 'Job D', applications: 50 },
];

const JobPostingPerformance = () => (
<div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center">
    <h3 className='self-start ml-4 font-semibold'> Job Posting Performance </h3>
    <BarChart width={500} height={300} data={jobData} margin={{ top: 20, right: 40, left: 0, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name">
      </XAxis>
      <YAxis>
      </YAxis>
      <Tooltip />
      <Legend />
      <Bar dataKey="applications" fill="#8884d8" />
    </BarChart>
  </div>
);

export default JobPostingPerformance;
