import React from 'react';
import Header from '../components/common/header';
import { Outlet } from 'react-router-dom';
import SideBarAccount from '../components/sideBar/sideBarAccount';
import SideBarAccountH from '../components/sideBar/sideBarAccountH';

function LayoutSetting({ children }) {
    return (
        <div className="bg-gray-100  ">
            <Header/>

            <div className=" flex lg:flex-row flex-col h-auto  lg:p-8 lg:space-x-4 ">
                {/* <SideBarAccount /> */}
                {/* <SideBarAccountH/> */}
                <div className="hidden lg:block">
                    <SideBarAccount />
                </div>
                {/* Render this component for screen sizes smaller than md */}
                <div className="lg:hidden">
                    <SideBarAccountH />
                </div>
                <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">

                    <main>
                        <div className="bg-gray-100 mx-auto max-w-screen-2xl min-h-screen ">
                            {/* {children} */}
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}
export default LayoutSetting;