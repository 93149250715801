import React, { useState } from 'react';
import DatePicker from '../common/datePicker';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const StudentForm = ({ firstName, setFirstName, lastName, setLastName, date, setDate }) => {
  // const [date, setDate] = useState(new Date());

  // const handleDateSelect = (selectedDate) => {
  //   console.log('Selected Date:', selectedDate);
  //   setDate(selectedDate);
  // }

  // const handleDateChange = (newDate) => {
  //   console.log('New Date:', newDate);
  //   setDate(newDate);
  // }
  return (
    <div className='flex flex-col gap-2'>
   
        <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                                                First name 
                                            </label>

        <div className="relative">
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
            placeholder="Enter first name"
          />

          {/* <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-4 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
              />
            </svg>
          </span> */}
        </div>
     
<label className='self-start  block text-base font-medium text-dark dark:text-white'>
                                                Last name 
                                            </label>
        <div className="relative">
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
            placeholder="Enter second name"
          />

          {/* <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-4 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </span> */}
        </div>
      <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                                                Date of birth 
                                            </label>
      <div>
        <DatePicker date={date} setDate={setDate} />
      </div>
    </div>
  );
}

export default StudentForm;
