import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/userContext';
import { updateUser } from '../../api/userApi';
import { useNavigate } from 'react-router-dom';

export default function Security() {
    const { user } = useContext(UserContext);
    const [alert, setAlert] = useState({ type: '', message: '' });

    const [email, setEmail] = useState('')

    const [newEmail, setNewEmail] = useState('');
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    //  const id = user.id  
    useEffect(() => {
        if (user) {
          setEmail(user.email || '');
          setLoading(false);
        }
      }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'currentEmail') {
            setEmail(value);
        } else if (name === 'newEmail') {
            setNewEmail(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (user){
                // console.log(user.userId);
            await updateUser(user.userId, { email: newEmail });
        }
            setAlert({ type: 'success', message: 'information updated successfully!' });

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);

                } else if (error.response.status === 403) {
                    setAlert({ type: 'error', message: 'Forbidden. You do not have permission to edit this offer.' });
                    setTimeout(() => {
                        navigate('/unauthorized');
                    }, 1000);


                } else {
                    setTimeout(() => {
                        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

                    }, 1000);
                }
            } else {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

            }
        }
    };
    // const email = user.email
    const handleCloseAlert = () => {
        setAlert({ type: '', message: '' });
    };

    if (loading) {
        return <p>Loading...</p>;
      }
    
    return (
        <div className='bg-white py-12 px-12 container mx-auto shadow-md rounded-xl bg-clip-border '>
            <div className='fixed  top-24 right-10 space-y-4 z-50'>

                {alert.type === 'success' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-green-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
                )}
                {alert.type === 'error' && (
                    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
                        {alert.message}
                        <button
                            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type="button"
                            onClick={handleCloseAlert}
                        >
                            Close
                        </button>
                    </div>
                )}
            </div>
            <div class="relative flex flex-col text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border">
                <h4 class="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                    Account access
                </h4>
                {/*  */}


                <div className='mt-6 flex flex-col  gap-12'>
                    <form className='w-1/2' onSubmit={handleSubmit}>
                        <div className='flex flex-col items-start gap-4'>

                            <h4 class="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                                Change email
                            </h4>
                            <label className='self-start  block text-sm font-medium text-dark dark:text-white'>
                                Your email
                            </label>
                            <input
                                type='text'
                                name='currentEmail'
                                value={email}
                                // onChange={handleChange}
                                placeholder='Current email'
                                className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                            />


                            <label className='self-start  block text-sm font-medium text-dark dark:text-white'>
                                New email
                            </label>
                            <input
                                type='text'
                                name='newEmail'
                                value={newEmail}
                                onChange={handleChange}
                                placeholder='New email'
                                className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                            />
                            <button
                                className="block w-48 select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                type="submit">
                                Save
                            </button>

                        </div>
                    </form>





                    <form className='w-1/2'>
                        <div className='flex flex-col items-start gap-4 '>

                            <h4 class="block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                                Change Passowrd
                            </h4>
                            <label className='self-start text-sm block  font-medium text-dark dark:text-white'>
                                Type your current password*
                            </label>
                            <input
                                type='password'
                                name='Old Passowrd'
                                // value={email}
                                //   onChange={handleChange}
                                placeholder='oldPassowrd'
                                className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                            />


                            <label className='self-start  block text-sm font-medium text-dark dark:text-white'>
                                Type your new password*
                            </label>
                            <input
                                type='text'
                                name=' New email'
                                //   value={formData.firstName}
                                //   onChange={handleChange}
                                placeholder='New emaile'
                                className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                            />
                            <label className='self-start  block text-sm font-medium text-dark dark:text-white'>
                                Retype your new password*
                            </label>
                            <input
                                type='text'
                                name=' New email'
                                //   value={formData.firstName}
                                //   onChange={handleChange}
                                placeholder='New emaile'
                                className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                            />
                            <button
                                className="block w-48 select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                type="submit">
                                Save
                            </button>

                        </div>
                    </form>


                </div>
            </div>


        </div >
    )
}