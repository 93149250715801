import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet, } from 'react-router-dom';
import ProfilePage from './pages/profilePage';
import ProfileEditPage from './pages/profileEditPage';
import OffersPage from './pages/offersPage';
import RegisterPage from './pages/registerPage';
import LoginPage from './pages/loginPage';
import NotificationsPage from './pages/notificationsPage';
import ErrorNotFoundPage from './pages/commonPages/ErrorNotFoundPage';
import HomePage from './pages/homePage';
import UserFeedPage from './pages/userFeedPage';
import ChatPage from './pages/chatPage';
import ContactUsPage from './pages/commonPages/contactUsPage';
// import ChatPage from './pages/chatPageFirst';
import Applypage from './pages/applyPage';
import Header from './components/common/header';
import CalendarPage from './pages/dashboard/calendarPage';
import LayoutDashboard from './layout/layoutDashboard';
import MyCalendar from './components/calendar/calendar';
// import OfferDashboard from './components/offer/offerDashboard';
import ApplicationDashboard from './pages/application/applicationDashboard';
import AccountSettings from './components/profile/accountSettings';
import AccountPage from './pages/student/accountPage';
import LayoutSetting from './layout/layoutSetting';
import ProfileInformation from './components/account/profileInformation';
import Security from './components/account/security';
import Experience from './components/account/experience';
import Education from './components/account/education';
import Certificate from './components/account/certificate';
import Skill from './components/account/skill';
import LayoutProfile from './layout/layoutProfile';
import Layout from './layout/layout';
import { useContext, useEffect } from 'react';
import { AuthContext } from './contexts/authContext';
import DashboardPageStudent from './pages/dashboard/dashboardPageStudent';
import DashboardPage from './pages/dashboard/dashboardPage';
import AddOffer from './components/offer/addOffer';
import Editor from './components/common/editor';
import ApplicantDashbaord from './pages/application/applicantDashboard';
import ApplicantShortListedDashboard from './pages/application/applicantShortListedDashboard'

import { UserContext } from './contexts/userContext';
import EditOffer from './components/offer/editOffer';
import WorkingOnItPage from './pages/commonPages/WrokingOnItPage';
import OfferDashboard from './pages/offer/offerDashboard';
import Unauthorized from './pages/commonPages/unauthorized';
import useDefineAbilities from './routes/ability';
import UniversityProgram from './components/account/universityProgram';
import LoadingPage from './pages/loadingPage';
import LoadingLogoutPage from './pages/loadingLogoutPage';
import ApplicationHiredDashboard from './pages/application/applicantHiredDashboard';
import ApplicationValidationDashboard from './pages/application/applicantValidationDashboard';
import ArchiveOffer from './pages/archive/archiveOffer';
import OfferPage from './pages/offer/offerPage';



function App() {

  const { isAuthenticated } = useContext(AuthContext);
  const { user, userData } = useContext(UserContext);
// console.log('app',user);
  const ability = useDefineAbilities();

  function ProtectedRoute({ children, action, subject, fields, conditions }) {

    if (!ability.can(action, subject, fields, conditions)) {
      return <Navigate to="/unauthorized" replace />;
    }

    return <>{children}</>;
  }
  
  function PrivateRoute({ children }) {


    return isAuthenticated() ? <>{children}</> : <Navigate to="/login" />;
  }
  function PublicRoute({ children }) {


    return isAuthenticated() ?<Navigate to="/dashboard" />  : <>{children}</>;
  }

  return (
    <Router>
      <div className="App">
        
        <Routes>
          <Route path="profile/:username" element={<ProfilePage />} />

          <Route path='/' element={<Layout />}>
            <Route path="/loading" element={<LoadingPage />} />
            <Route path="/logout" element={<LoadingLogoutPage />} />
            <Route path="" element={<PublicRoute><HomePage /></PublicRoute>} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/chat" element={
               <PrivateRoute>
                <ChatPage />
              </PrivateRoute>
              } />
            <Route path="/chat/:conversationId" element={ 
              <PrivateRoute>
                <ChatPage />
              </PrivateRoute>
            } />


            {/* <Route path="chat" element={

              <PrivateRoute>
                <ChatPage />
              </PrivateRoute>
            } /> */}


            <Route path="feed" element={
              <PrivateRoute>
                <WorkingOnItPage />
              </PrivateRoute>} />
            <Route path="unauthorized" element={<Unauthorized />} />

            <Route path="login" element={<PublicRoute><LoginPage /></PublicRoute>} />
            <Route path="register" element={<PublicRoute><RegisterPage /></PublicRoute>} />



            <Route path="offers" element={<OffersPage />}/>
            <Route path="offers/:id" element={<OfferPage />}/>
            {/* <Route path="offers" element={<ProtectedRoute action="update" subject="InternshipOffer"><OffersPage /></ProtectedRoute>} /> */}

            <Route path="offers/apply/:id" element={
              <PrivateRoute>
                <ProtectedRoute action="create" subject="application">
                  <Applypage />
                </ProtectedRoute>
              </PrivateRoute>} />

          </Route>





          <Route path="dashboard" element={
            <PrivateRoute>
              <LayoutDashboard user={user} />
            </PrivateRoute>} >

            <Route path="" element={<DashboardPage />} />



            <Route path="analytics" element={<WorkingOnItPage />} />
            <Route path="reporting" element={<WorkingOnItPage />} />



            <Route path="offers" element={<OfferDashboard />} />
{/* student */}
            <Route path="offers/browse" element={<OfferDashboard />} />

            <Route path="offers/saved" element={
              <ProtectedRoute action="read" subject="saved">
                <WorkingOnItPage />
              </ProtectedRoute>
            } />
{/* company */}
            <Route path="offers/add" element={
              <ProtectedRoute action="create" subject="offer">
                <AddOffer />
              </ProtectedRoute>
            } />
            <Route path="offers/edit/:id" element={
              <ProtectedRoute 
              action="update" 
              subject="offer" 
              fields=''
              conditions={{ companyId: userData?.id }}>
                <EditOffer />
              </ProtectedRoute>
              } />
{/* university */}
            <Route path="offers/requested" element={
              <ProtectedRoute action="read" subject="requested">
                <WorkingOnItPage />
              </ProtectedRoute>
            } />
            <Route path="offers/verified" element={
              <ProtectedRoute action="read" subject="verified">
                <WorkingOnItPage />
              </ProtectedRoute>
            } />




{/* company */}
            <Route path="applications" element={<ApplicationDashboard />} />
            {/* applicant  */}
            <Route path="applications/applicants/" element={
              <ProtectedRoute action="read" subject="applicant">
                <ApplicantDashbaord />
              </ProtectedRoute>
            }>
              <Route path=":applicationId" element={
                <ProtectedRoute action="read" subject="applicant">
                  <ApplicantDashbaord />
                </ProtectedRoute>
              } />
            </Route>
            {/* shortlisted */}
            <Route path="applications/shortlists/" element={
              <ProtectedRoute action="read" subject="shortlist">
                <ApplicantShortListedDashboard />
              </ProtectedRoute>
            } >
              <Route path=":applicationId" element={
                <ProtectedRoute action="read" subject="shortlist">
                  <ApplicantShortListedDashboard />
                </ProtectedRoute>
              } />
            </Route>
{/* uni & company */}
            <Route path="applications/hired/" element={
              <ProtectedRoute action="read" subject="hired">
                <ApplicationHiredDashboard />
              </ProtectedRoute>
            } >
              <Route path=":applicationId" element={
                <ProtectedRoute action="read" subject="hired">
                  <ApplicationHiredDashboard />
                </ProtectedRoute>
              } />
            </Route>

{/* university */}
            <Route path="applications/validations/" element={
              <ProtectedRoute action="read" subject="validation">
                <ApplicationValidationDashboard />
              </ProtectedRoute>
            }>
              <Route path=":applicationId" element={
                <ProtectedRoute action="read" subject="validation">
                  <ApplicationValidationDashboard />
                </ProtectedRoute>
              } />
            </Route>
            
            
{/* _____________________________________________________________________ */}
            <Route path="students" element={<WorkingOnItPage />} />

            <Route path='forum' element={<WorkingOnItPage />} />

            <Route path="archive" element={<WorkingOnItPage />} />
            <Route path="archive/offers" element={<ArchiveOffer />} />
            <Route path="archive/applicants" element={<WorkingOnItPage />} />

            <Route path="chat" element={<ChatPage />} />
            <Route path="account" element={<AccountSettings />} />
            <Route path="calendar" element={<MyCalendar height={500} />} /> {/* Remove the leading forward slash */}
          </Route>


          <Route path="account" element={
            <PrivateRoute>
              <LayoutSetting />
            </PrivateRoute>} >
            <Route path="" element={<ProfileInformation />} />
            <Route path="security" element={<Security />} />



            <Route path="experience" element={
              <ProtectedRoute action="read" subject="experience">
                <Experience />
              </ProtectedRoute>
            } />

            <Route path="experience/add" element={
              <ProtectedRoute action="create" subject="experience">
                <Experience />
              </ProtectedRoute>
            } />

            <Route path="experience/edit" element={
              <ProtectedRoute action="create" subject="experience">
                <Experience />
              </ProtectedRoute>
            } />


            <Route path="education" element={
              <ProtectedRoute action="read" subject="education">
                <Education />
              </ProtectedRoute>
            } />

            <Route path="education/add" element={
              <ProtectedRoute action="create" subject="education">
                <Education />
              </ProtectedRoute>
            } />

            <Route path="education/edit" element={
              <ProtectedRoute action="create" subject="education">
                <Education />
              </ProtectedRoute>
            } />



            <Route path="certificate" element={
              <ProtectedRoute action="read" subject="certificate">
                <Certificate />
              </ProtectedRoute>
            } />

            <Route path="certificate/add" element={
              <ProtectedRoute action="create" subject="certificate">
                <Certificate />
              </ProtectedRoute>
            } />
            <Route path="certificate/edit" element={
              <ProtectedRoute action="create" subject="certificate">
                <Certificate />
              </ProtectedRoute>
            } />

            <Route path="skill" element={
              <ProtectedRoute action="read" subject="skill">
                <Skill />
              </ProtectedRoute>
            } />

            <Route path="program" element={
              <ProtectedRoute action="read" subject="program">
                {/* <UniversityProgram /> */}
                <WorkingOnItPage/>
              </ProtectedRoute>
            } />

            <Route path="settings" element={<AccountSettings />} />

          </Route>

          {/* <Route path="Profile" element={<ProfilePage/>}>


          </Route> */}

          <Route path="*" element={<ErrorNotFoundPage />} />
          <Route path='/contactus' element={<ContactUsPage />} />


        </Routes>

      </div>
    </Router>
  );
}

export default App;
