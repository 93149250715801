import React, { useEffect, useState } from 'react';

const ApplicantSidebar = ({ applications, offers, onSelectApplicant }) => {
    const [visibleCount, setVisibleCount] = useState(5); // Show first 10 applications initially
    const [statusFilter, setStatusFilter] = useState('');
    const [jobFilter, setJobFilter] = useState('');
    const [filteredApplications, setFilteredApplications] = useState(applications);
    // console.log(applications);
    useEffect(() => {
        // Filter applications whenever the filter changes
        let filtered = applications;

        if (statusFilter) {
            filtered = filtered.filter(app => app.status.toLowerCase() === statusFilter.toLowerCase());
        }

        if (jobFilter) {
            filtered = filtered.filter(app => app.Offer.title.toLowerCase().includes(jobFilter.toLowerCase()));
        }

        setFilteredApplications(filtered);
    }, [statusFilter, jobFilter, applications]);

    const handleLoadMore = () => {
        setVisibleCount((prevCount) => prevCount + 5); // Show 10 more applications each time
    };
    return (
        <div className=" p-2 ">
            <div className=" flex flex-col gap-2 ">
                {/* <nav className="flex min-w-[240px] flex-col gap-1 p-2 font-sans text-base font-normal text-blue-gray-700"> */}

                <h1 className='text-3xl text-left font-semibold mb-6'>
                    Candidates
                </h1>
                <div className='flex w-full gap-2 items-center justify-around'>
                    <div className='relative  '>
                        <select
                            name="statusFilter"
                            className="relative w-32 z-20 appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2"
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                        >

                            <option value="" className="dark:bg-dark-2">Status</option>
                            <option value="pending" className="dark:bg-dark-2">Pending</option>
                            <option value="shortlisted" className="dark:bg-dark-2">Shortlisted</option>
                            <option value="accepted" className="dark:bg-dark-2">Accepted</option>
                            <option value="rejected" className="dark:bg-dark-2">Rejected</option>

                        </select>
                    </div>
                    <div className='relative '>
                        <select
                            name="jobFilter"
                            className="relative w-32 appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2"
                            value={jobFilter}
                            onChange={(e) => setJobFilter(e.target.value)}
                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} // Handle text overflow

                        >
                            <option value="" className="dark:bg-dark-2">Job Offers</option>
                            {offers.map((offer) => (
                                <option key={offer.id} value={offer.title} className="dark:bg-dark-2">{offer.title}</option>
                            ))}
                        </select>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                    </svg>


                </div>
                <div role="button"
                    className="flex flex-col gap-2  h-screen overflow-y-scroll  shadow-md rounded-lg">

                    {filteredApplications.slice(0, visibleCount).map((applicant) => (

                        <div key={applicant.id} onClick={() => onSelectApplicant(applicant)}
                            className='flex flex-col items-center bg-white w-full p-3 leading-tight transition-all rounded-lg outline-none  hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900'>

                            <div className='flex  text-start  gap-2 items-center '>

                                <img alt="candice" src="https://docs.material-tailwind.com/img/face-1.jpg"
                                    className="relative inline-block h-12 w-12 !rounded-full  object-cover object-center" />

                                <div className="flex flex-col justify-center">
                                    <h6
                                        className="block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
                                        {applicant.Student.firstName}
                                    </h6>
                                    {applicant.Student.headline && (
                                    <p className="block font-sans text-sm antialiased font-normal leading-normal text-gray-700">
                                        {applicant.Student.headline}
                                    </p>
                                    )}
                                    {/* <button
                                        className="select-none rounded-lg border border-gray-900 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-gray-900 transition-all hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                        type="button"
                                      >
                                        {applicant.status}
                                    </button> */}

                                </div>

                                <div className=' ml-auto '>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                    </svg>

                                </div>
                            </div>
                            <div className={`w-1/2 relative grid items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap ${
                           applicant.status === 'accepted' ? 'bg-blue-500/20 text-blue-900' :
                           applicant.status === 'rejected' ? 'bg-gray-500/20 text-gray-900' :
                           applicant.status === 'pending' ? 'bg-yellow-500/20 text-yellow-900' :
                           applicant.status === 'shortlisted' ? 'bg-orange-500/20 text-orange-900' :
                           applicant.status === 'validated' ? 'bg-green-500/20 text-green-900' :
                           applicant.status === 'invalidated' ? 'bg-red-500/20 text-red-900' :
                           'bg-gray-200 text-gray-700' // Default background color for other statuses
                       }`}>
                                <span>{applicant.status}</span>
                            </div>
                        </div>

                    ))}
                    {visibleCount < applications.length && (
                        <button onClick={handleLoadMore} className="m-4 bg-indigo-500 text-white px-4 py-2 rounded-full">
                            Load More
                        </button>
                    )}
                </div>

                {/* </nav> */}
            </div>
        </div>
    );
};

export default ApplicantSidebar;
