import React, { useContext, useEffect, useState } from 'react';
import ExperienceCard from '../account/experienceCard';
import { getAllExperiences, getExperienceById, getExperiencesByStudent } from '../../api/experienceApi';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext';
import ExperienceProfileCard from './experienceProfileCard';

export default function ExperienceProfile({ profileData }) {
    const { userData } = useContext(UserContext);

    const [experiences, setExperiences] = useState([]);
    useEffect(() => {
        // Fetch data when component mounts
        const fetchExperience = async () => {
            try {
                const data = await getExperiencesByStudent(profileData.id); // Call the API function
                setExperiences(data);
                // Set the fetched data to state
            } catch (error) {
                console.error('Error fetching experiences:', error);
            }
        };
        fetchExperience();
        // console.log('helo',experiences);

        // Clean up function
        return () => {
            // Any cleanup code if needed
        };
    }, [profileData.id]);
    return (
        <div className='bg-white px-8 py-8  shadow-md rounded-md bg-clip-border '>
            <div className='flex flex-col justify-end'>
                <div className='flex items-center w-full justify-between'>
                    <h3 className="self-start block font-sans text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                        Experiences / Activities
                    </h3>
                    {userData && userData.userId === profileData.userId && (
                    <Link to='/account/experience' className="self-start flex select-none items-center gap-3 rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    >

                        <span className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                            </svg>
                        </span>
                    </Link>
                    )}
                </div>
                {experiences.length === 0 ? (
                    <div className='flex flex-col '>
                        <div className='self-center'>
                            <span className=''>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="gray" className="w-36 h-36">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                                </svg>
                            </span>
                        </div>

                        <div>
                            <h1> No additional work experiences are available</h1>
                            <h1> Complete your profile information for a strong resume</h1>
                        </div>
                    </div>
                ) : (
                    <div className=' mt-5'>
                        <ExperienceProfileCard experiences={experiences} />

                    </div>
                )}



                {/* <p class="flex justify-center mt-4 font-sans text-sm antialiased font-light leading-normal text-inherit">
                                    Don&apos;t have an account?
                                    <a href="#signup"
                                        class="block ml-1 font-sans text-sm antialiased font-bold leading-normal text-blue-gray-900">
                                        Sign up
                                    </a>
                                </p> */}


            </div>

        </div>
    );
}