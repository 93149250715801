import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';

const data = [
  { name: 'Jan', Applications:  18},
  { name: 'Feb', Applications: 25 },
  { name: 'Mar', Applications: 14 },
  { name: 'May', Applications: 38 },
  { name: 'Apr', Applications: 29 },
  // Add more data points
];

const ApplicationsGraph = () => (
    <div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center">
    <h3 className='self-start ml-4 font-semibold'> Applications Over Time </h3>
    <LineChart width={500} height={280} data={data} margin={{ top: 20, right: 40, left: 0, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
     <XAxis dataKey="name" />
      <YAxis />
    <Tooltip />
    <Legend />
    <Line type="monotone" dataKey="Applications" stroke="#8884d8" activeDot={{ r: 8 }} />
  </LineChart>
  </div>
);

export default ApplicationsGraph;
