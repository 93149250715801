import React from 'react';
import Sidebar from '../components/sideBar/sideBar';
import Header from '../components/common/header';
import { Outlet } from 'react-router-dom';
import Footer from '../components/common/footer';

function LayoutProfile({child1, child2}) {
    return (
        <div className="bg-gray-100 min-h-screen ">
            <Header />
            <div className=" flex lg:flex-nowrap flex-wrap sm:justify-center lg:px-24  py-6  space-x-4 space-y-4 lg:space-y-0">



                <div className='relative  w-full overflow-y-auto overflow-x-hidden '>
                    <div className='shadow-md rounded-xl bg-clip-border'>
                        {child1}
                    </div>
                    


                </div>
                {/* <div className=' relative md:basis-1/4 self-start  overflow-x-hidden '>
                    <div className='shadow-md rounded-xl bg-clip-border'>
                        {child2}
                    </div>

                </div> */}




            </div>
            <Footer/>
        </div>
    )
}
export default LayoutProfile;