import React, { useState, useEffect, useContext } from 'react';
import Pagination from '../../components/common/pagination';
import { getApplicationByCompanyId, getApplicationById, getApplicationBystudentId, getApplications, updateApplicationStatus } from '../../api/applicationApi';
import { Link, useNavigate } from 'react-router-dom';


import { UserContext } from '../../contexts/userContext';
import { Spinner } from '@material-tailwind/react';


export default function ApplicationDashboardC() {
  const { userData } = useContext(UserContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add loading state
  const [applications, setApplications] = useState([]);
  const [filter, setFilter] = useState('all'); // 'all', 'pending', 'accepted', 'rejected'
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' for ascending, 'desc' for descending
  const [alert, setAlert] = useState({ type: '', message: '' });

  const itemsPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const applicationData = await getApplicationByCompanyId(userData.id);
        // const applicationData = await getApplications();
        // console.log(applicationData);
        setApplications(applicationData);
        setLoading(false); // Set loading to false when offers are fetched

      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    };

    fetchApplications();
  }, [currentPage]);

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const handleSortChange = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);

    const sortedApplications = [...applications].sort((a, b) => {
      const dateA = new Date(a.applicationDate);
      const dateB = new Date(b.applicationDate);

      return newSortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setApplications(sortedApplications);
  };

  const handleFilterChange = (status) => {
    setFilter(status);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const filteredApplications = applications.filter(application =>
    filter === 'all' || application.status === filter
  );

  const handleRejectApplication = async (id) => {
    try {
      await updateApplicationStatus(id, 'rejected');
      setApplications((prevApplications) =>
        prevApplications.map((app) =>
          app.id === id ? { ...app, status: 'rejected' } : app
        )
      );
      setAlert({ type: 'success', message: 'Application rejected successfully!' });

    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
          setTimeout(() => {
            navigate('/login');
          }, 1000);

        } else if (error.response.status === 403) {
          setAlert({ type: 'error', message: 'Forbidden. You do not have permission to add experience.' });
          setTimeout(() => {
            navigate('/unauthorized');
          }, 1000);


        } else {
          setTimeout(() => {
            setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

          }, 1000);
        }
      } else {
        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

      }

    }
  };


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Slice the offers array to get the items for the current page
  const currentData = filteredApplications.slice(indexOfFirstItem, indexOfLastItem);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
  };

  const handleCloseAlert = () => {
    setAlert({ type: '', message: '' });
  };
  // if (loading) {
  //   return <div>Loading...</div>;
  // }
  return (
    <div class="relative flex flex-col w-full h-full text-gray-700 bg-white shadow-md rounded-xl bg-clip-border">
 <div className='fixed  top-24 right-10 space-y-4 z-50'>

{alert.type === 'success' && (
    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-green-500 rounded-lg shadow-lg opacity-100 font-regular">
        {alert.message}
        <button
            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
            type="button"
            onClick={handleCloseAlert}
        >
            Close
        </button>
    </div>
)}
{alert.type === 'error' && (
    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
        {alert.message}
        <button
            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
            type="button"
            onClick={handleCloseAlert}
        >
            Close
        </button>
    </div>
)}
</div>
      <div class="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-white rounded-none bg-clip-border">
        <div class="flex items-center justify-between gap-8 mb-8">
          <div>
            <h5
              class="block font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
              Applications list
            </h5>
            <p class="block mt-1 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
              See information about all applications
            </p>
          </div>
          <div class="flex flex-col gap-2 shrink-0 sm:flex-row">
            {/* <button
              class="select-none rounded-lg border border-gray-900 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-gray-900 transition-all hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              type="button">
              view all
            </button> */}
            {/* <button
          class="flex select-none items-center gap-3 rounded-lg bg-gray-900 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true"
            stroke-width="2" class="w-4 h-4">
            <path
              d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z">
            </path>
          </svg>
          Add member
        </button> */}
          </div>
        </div>
        <div class="flex flex-col items-center justify-between gap-4 md:flex-row">
          <div class="block w-full overflow-hidden md:w-max">
            <nav>
              <ul role="tablist" className="relative flex flex-row p-1 rounded-lg bg-blue-gray-50 bg-opacity-60">
                <li
                  role="tab"
                  className={`relative flex items-center justify-center w-full h-full px-2 py-1 font-sans text-base antialiased font-normal leading-relaxed text-center bg-transparent cursor-pointer select-none text-blue-gray-900 ${filter === 'all' ? 'bg-white shadow rounded-md' : ''}`}
                  onClick={() => handleFilterChange('all')}
                >
                  <div className="z-20 text-inherit">
                    &nbsp;&nbsp;All&nbsp;&nbsp;
                  </div>
                </li>
                <li
                  role="tab"
                  className={`relative flex items-center justify-center w-full h-full px-2 py-1 font-sans text-base antialiased font-normal leading-relaxed text-center bg-transparent cursor-pointer select-none text-blue-gray-900 ${filter === 'pending' ? 'bg-white shadow rounded-md' : ''}`}
                  onClick={() => handleFilterChange('pending')}
                >
                  <div className="z-20 text-inherit">
                    &nbsp;&nbsp;Pending&nbsp;&nbsp;
                  </div>
                </li>
                <li
                  role="tab"
                  className={`relative flex items-center justify-center w-full h-full px-2 py-1 font-sans text-base antialiased font-normal leading-relaxed text-center bg-transparent cursor-pointer select-none text-blue-gray-900 ${filter === 'accepted' ? 'bg-white shadow rounded-md' : ''}`}
                  onClick={() => handleFilterChange('accepted')}
                >
                  <div className="z-20 text-inherit">
                    &nbsp;&nbsp;Accepted&nbsp;&nbsp;
                  </div>
                </li>
                <li
                  role="tab"
                  className={`relative flex items-center justify-center w-full h-full px-2 py-1 font-sans text-base antialiased font-normal leading-relaxed text-center bg-transparent cursor-pointer select-none text-blue-gray-900 ${filter === 'rejected' ? 'bg-white shadow rounded-md' : ''}`}
                  onClick={() => handleFilterChange('rejected')}
                >
                  <div className="z-20 text-inherit">
                    &nbsp;&nbsp;Rejected&nbsp;&nbsp;
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div class="w-full md:w-72">
            <div class="relative h-10 w-full min-w-[200px]">
              <div class="absolute grid w-5 h-5 top-2/4 right-3 -translate-y-2/4 place-items-center text-blue-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" aria-hidden="true" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
                </svg>
              </div>
              <input
                class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                placeholder=" " />
              <label
                class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Search
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="p-6 px-0">
        <div className='overflow-x-auto  w-full'>
          <table class="w-full mt-4 text-left table-auto min-w-max ">
            <thead>
              <tr>
                <th
                  class="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50">
                  <p
                    class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70">
                    Applicant
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                    stroke="currentColor" aria-hidden="true" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                  </svg> */}
                  </p>
                </th>
                <th
                  class="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50">
                  <p
                    class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70">
                    Offer
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                    stroke="currentColor" aria-hidden="true" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                  </svg> */}
                  </p>
                </th>
                <th
                  class="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50">
                  <p
                    class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70">
                    Status
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                    stroke="currentColor" aria-hidden="true" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                  </svg> */}
                  </p>
                </th>
                <th
                  className="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50"
                  onClick={handleSortChange}
                >
                  <p
                    className="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70"
                  >
                    Application Date
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                      stroke="currentColor" aria-hidden="true" className="w-4 h-4">
                      {sortOrder === 'asc' ? (
                        <path strokeLinecap="round" strokeLinejoin="round"
                          d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"></path>
                      ) : (
                        <path strokeLinecap="round" strokeLinejoin="round"
                          d="M8.25 9L12 5.25 15.75 9m-7.5 6L12 18.75 15.75 15"></path>
                      )}
                    </svg>
                  </p>
                </th>
                <th
                  class="p-4 transition-colors cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 hover:bg-blue-gray-50">
                  <p
                    class="flex items-center justify-between gap-2 font-sans text-sm antialiased font-normal leading-none text-blue-gray-900 opacity-70">
                  </p>
                </th>
              </tr>
            </thead>
            <tbody className=' overflow-y-scroll'>
              {loading ? (
                <div className="flex justify-center items-center h-screen">
                  <Spinner className="h-12 w-12" />
                </div>
              ) : currentData.length === 0 ? (
                <tr>
                  <td colSpan={5} className="text-center pt-4">
                    No Applications Found
                  </td>
                </tr>
              ) : (currentData.map((application, index) => (
                <tr key={index}>
                  <td className="p-4 border-b border-blue-gray-50 max-w-40">
                    <Link to={`/profile/${application.Student.User.userName}`}>

                      <div className="flex items-center gap-3">
                        {/* <img src={row.member.imageSrc} alt={row.member.name} className="relative inline-block h-9 w-9 !rounded-full object-cover object-center" /> */}
                        <p className="block underline font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900 line-clamp-1">
                          {application.Student.firstName} {application.Student.lastName}

                        </p>
                      </div>
                    </Link>

                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <p className="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                      {application.Offer.title}
                    </p>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="w-max">
                    <div className={`relative grid items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap ${
                           application.status === 'accepted' ? 'bg-blue-500/20 text-blue-900' :
                           application.status === 'rejected' ? 'bg-gray-500/20 text-gray-900' :
                           application.status === 'pending' ? 'bg-yellow-500/20 text-yellow-900' :
                           application.status === 'shortlisted' ? 'bg-orange-500/20 text-orange-900' :
                           application.status === 'validated' ? 'bg-green-500/20 text-green-900' :
                           application.status === 'invalidated' ? 'bg-red-500/20 text-red-900' :
                           'bg-gray-200 text-gray-700' // Default background color for other statuses
                       }`}>
                        <span>{application.status}</span>
                      </div>
                    </div>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <p className="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                      {formatDate(application.applicationDate)}
                    </p>
                  </td>
                  <td className="p-4 border-b border-blue-gray-50">
                    <div className="flex justify-end gap-4">

                      <Link to={`applicants/${application.id}`} className="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase text-gray-900 transition-all hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button">
                        <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path d="M11.625 16.5a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z" />
                            <path fillRule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875Zm6 16.5c.66 0 1.277-.19 1.797-.518l1.048 1.048a.75.75 0 0 0 1.06-1.06l-1.047-1.048A3.375 3.375 0 1 0 11.625 18Z" clipRule="evenodd" />
                            <path d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                          </svg>

                        </span>
                      </Link>

                      {/* <button
                        onClick={() => handleRejectApplication(application.id)}
                        className="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase text-gray-900 transition-all hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                      </button> */}
                    </div>
                  </td>
                </tr>
              )))}



            </tbody>
          </table>
        </div>
      </div>
      <div class="self-center justify-between p-4 border-t border-blue-gray-50">

        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={applications.length}
          currentPage={currentPage}

          paginate={handlePageChange}
        />

      </div>
    </div>)
}