

import React, { useState } from 'react';
import { Dialog } from '@material-tailwind/react';

export default function EducationDialog({ open, handleOpen, handleSubmit, formData, handleChange, date, handleDateChange }) {
    return (

<Dialog open={open} handler={handleOpen}>


                            <div className="flex flex-col gap-4 px-6 h-screen overflow-scroll">
                                <div className='sticky top-0 z-50 p-4 rounded-xl bg-clip-border bg-white  '>
                                    <h4
                                        className=" block font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                                        Add Education / Activity
                                    </h4>
                                </div>

                                <form onSubmit={handleSubmit}>

                                    <div className='  pt-4 flex flex-col gap-3 '>
                                        <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                                            School *
                                        </label>
                                        <input
                                            type="text"
                                            name="school"
                                            value={formData.school}
                                            onChange={handleChange}
                                            placeholder='enter a school name'
                                            className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                                        />


                                        <label className='self-start block text-base font-medium text-dark dark:text-white'>
                                            Degree
                                        </label>
                                        <input
                                            type="text"
                                            name="degree"
                                            value={formData.degree}
                                            onChange={handleChange}
                                            placeholder='ex : bachelor'

                                            className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'

                                        />


                                        <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                                            Field of study
                                        </label>
                                        <input
                                            type='text'
                                            name="fieldOfStudy"
                                            value={formData.fieldOfStudy}
                                            onChange={handleChange}
                                            placeholder='ex : business'
                                            className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                                        />



                                        <div className='flex gap-4'>
                                            <div className='flex flex-col gap-3 basis-1/2'>
                                                <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                                                    location
                                                </label>
                                                <input
                                                    type='text'
                                                    name="location"
                                                    value={formData.location}
                                                    onChange={handleChange}
                                                    placeholder='enter location name'
                                                    className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                                                />
                                            </div>

                                            <div className='flex flex-col gap-3 basis-1/2'>
                                                <label className='self-start block text-base font-medium text-dark dark:text-white'>
                                                    Location type
                                                </label>
                                                <div className='relative z-20'>
                                                    <select
                                                        name="locationType"
                                                        value={formData.locationType}
                                                        onChange={handleChange}
                                                        className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'>
                                                        <option value='' className='dark:bg-dark-2'>Select location type</option>

                                                        <option value='on site' className='dark:bg-dark-2'>on site</option>

                                                        <option value='remote' className='dark:bg-dark-2'>remote</option>
                                                        <option value='hybrid' className='dark:bg-dark-2'>hybrid</option>
                                                    </select>
                                                    <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                                                </div>

                                            </div>
                                        </div>


                                        {/* <div className="self-start">
                                        <div className="inline-flex items-center">
                                            <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="remember">
                                                <input type="checkbox"
                                                    className="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                                    id="remember" />
                                                <span
                                                    className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
                                                        stroke="currentColor" stroke-width="1">
                                                        <path fill-rule="evenodd"
                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                            clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            </label>
                                            <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="remember">
                                                I am currently working in this role
                                            </label>
                                        </div>
                                    </div> */}

                                        <label className='self-start block text-base font-medium text-dark dark:text-white'>
                                            Start date
                                        </label>

                                        <div className='flex gap-4'>

                                            <div className='flex flex-col gap-3 basis-1/2'>

                                                <div className='relative z-20'>
                                                    <select
                                                        name="startMonth"
                                                        value={date.startMonth}
                                                        onChange={handleDateChange}

                                                        className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'>
                                                        <option value='' className='dark:bg-dark-2'>select a month</option>

                                                        <option value='01' className='dark:bg-dark-2'>January</option>
                                                        <option value='02' className='dark:bg-dark-2'>February</option>
                                                        <option value='03' className='dark:bg-dark-2'>March</option>
                                                        <option value='04' className='dark:bg-dark-2'>April</option>
                                                        <option value='05' className='dark:bg-dark-2'>May</option>
                                                        <option value='06' className='dark:bg-dark-2'>June</option>
                                                        <option value='07' className='dark:bg-dark-2'>July</option>
                                                        <option value='08' className='dark:bg-dark-2'>August</option>
                                                        <option value='09' className='dark:bg-dark-2'>September</option>
                                                        <option value='10' className='dark:bg-dark-2'>October</option>
                                                        <option value='11' className='dark:bg-dark-2'>November</option>
                                                        <option value='12' className='dark:bg-dark-2'>December</option>
                                                    </select>
                                                    <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                                                </div>

                                            </div>

                                            <div className='flex flex-col gap-3 basis-1/2'>

                                                <div className='relative z-20'>
                                                    <select
                                                        name="startYear"

                                                        className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
                                                        value={date.startYear}
                                                        onChange={handleDateChange}
                                                    >
                                                        <option value='' className='dark:bg-dark-2'>Select year</option>
                                                        {Array.from({ length: 31 }, (_, i) => (
                                                            <option key={i} value={2000 + i} className='dark:bg-dark-2'>{2000 + i}</option>
                                                        ))}
                                                    </select>
                                                    <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                                                </div>

                                            </div>
                                        </div>

                                        <label className='self-start block text-base font-medium text-dark dark:text-white'>
                                            End date (or expected)
                                        </label>
                                        <div className='flex gap-4'>

                                            <div className='flex flex-col gap-3 basis-1/2'>

                                                <div
                                                    disabled
                                                    className='relative z-20'>
                                                    <select
                                                        name="endMonth"
                                                        className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
                                                        value={date.endMonth}
                                                        onChange={handleDateChange}
                                                    // disabled={formData.currentlyWorking}

                                                    >

                                                        <option value='' className='dark:bg-dark-2'>select a month</option>

                                                        <option value='01' className='dark:bg-dark-2'>January</option>
                                                        <option value='02' className='dark:bg-dark-2'>February</option>
                                                        <option value='03' className='dark:bg-dark-2'>March</option>
                                                        <option value='04' className='dark:bg-dark-2'>April</option>
                                                        <option value='05' className='dark:bg-dark-2'>May</option>
                                                        <option value='06' className='dark:bg-dark-2'>June</option>
                                                        <option value='07' className='dark:bg-dark-2'>July</option>
                                                        <option value='08' className='dark:bg-dark-2'>August</option>
                                                        <option value='09' className='dark:bg-dark-2'>September</option>
                                                        <option value='10' className='dark:bg-dark-2'>October</option>
                                                        <option value='11' className='dark:bg-dark-2'>November</option>
                                                        <option value='12' className='dark:bg-dark-2'>December</option>
                                                    </select>
                                                    <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                                                </div>

                                            </div>

                                            <div className='flex flex-col gap-3 basis-1/2'>

                                                <div className='relative z-20'>
                                                    <select
                                                        // disabled
                                                        name="endYear"
                                                        className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
                                                        value={date.endYear}
                                                        onChange={handleDateChange}
                                                    // disabled={formData.currentlyWorking}

                                                    >

                                                        <option value='' className='dark:bg-dark-2'>Select year</option>
                                                        {Array.from({ length: 31 }, (_, i) => (
                                                            <option key={i} value={2000 + i} className='dark:bg-dark-2'>{2000 + i}</option>
                                                        ))}
                                                    </select>
                                                    <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                                                </div>

                                            </div>
                                        </div>


                                        <label className='self-start  block text-base font-medium text-dark dark:text-white'>
                                            Grade
                                        </label>
                                        <input
                                            type='text'
                                            name="grade"
                                            value={formData.grade}
                                            onChange={handleChange}
                                            placeholder='ex : A'
                                            className='w-full bg-transparent rounded-md border border-primary py-[10px] px-5 text-dark-5 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2'
                                        />

                                        <label className='mt-6 self-start block text-base font-medium text-dark dark:text-white'>
                                            Description
                                        </label>
                                        <textarea
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            rows='5'
                                            placeholder='Default textarea'
                                            className='w-full bg-transparent rounded-md border border-stroke dark:border-dark-3 p-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
                                        />

                                        <div className="flex p-6 pt-6 gap-4">
                                            <button
                                                className="block w-48 select-none rounded-lg bg-gradient-to-tr from-gray-900 to-gray-800 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                                type="submit">
                                                Save
                                            </button>
                                            <button
                                                className="block w-48 select-none rounded-lg bg-white py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-black border-black shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                                type="button"
                                                onClick={handleOpen}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* <p className="flex justify-center mt-4 font-sans text-sm antialiased font-light leading-normal text-inherit">
                                    Don&apos;t have an account?
                                    <a href="#signup"
                                        className="block ml-1 font-sans text-sm antialiased font-bold leading-normal text-blue-gray-900">
                                        Sign up
                                    </a>
                                </p> */}

                        </Dialog>
    )
}