import React, { useContext, useEffect, useState } from 'react';
import SkillDialog from './skillDialog';
import { UserContext } from '../../contexts/userContext';
import { getStudentById } from '../../api/studentApi';

const items = ["Data science", "python", "project management", "git", "html", "css", "javascript",];

export default function Skill() {

    const [open, setOpen] = useState(false);
    const [skills, setSkills] = useState([]);
    const { userData } = useContext(UserContext);
    const studentId = userData.id;
    const handleOpen = () => {
        setOpen(!open);
        // navigate('/account/education/add');
    };

    const fetchStudentSkills = async () => {
        try {
            const response = await getStudentById(studentId);
            setSkills(response.skills);
            console.log(skills);
        } catch (error) {
            console.error('Failed to fetch skills:', error);
        }
    };

    useEffect(() => {
        if (userData) {
            fetchStudentSkills();
        }
    }, [userData]);


    return (
        <div className='bg-white px-8 py-8  shadow-md rounded-xl bg-clip-border '>
            <div className='flex flex-col justify-end'>
                <h3 className="self-start block font-sans text-3xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                    Skills
                </h3>
                



                <div className=" sm:p-6   flex flex-wrap items-center  justify-start gap-2">

                    <div className=''>
                        {/* <button
                            className=" inline-flex  items-center justify-center rounded-full border border-black bg-transparent   px-3 py-1 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-100 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3"
                            type="button" >

                            <span className='' onClick={handleOpen}>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                                </svg>
                            </span>
                        </button> */}





                    </div>
                    {skills && skills.length > 0 ? (
    <>
        <button
            className="inline-flex items-center justify-center rounded-full border border-black bg-transparent px-3 py-1 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-100 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3"
            type="button"
            onClick={handleOpen}
        >
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" className="w-6 h-6">
                    <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                </svg>
            </span>
        </button>
        {skills.map((skill, index) => (
            <span
                key={index}
                className="inline-flex items-center justify-center rounded-full bg-cyan-100 px-7 py-3 text-base font-medium text-dark"
            >
                {skill}
            </span>
        ))}
    </>
                    ) : (
                        <>
                            <div className='flex flex-col w-full'>
                                <div className='self-center'>
                                    <button className=''>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="gray" className="w-36 h-36">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                                        </svg>


                                    </button>
                                </div>
                                <div>
                                    <h1> No additional skills informations are available</h1>
                                    <h1> Complete your profile information for a strong resume</h1>
                                </div>
                                
                            </div>
                            <button
                                    className=" flex  items-center justify-center rounded-full border border-black bg-transparent   px-3 py-1 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-100 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3"
                                    type="button" onClick={handleOpen}>

                                    <span className='' >

                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                                </svg>
                                </span>
                            Add a skill
                                </button>
                            </>
                    )}

                    {/* {items.map((item, index) => (
                        <a
                            key={index} // Use a unique key for each item
                            href="#0"
                            className=" inline-flex items-center justify-center rounded-full border border-transparent bg-amber-200 px-7 py-3 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-4 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3"
                        >
                            {item}
                        </a>
                    ))} */}


                </div>

                <SkillDialog open={open} handleOpen={handleOpen} studentId={studentId} currentSkills={skills} setSkills={setSkills} skills={skills} />


                {/* <div className='mt-8 '> */}
                {/* <button
                            className="flex select-none items-center rounded-lg bg-gray-900 py-3 px-6 text-center align-middle font-sans text-xs font-bold  text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                            type="button" data-dialog-target="experience-dialog">
                            <span className='mr-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                                </svg></span>
                            Add Skill
                        </button> */}


                {/* <p class="flex justify-center mt-4 font-sans text-sm antialiased font-light leading-normal text-inherit">
                                    Don&apos;t have an account?
                                    <a href="#signup"
                                        class="block ml-1 font-sans text-sm antialiased font-bold leading-normal text-blue-gray-900">
                                        Sign up
                                    </a>
                                </p> */}

                {/* </div>        */}
            </div>


        </div>
    )
}