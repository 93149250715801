import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';
import { Spinner } from '@material-tailwind/react';

const LoadingPage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.role) {
      navigate('/dashboard');
    } else {
      navigate(0)
    }
  }, [user, navigate]);



  return (
    <div className="flex justify-center items-center h-screen">
      <Spinner className="h-12 w-12" />
    </div>
  );};

export default LoadingPage;
