import React from 'react';

export default function EducationProfileCard({ educations }) {
    // console.log(educations);
    function calculateDuration(start, end) {
        const startDate = new Date(start);
        const endDate = end ? new Date(end) : new Date(); // If endDate is null, use current date
        const diffInMs = Math.abs(endDate - startDate);
        const months = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30)); // Approximation for months
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        return `${years} yr ${remainingMonths} mos`;
    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    }
    return (
        <div className='flex flex-col space-y-4'>
            {educations.map(education => (

                <article class="rounded-xl bg-white p-4 ring ring-indigo-50 sm:p-6 lg:p-8">
                    <div class="flex items-start sm:gap-8">
                        <div
                            class="hidden sm:grid sm:size-20 sm:shrink-0 sm:place-content-center sm:rounded-full sm:border-2 sm:border-indigo-500"
                            aria-hidden="true"
                        >
                            <div class="flex items-center gap-1">
                                <span class="h-8 w-0.5 rounded-full bg-indigo-500"></span>
                                <span class="h-6 w-0.5 rounded-full bg-indigo-500"></span>
                                <span class="h-4 w-0.5 rounded-full bg-indigo-500"></span>
                                <span class="h-6 w-0.5 rounded-full bg-indigo-500"></span>
                                <span class="h-8 w-0.5 rounded-full bg-indigo-500"></span>
                            </div>
                        </div>

                        <div className='flex flex-col grow '>
                            {/* <strong
                        class="rounded border border-indigo-500 bg-indigo-500 px-3 py-1.5 text-[10px] font-medium text-white"
                    >
                        Episode #101
                    </strong> */}

                            <h3 class=" self-start  text-lg font-medium sm:text-xl">
                                <span href="#" class="hover:underline">{education.school} </span>
                            </h3>
                            <h1 class=" self-start    font-xs text-sm">
                                <span href="#" class="hover:underline"> {education.degree}  </span>
                                <span> · </span>
                                <span>{education.fieldOfStudy} </span>
                            </h1>

                            <h1 className="self-start  font-xs text-sm">
                                {education.currentlyWorking ? (
                                   <>
                                   <span>{formatDate(education.startDate)} - present</span>
                                   <span> · </span>
                                   <span>{calculateDuration(education.startDate)}</span>
                               </>
                           ) : (
                               <>
                                   <span>{formatDate(education.startDate)}</span>
                                   <span> ·  </span>
                                   <span>{formatDate(education.endDate)}</span>
                               </>
                                )}
                            </h1>
                            <p class="mt-2 text-sm text-gray-700 text-start line-clamp-2">
                                {education.description}
                            </p>
                            </div>
                            
                        



                          

                    </div>
                </article>
            ))}

        </div>
    )
}