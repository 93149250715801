import React from 'react';
import { Link } from 'react-router-dom';
function ExploreOffer() {
    return (
        <section className="bg-blue-500/20 dark:bg-gray-900">
            <div className="container px-6 py-10 mx-auto">
                <div className="lg:flex lg:items-center ">
                    <div className="w-full  lg:w-1/2 mb-6 lg:mb-0">
                        <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">
                            Discover the perfect internship opportunity for you.
                        </h1>


                    </div>
                    <div className=" flex flex-wrap items-center justify-center lg:w-1/2  gap-3">
                    <Link to="offers" className="inline-flex items-center justify-center rounded-full border border-transparent bg-white px-7 py-3 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-4 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3">
        Data Science
    </Link>
    <Link to="offers" className="inline-flex items-center justify-center rounded-full border border-transparent bg-white px-7 py-3 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-4 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3">
        Network
    </Link>
    <Link to="offers" className="inline-flex items-center justify-center rounded-full border border-transparent bg-white px-7 py-3 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-4 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3">
        IoT
    </Link>
    <Link to="offers" className="inline-flex items-center justify-center rounded-full border border-transparent bg-white px-7 py-3 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-4 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3">
        Cloud
    </Link>
    <Link to="offers" className="inline-flex items-center justify-center rounded-full border border-transparent bg-white px-7 py-3 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-4 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3">
        Mobile Development
    </Link>
    <Link to="offers" className="inline-flex items-center justify-center rounded-full border border-transparent bg-white px-7 py-3 text-center text-base font-medium text-dark shadow-1 hover:bg-gray-4 disabled:border-gray-3 disabled:bg-gray-3 disabled:text-dark-5 dark:bg-gray-2 dark:shadow-box-dark dark:hover:bg-dark-3">
        Web Development
    </Link>



                    </div>
                </div>
            </div>

        </section>
    )
}
export default ExploreOffer