

import { Card } from '@material-tailwind/react';
import React, { useState } from 'react';
import Select from 'react-select';
import debounce from 'lodash.debounce';

export default function SidebarFilterContent({ onFilter, universities  }) {
    const [searchQuery, setSearchQuery] = useState('');
  const [employmentType, setEmploymentType] = useState('');
  const [university, setUniversity] = useState('');

  const handleInputChange = debounce((event) => {
    const query = event.target.value;
    setSearchQuery(query);
    onFilter({ searchQuery: query, employmentType, university });
  }, 300);

  const handleEmploymentChange = (event) => {
    const type = event.target.value;
    setEmploymentType(type);
    onFilter({ searchQuery, employmentType: type, university });
  };

  const handleUniversityChange = (event) => {
    const uni = event.target.value;
    setUniversity(uni);
    onFilter({ searchQuery, employmentType, university: uni });
  };
  const handleResetFilters = () => {
    setSearchQuery('');
    setEmploymentType('');
    setUniversity('');
    onFilter({ searchQuery: '', employmentType: '', university: '' });
  };
    // const handleSearchInputChange = (event) => {
    //     const query = event.target.value;
    //     setSearchQuery(query);
    //     // Call onFilter with the search query
    //     onFilter(query);
    // };
    return (
        <Card className="bg-white mx-20 p-4 no-scrollbar overflow-y-auto overflow-x-hidden  ease-linear h-auto   rounded-xl shadow-md  shadow-blue-gray-900/5">
            <div className="flex  flex-wrap justify-center items-center gap-4  ">
               
                <div>
                <label className='text-left block text-base font-medium text-dark dark:text-white '>
                Search
                    </label>
                    <div className=" pt-2 w-full md:w-72" >
                        <div className="relative h-10 w-full sm:min-w-[200px]">
                            <div className="absolute grid w-5 h-5 top-2/4 right-3 -translate-y-2/4 place-items-center text-blue-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" aria-hidden="true" className="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
                                </svg>
                            </div>
                            <input
                                className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200  focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                                placeholder=""
                                value={searchQuery}
                                onChange={(event) => {
                                  setSearchQuery(event.target.value);
                                  handleInputChange(event);
                                }}
                                type="text"
                            />
                            

                            <label
                                className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Search by title, skill, ..
                                {/* Search  */}
                            </label>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col'>
                    <label className='self-start block text-base font-medium text-dark dark:text-white '>
                        Employment type
                    </label>
                    <div className='relative z-20 w-full pt-2'>
                       <select
              className='relative z-20 w-full min-w-[200px] appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
              value={employmentType}
              onChange={handleEmploymentChange}
            >
              <option value=''>Select employment type</option>
              <option value='full time'>Full-time</option>
              <option value='half time'>Part-time</option>
              <option value='freelance'>Freelance</option>
            </select>
                        {/* <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span> */}
                    </div>
                </div>
                {/* <div>
                    <label className='self-start block text-base font-medium text-dark dark:text-white '>
                        Location
                    </label>
                    <div className=" pt-2 w-full md:w-72" >
                        <div className="relative h-10 w-full min-w-[200px]">
                            <div className="absolute grid w-5 h-5 top-2/4 right-3 -translate-y-2/4 place-items-center text-blue-gray-500">

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                                </svg>

                            </div>
                            <input
                                className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 !pr-9 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200  focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                                placeholder="
                                " />
                            <label
                                className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Search for location
                            </label>
                        </div>
                    </div>
                </div> */}


                {/* <label className='self-start block text-base font-medium text-dark dark:text-white pt-4'>
                        Location
                    </label>
                    <div className='relative z-20 w-full'>
                        <select className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'>
                            <option value='' className='dark:bg-dark-2'>full time</option>
                            <option value='' className='dark:bg-dark-2'>half time</option>
                            <option value='' className='dark:bg-dark-2'>freelance</option>
                        </select>
                        <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span>
                    </div>
                     */}

                <div className='flex flex-col'> 
                    <label className='self-start block text-base font-medium text-dark dark:text-white '>
                        University
                    </label>
                    <div className='relative z-20 w-full min-w-[200px] pt-2'>
                    <select
              className='relative z-20 w-full appearance-none rounded-lg border border-stroke dark:border-dark-3 bg-transparent py-[10px] px-5 text-dark-6 outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-gray-2'
              value={university}
              onChange={handleUniversityChange}
            >
              <option value=''>Select university</option>
              {universities.map((uni) => (
                <option key={uni.id} value={uni.id} className='dark:bg-dark-2'>{uni.universityName}</option>
              ))}
            </select>
                        {/* <span className='absolute right-4 top-1/2 z-10 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2 border-body-color'></span> */}
                    </div>
                </div>
                <div className='self-end'>
                <button onClick={handleResetFilters} className="bg-blue-500 text-white rounded-lg py-2 px-4 mt-4">
          Reset Filters
        </button></div>
            </div>
        </Card>


    )
}