// permissions.js
import { defineAbility } from '@casl/ability';
import { useContext, useState } from 'react';
import { UserContext } from '../contexts/userContext';


export default  function useDefineAbilities  () {
  // const [companyId, setCompanyId] = useState(null);

    const { user, userData } = useContext(UserContext);
    const role = user && user.role;
    const companyId = userData && userData.id;


return defineAbility ((can,cannot)=>{
  switch (role) {
    case 'student':
      // can('read', 'Profile', { userId: 'own' });
      // can('update', 'Profile', { userId: 'own' });
      // can('read', 'InternshipOffer');
      // can('create', 'Application');
      // can('read', 'ChatMessage');
      // can('create', 'ChatMessage');
      can('create', 'application');
      can('read','experience');
      can('create','experience');
      can('read','education');
      can('create','education');
      can('read','certificate');
      can('create','certificate');
      can('read','skill');
      can('read','saved');




      break;
   
      case 'company':
        // can('read', 'Profile', { userId: 'own' });
        // can('update', 'Profile', { userId: 'own' });
        // can('create', 'InternshipOffer');
        // can('update', 'InternshipOffer', { companyId: 'own' });
        // can('read', 'Application');
        // can('update', 'Application', { companyId: 'own' });
        // can('read', 'ChatMessage');
        // can('create', 'ChatMessage');
        // can('create', 'Meeting');
        // cannot('create', 'application');
        can('create','offer')
        can('read','applicant')
        can('read','shortlist')
        can('update','offer','',{companyId: companyId})
        can('read', 'hired')
  

      break;
      case 'university':
        // can('read', 'Profile', { userId: 'own' });
        // can('read', 'InternshipOffer');
        // can('read', 'Internship');
        // can('update', 'Internship', { universityId: 'own' });
        // can('read', 'ChatMessage');
        // can('create', 'ChatMessage');
        // can('read', 'Statistics');
        // cannot('create', 'application');
        can ('read','program')
        can ('read','validation')
        can ('read', 'requested')
        can ('read', 'verified')
        can ('read', 'hired')

      break;
    case 'admin':
      can('manage', 'all');
      break;
    default:
      // No permissions for unknown roles
      break;
  }
})
  // return defineAbility('user', (can, cannot) => {
  //   can(rules);
  // });
}
// );
