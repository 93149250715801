import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    IconButton,
    Avatar,
    Typography,
  } from "@material-tailwind/react";
import { UserContext } from "../../contexts/userContext";
import { useContext, useEffect, useState } from "react";
import { useSocket } from "../../contexts/socketContext";
import { getNotificationsByUserId ,markAsRead } from "../../api/notificationApi";
   
  function ClockIcon() {
    return (
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99998 14.9C9.69736 14.9 11.3252 14.2257 12.5255 13.0255C13.7257 11.8252 14.4 10.1974 14.4 8.49998C14.4 6.80259 13.7257 5.17472 12.5255 3.97449C11.3252 2.77426 9.69736 2.09998 7.99998 2.09998C6.30259 2.09998 4.67472 2.77426 3.47449 3.97449C2.27426 5.17472 1.59998 6.80259 1.59998 8.49998C1.59998 10.1974 2.27426 11.8252 3.47449 13.0255C4.67472 14.2257 6.30259 14.9 7.99998 14.9ZM8.79998 5.29998C8.79998 5.0878 8.71569 4.88432 8.56566 4.73429C8.41563 4.58426 8.21215 4.49998 7.99998 4.49998C7.7878 4.49998 7.58432 4.58426 7.43429 4.73429C7.28426 4.88432 7.19998 5.0878 7.19998 5.29998V8.49998C7.20002 8.71213 7.28434 8.91558 7.43438 9.06558L9.69678 11.3288C9.7711 11.4031 9.85934 11.4621 9.95646 11.5023C10.0536 11.5425 10.1577 11.5632 10.2628 11.5632C10.3679 11.5632 10.472 11.5425 10.5691 11.5023C10.6662 11.4621 10.7544 11.4031 10.8288 11.3288C10.9031 11.2544 10.9621 11.1662 11.0023 11.0691C11.0425 10.972 11.0632 10.8679 11.0632 10.7628C11.0632 10.6577 11.0425 10.5536 11.0023 10.4565C10.9621 10.3593 10.9031 10.2711 10.8288 10.1968L8.79998 8.16878V5.29998Z"
          fill="#90A4AE"
        />
      </svg>
    );
  }
   
  export function NotificationsMenu() {
    const [notifications, setNotifications] = useState([]);

    const socket = useSocket();
    const { user } = useContext(UserContext);
    const currentUserId = user && user.userId;

    const fetchNotifications = async () => {
      if (currentUserId) {
        const fetchedNotifications = await getNotificationsByUserId(currentUserId);
        setNotifications(fetchedNotifications.slice(0, 5)); // Keep only the latest 5 notifications
      }
    };
    useEffect(() => {
        if (!socket || !currentUserId) return;

        fetchNotifications(); // Initial fetch

        socket.emit('joinNotification', currentUserId);
    
        socket.on('newNotification', (notification) => {
          fetchNotifications();
        });
    
        return () => {
          socket.off('newNotification'); // Remove event listener on cleanup
          // socket.disconnect(); // If disconnecting socket is needed on component unmount
        };
      }, [socket, currentUserId]);
      const markNotifAsRead = async (notificationId) => {
        try {
          await markAsRead(notificationId);
          setNotifications((prevNotifications) =>
            prevNotifications.map((notif) =>
              notif.id === notificationId ? { ...notif, read: true } : notif
            )
          );
        } catch (error) {
          console.error('Error marking notification as read:', error);
        }
      };
    return (
      <Menu>
        <MenuHandler>
      

          <IconButton variant="text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />

            </svg>
          </IconButton>
        </MenuHandler>
        <MenuList className="flex flex-col gap-2">
        {notifications.map((notification, index) => (
        <MenuItem
          key={index}
          className={`flex items-center gap-4 py-2 pl-2 pr-8 ${notification.isRead ? 'bg-white' : 'bg-gray-200'}`}
          onClick={() => markNotifAsRead(notification.id)}
        >
          <Avatar
            variant="circular"
            alt="notification avatar"
            src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
          />
          <div className="flex flex-col gap-1">
            <Typography variant="small" color="gray" className="font-semibold">
              {notification.message}
            </Typography>
            <Typography className="flex items-center gap-1 text-sm font-medium text-blue-gray-500">
              <ClockIcon className="w-4 h-4" />
              {new Date(notification.createdAt).toLocaleString()}
            </Typography>
          </div>
        </MenuItem>
      ))}
    </MenuList>
      </Menu>
    );
  }