import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import OfferOverview from './offerOverview';
import { deleteOffer, updateOffer } from '../../api/offerApi';
import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';

const OfferCompanyCard = ({ offer }) => {

    const [open, setOpen] = useState(false);
    // const [menuAnchor, setMenuAnchor] = useState(null);
    const [alert, setAlert] = useState({ type: '', message: '' });


    const handleOpen = () => setOpen(!open);
    const navigate = useNavigate();

    const handleClose = async () => {
        try {
            await updateOffer(offer.id, { status: 'closed' });
            // Refresh offers after deletion
            setAlert({ type: 'success', message: 'Offer archived successfully!' });

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);

                } else if (error.response.status === 403) {
                    setAlert({ type: 'error', message: 'Forbidden. You do not have permission to add education.' });
                    setTimeout(() => {
                        navigate('/unauthorized');
                    }, 1000);


                } else {
                    setTimeout(() => {
                        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

                    }, 1000);
                }
            } else {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

            }

        }
    };
    const handleReopen = async () => {
        try {
            await updateOffer(offer.id, { status: 'open' });
            // Refresh offers after deletion
            setAlert({ type: 'success', message: 'Offer archived successfully!' });

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);

                } else if (error.response.status === 403) {
                    setAlert({ type: 'error', message: 'Forbidden. You do not have permission to add education.' });
                    setTimeout(() => {
                        navigate('/unauthorized');
                    }, 1000);


                } else {
                    setTimeout(() => {
                        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

                    }, 1000);
                }
            } else {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

            }

        }
        // handleMenuClose();
    };

    const handleCloseAlert = () => {
        setAlert({ type: '', message: '' });
    };
    return (
        
            <div

                className=" flex flex-col relative shadow-md overflow-hidden bg-white rounded-lg border border-gray-100 p-4 sm:p-6 cursor-pointer">

<div className="absolute top-4 left-4">
                <span className={`px-2 py-1 rounded-full text-xs ${offer.status === 'open' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                    {offer.status}
                </span>
            </div>
                <div className='fixed  top-24 right-10 space-y-4 z-50'>

            {alert.type === 'success' && (
                <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-green-500 rounded-lg shadow-lg opacity-100 font-regular">
                    {alert.message}
                    <button
                        className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                        type="button"
                        onClick={handleCloseAlert}
                    >
                        Close
                    </button>
                </div>
            )}
            {alert.type === 'error' && (
                <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
                    {alert.message}
                    <button
                        className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                        type="button"
                        onClick={handleCloseAlert}
                    >
                        Close
                    </button>
                </div>
            )}
            </div>
                {/* <span
                    className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-amber-300 via-blue-500 to-purple-600"
                ></span> */}
                 <div className=' self-end'>
                    <Menu >

                        <MenuHandler>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-dots"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /></svg>
                        </MenuHandler>
                        <MenuList>
                        <MenuItem onClick={handleReopen}>Reopen Offer</MenuItem>

                        <MenuItem onClick={handleClose}>Close Offer</MenuItem>
                        </MenuList>
                    </Menu>
                    </div>
        <div onClick={handleOpen}>
                <div className='flex flex-col justify-between'>
                   
                    <div className="sm:flex sm:justify-between sm:gap-4 items-center">
                        <div className='flex flex-col items-start'>

                                <div className="my-0 h-16 overflow-hidden ">
                                    <div className="text-pretty text-left  truncate ...">
                                        <h3 className="text-lg font-bold text-gray-900 sm:text-xl line-clamp-2"
                                        >
                                            {offer.title}</h3>
                                    </div>

                                </div>
                                


                            {/* <p className="mt-1 text-sm font-medium text-gray-600"> {offer.Company.companyName}</p> */}



                           
                        </div>

                        <div className="hidden sm:block sm:shrink-0">
                            <img
                                alt=""
                                src="/buildingLogo.png"
                                className="size-16 rounded-lg object-cover shadow-sm"
                            />
                        </div>
                    </div>
                    {/* <div className=' flex items-center justify-between md:'>
                        
                       

                    </div> */}

                    {/* <div className="my-4 h-10 overflow-hidden ">
                            <div className="text-pretty text-justify text-sm text-gray-500 truncate ...">
                            <p className='line-clamp-2' dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />

                            </div>
                        </div> */}

                    <div className=" flex flex-wrap gap-4 md:gap-4 sm:gap-2 items-center 	pt-4">

                        <div className=" md:flex flex-col">
                            <dt className="text-sm font-medium text-gray-600">Salary</dt>
                            <dd className="text-xs text-gray-500">{offer.salary}</dd>
                        </div>

                        <div className=" sm:flex flex-col ">
                            <dt className="text-sm font-medium text-gray-600">Duration</dt>
                            <dd className="text-xs text-gray-500">{offer.duration}</dd>
                        </div>
                        <div className=" lg:flex flex-col ">
                            <dt className="text-sm font-medium text-gray-600">University</dt>
                            <dd className="text-xs text-gray-500">{offer.University.universityName}</dd>
                        </div>
                        <div className="hidden lg:flex flex-col ">
                            <dt className="text-sm font-medium text-gray-600">Employment</dt>
                            <dd className="text-xs text-gray-500">{offer.employmentType}</dd>
                        </div>
                       
                         <div className='flex justify-self-end md:ml-auto md:mt-6' >
                            <Link
                                className="  group flex items-center justify-around gap-4 px-2 py-1 rounded-lg border border-indigo-600 bg-indigo-600  transition-colors hover:bg-transparent focus:outline-none focus:ring"
                                to={`edit/${offer.id}`}

                            >

                                <span
                                    className="font-medium text-sm text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
                                >
                                    Edit
                                </span>

                                <span
                                    className="shrink-0 rounded-full border border-current bg-white p-1 text-indigo-600 group-active:text-indigo-500"
                                >
                                    <svg
                                        className="size-5 rtl:rotate-180"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                                        />
                                    </svg>
                                </span>

                            </Link>
                        </div>
                    </div>
                </div>
                <OfferOverview open={open} handleOpen={handleOpen} offer={offer} actionType="edit" />
</div>
            </div>
    )
};
export default OfferCompanyCard;