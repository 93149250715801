import React, { useState } from 'react';
import Select from 'react-select';

const industryOptions = [
  { value: 'Accounting', label: 'Accounting' },
  { value: 'Advertising', label: 'Advertising' },
  { value: 'Aerospace', label: 'Aerospace' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Banking', label: 'Banking' },
  { value: 'Biotechnology', label: 'Biotechnology' },
  { value: 'Broadcasting', label: 'Broadcasting' },
  { value: 'Business Services', label: 'Business Services' },
  { value: 'Chemicals', label: 'Chemicals' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Consulting', label: 'Consulting' },
  { value: 'Consumer Products', label: 'Consumer Products' },
  { value: 'Education', label: 'Education' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Environmental Services', label: 'Environmental Services' },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Food & Beverage', label: 'Food & Beverage' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Information Technology', label: 'Information Technology' },
  { value: 'Legal Services', label: 'Legal Services' },
  { value: 'Logistics', label: 'Logistics' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Media', label: 'Media' },
  { value: 'Non-Profit', label: 'Non-Profit' },
  { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Transportation', label: 'Transportation' },
  { value: 'Utilities', label: 'Utilities' },
  { value: 'Wholesale', label: 'Wholesale' },
];

export default function IndustryInput ({ industry, handleIndustryChange }) {
  return (
    <div className="flex flex-col gap-3 w-full">
      {/* <label className="self-start block text-base font-medium text-dark dark:text-white">
        Industry
      </label> */}
      <Select
        value={industryOptions.find(option => option.value === industry)}
        onChange={(option) => handleIndustryChange(option.value)}
        options={industryOptions}
        placeholder="Select or type to search"
        className="w-full rounded-lg border-gray-200 text-sm shadow-sm"
      />
    </div>
  );
};

// const YourFormComponent = () => {
//   const [selectedIndustry, setSelectedIndustry] = useState(null);

//   const handleIndustryChange = (selectedOption) => {
//     setSelectedIndustry(selectedOption);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Use the selectedIndustry value as needed
//     console.log('Selected industry:', selectedIndustry);
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <IndustryInput 
//         selectedIndustry={selectedIndustry}
//         handleIndustryChange={handleIndustryChange}
//       />
//       <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-md">
//         Submit
//       </button>
//     </form>
//   );
// };

// export default YourFormComponent;
