import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const validationData = [
  { name: 'Validated', value: 80 },
  { name: 'Pending', value: 20 },
];

const COLORS = ['#0088FE', '#FF8042'];

const OfferValidationChart = () => (
  <div className="bg-white rounded-lg shadow-md p-4 flex justify-center items-center flex-col">
                    <h3 className='self-start ml-4 font-semibold'> Offer Validation Status  </h3>

    <PieChart width={400} height={280}>
      <Pie
        data={validationData}
        cx={200}
        cy={140}
        labelLine={false}
        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
      >
        {validationData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  </div>
);

export default OfferValidationChart;
