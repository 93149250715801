import { useContext } from "react";
import { UserContext } from "../contexts/userContext";

// Custom hook to get user roles
const useUserRoles = () => {
  const { user } = useContext(UserContext);
  const role = user && user.role;
  // console.log(role);
  return role;
};

export default useUserRoles;
