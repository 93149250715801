import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../contexts/userContext';
import OfferDashboardS from './offerDashboardS';
import OfferDashboardC from './offerDashboardC';
import OfferDashboardU from './offerDashboardU';
import { Spinner } from '@material-tailwind/react';



export default function OfferDashboard() {
    const [loading, setLoading] = useState(true);
    const [offer, setOffer] = useState(null);
    const { user } = useContext(UserContext);
   
    useEffect(() => {
        if (user && user.role) {
            const role = user.role;
            if (role === 'student') {
                setOffer(<OfferDashboardS />);
            } else if (role === 'company') {
                setOffer(<OfferDashboardC />);
            } else if (role === 'university') {
                setOffer(<OfferDashboardU />);
            }
            setLoading(false);
        }
    }, [user]);
    if (loading) {
        return <div className="flex justify-center items-center"><Spinner /></div>;
    }
  return (
    <>
    {offer}
    </>
  )
}