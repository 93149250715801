import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL  ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');

let headers = {};

if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject.token;

  headers = {
    Authorization: token,
  };
}

// Fetch all experiences
export const getAllExperiences = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/experiences`, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error('Error fetching experiences:', error);
    throw new Error('Failed to fetch experiences');
  }
};

// Fetch a single experience by ID
export const getExperienceById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/experiences/${id}`, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error fetching experience with ID ${id}:`, error);
    throw new Error(`Failed to fetch experience with ID ${id}`);
  }
};

// Create a new experience
export const createExperience = async (experienceData) => {
  try {
    const response = await axios.post(`${BASE_URL}/experiences`, experienceData, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error('Error creating experience:', error);
    throw new Error('Failed to create experience');
  }
};

// Update an existing experience
export const updateExperience = async (id, experienceData) => {
  try {
    const response = await axios.put(`${BASE_URL}/experiences/${id}`, experienceData, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error updating experience with ID ${id}:`, error);
    throw new Error(`Failed to update experience with ID ${id}`);
  }
};

// Delete an experience by ID
export const deleteExperience = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/experiences/${id}`, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error deleting experience with ID ${id}:`, error);
    throw new Error(`Failed to delete experience with ID ${id}`);
  }
};

// Fetch experiences by student ID
export const getExperiencesByStudent = async (studentId) => {
  try {
    const response = await axios.get(`${BASE_URL}/experiences/student/${studentId}`, /*  { headers } */);
    return response.data;
  } catch (error) {
    console.error(`Error fetching experiences for student with ID ${studentId}:`, error);
    throw new Error(`Failed to fetch experiences for student with ID ${studentId}`);
  }
};
