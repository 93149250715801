import React, { useContext, useEffect, useState } from 'react';
import EducationCard from '../account/educationCard';
import { getAllEducations, getEducationsByStudent } from '../../api/educationApi';
import { getExperiencesByStudent } from '../../api/experienceApi';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext';
import EducationProfileCard from './educationProfileCard';

export default function EducationProfile({ profileData }) {
    const { userData } = useContext(UserContext);

    const [educations, setEducations] = useState([]);
    useEffect(() => {
        // Fetch data when component mounts
        const fetchEducation = async () => {
            try {
                const data = await getEducationsByStudent(profileData.id); // Call the API function
                setEducations(data);
                // Set the fetched data to state
            } catch (error) {
                console.error('Error fetching educations:', error);
            }
        };
        fetchEducation();
        // console.log(educations);

        // Clean up function
        return () => {
            // Any cleanup code if needed
        };
    }, [profileData.id]);

    return (
        <div className='bg-white px-8 py-8  shadow-md rounded-md bg-clip-border '>
            <div className='flex flex-col justify-end'>

                <div className='flex items-center w-full justify-between'>
                    <h3 className="self-start block font-sans text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                        Education
                    </h3>
                    {userData && userData.userId === profileData.userId && (

                    <Link to='/account/education' className="self-start flex select-none items-center gap-3 rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    >

                        <span className=''>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                            </svg>
                        </span>
                    </Link>
                                        )}

                </div>
                {educations.length === 0 ? (
                    <div className='flex flex-col '>
                        <div className='self-center'>
                            <span className=''>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="gray" className="w-36 h-36">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                                </svg>

                            </span>
                        </div>
                        <div>
                            <h1> No additional education informations are available</h1>
                            <h1> Complete your profile information for a strong resume</h1>
                        </div>
                    </div>
                ) : (
                    <div className='mt-5'>
                        <EducationProfileCard educations={educations} />

                    </div>
                )}


            </div>

        </div>
    );
}