import React, { useState, useEffect } from 'react';
import ProfilePicture from './profilePicture';
import SummaryText from './summary';
import SocialNetwork from './socialMediaStudent';
import UploadResume from './uploadResume';
import CompanyInformation from './companyInformation';
import UniversityInformation from './universityInformation';

import useUserRoles from '../../routes/roles';
import SocialMediaU from './socialMediaU';
import SocialMediaC from './socialMediaC';
import StudentInformation from './studentInformation';

export default function ProfileInformation() {
    const role = useUserRoles()
    return (
        <div className='flex flex-col gap-4 pb-8'>
            {role === 'university' && (
                <>
                    <UniversityInformation />
                    <SocialMediaU />
                </>
            )}
            {role === 'company' && (
                <>
                    <CompanyInformation />
                    <SocialMediaC />
                </>
            )}
            {role === 'student' && (
                <>
                    <UploadResume />
                    <StudentInformation />
                    <SocialNetwork />
                </>
            )}

        </div>
    )
}