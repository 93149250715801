import React from 'react';
import { uploadFile } from '../../api/fileApi';


const AttachmentHandler = ({ senderId, recipientId, onUpload }) => {
    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        // const formData = new FormData();
        // formData.append('file', file);
        // formData.append('senderId', senderId);
        // formData.append('recipientId', recipientId);
        // const formDataArray = Array.from(formData.entries());
        // console.log('FormData as Array:', formDataArray);
        const formData = {
            senderId: senderId,
        recipientId: recipientId,
        file: file
        }
        console.log(formData);
            try {
          const uploadedData = await uploadFile(formData);
          onUpload(uploadedData);
        //   onUpload({ message: { senderId, conversationId, content: `ChatFile: ${uploadedData.fileName}` }, file: uploadedData });

        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    };
  
    return (
      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="fileInput"
      />
    );
  };
  

export default AttachmentHandler;
