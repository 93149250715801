import React, { useCallback, useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Textarea,
  Typography
} from "@material-tailwind/react";
import { useSocket } from '../../contexts/socketContext';
import { createMessage } from "../../api/chatApi";
import { UserContext } from "../../contexts/userContext";

export function MessageDialog({recipientId ,firstname}) {


  const socket = useSocket();
  const { user } = useContext(UserContext);
  const currentUserId = user && user.userId;


  const [messageInput, setMessageInput] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);
 
  const sendMessage = async () => {
    if (!socket || messageInput.trim() === '' || !user) return;

    const senderId = currentUserId;
    const newMessage = {
      senderId: senderId,
      recipientId: recipientId,
      content: messageInput,
    };

    try {
      // console.log(newMessage);
      await createMessage(newMessage);
      // console.log("message created");
      // socket.emit('message', newMessage);
      // console.log("message emmited");

      setMessageInput('');
      setOpen(false);
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };
  return (
    <>
      <div onClick={handleOpen}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
              </svg>
      </div>
    <Dialog open={open} size="xs" handler={handleOpen}>
        <div className="flex items-center justify-between">
          <DialogHeader className="flex flex-col items-start">

            <Typography className="mb-1" variant="h4">
              New message to {firstname}
            </Typography>
          </DialogHeader>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5"
            onClick={handleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <DialogBody>
          
          <div className="grid gap-6">
            
          <Textarea 
              label="Message" 
              value={messageInput} 
              onChange={(e) => setMessageInput(e.target.value)} 
            />          
            </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="text" color="gray" onClick={handleOpen}>
            cancel
          </Button>
          <Button variant="gradient" color="gray" onClick={sendMessage}>
            send message
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}