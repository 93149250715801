import React from 'react';

export default function ShortlisedApplicant() {
    return (
        <div className="bg-gradient-to-b from-yellow-500/20  to-white shadow-md rounded-xl bg-clip-border lg:w-52 w-36 bg-white">
            <div className='flex flex-col items-start'>
                <div class="m-4 flex  self-start justify-center rounded-full ">
                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-viewport-short">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 3v7l3 -3" /><path d="M9 7l3 3" /><path d="M12 21v-7l3 3" /><path d="M9 17l3 -3" /><path d="M18 9h1a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-1" /><path d="M6 9h-1a2 2 0 0 0 -2 2v2a2 2 0 0 0 2 2h1" />
                </svg>
                <h3 className='self-start ml-4 text-sm font-semibold'> Shortlisted   </h3>

                </div>
                {/* <h3 className='   text-sm'> find best match for your job offers  </h3> */}

                <h1 className=' text-5xl ml-4 mb-4 text-yellow-900 font-bold' > 0 </h1>

                
            </div>
        </div>
    )
}