import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');
let headers = {};

if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject.token;
  headers = {
    Authorization: `Bearer ${token}`,
  };
}

export const uploadFile = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/files`, formData, { headers: { ...headers, 'Content-Type': 'multipart/form-data' } });
    return response.data;
  } catch (error) {
    throw new Error(`Error uploading file: ${error.message}`);
  }
};

export const getFilesByMessageId = async (messageId) => {
  try {
    const response = await axios.get(`${BASE_URL}/files/${messageId}`, { headers });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching files: ${error.message}`);
  }
};

export const downloadFile = async (fileId) => {
  try {
    const response = await axios.get(`${BASE_URL}/files/download/${fileId}`, {
      responseType: 'blob'
    });
    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;

        // Ensure Content-Disposition header is present and correctly parsed
        const contentDisposition = response.headers['content-disposition'];
        let fileName = fileId; // Fallback to fileId if header is not present
        if (contentDisposition) {
            const match = contentDisposition.match(/filename="?(.+?)"?$/);
            if (match && match[1]) {
                fileName = decodeURIComponent(match[1]);
            }
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

