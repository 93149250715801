import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import OfferOverview from './offerOverview';

const OfferApplyCard = ({ offer }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(!open);

    // console.log(offer);
    return (
        <>
            <div
                // to={`/offer/${offer.id}`}
                className="relative  overflow-hidden bg-white rounded-lg border border-gray-100 p-4 sm:p-6 shadow-lg cursor-pointer"
                // onClick={}
                // onClick={handleClick}
                onClick={handleOpen}
            >
                {/* <span
                    className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"
                ></span> */}

                <div className='flex flex-col justify-between'>
                    {/* <div className="sm:flex sm:justify-between sm:gap-4"> */}
                    <div className='flex gap-2 items-start'>
                        <div className=" flex-none">
                            <img
                                alt=""
                                src="/buildingLogo.png"
                                className="size-16 rounded-lg object-cover shadow-sm"
                            />
                        </div>
                        <div className="my-2  overflow-hidden ">
                            <div className="text-pretty text-left  truncate ...">
                                <h3 className="text-base font-bold text-gray-900 sm:text-xl line-clamp-2"
                                >
                                    {offer.title}</h3>
                            </div>
                        </div>
                        {/* <p className="self-start mt-1 text-sm font-medium text-gray-600"> {offer.Company.companyName}</p> */}


                    </div>


                    {/* </div> */}
                    <p className=" self-start mt-2 text-base font-medium text-gray-800"> {offer.Company.companyName}</p>

                    {/* <div className='self-start'>
                        <p className="mt-1 text-sm font-medium text-gray-600"> {offer.employmentType}</p>

                    </div> */}
                    {/* <div className="my-4 h-10 overflow-hidden ">
                            <div className="text-pretty text-justify text-sm text-gray-500 truncate ...">
                            <p className='line-clamp-2' dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />

                            </div>
                        </div> */}

                    <dl className=" flex flex-wrap gap-4 md:gap-4 sm:gap-2 items-center 	pt-4">

                        <div className=" md:flex flex-col">
                            <dt className="text-sm font-medium text-gray-600">Salary</dt>
                            <dd className="text-xs text-gray-500">{offer.salary}</dd>
                        </div>

                        <div className=" sm:flex flex-col ">
                            <dt className="text-sm font-medium text-gray-600">Duration</dt>
                            <dd className="text-xs text-gray-500">{offer.duration}</dd>
                        </div>
                        <div className=" sm:flex flex-col ">
                            <dt className="text-sm font-medium text-gray-600">University</dt>
                            <dd className="text-xs text-gray-500">{offer.University.universityName}</dd>
                        </div>
                        {/* <div className="hidden lg:flex flex-col ">
                            <dt className="text-sm font-medium text-gray-600">Emplyment</dt>
                            <dd className="text-xs text-gray-500">{offer.employmentType}</dd>
                        </div> */}
                        <div className='flex self-end ml-auto' >
                            <Link
                                className="  group flex items-center justify-around gap-4 px-2 py-1 rounded-lg border border-indigo-600 bg-indigo-600  transition-colors hover:bg-transparent focus:outline-none focus:ring"
                                to={`/offers/apply/${offer.id}`}

                            >

                                <span
                                    className="font-medium text-sm text-white transition-colors group-hover:text-indigo-600 group-active:text-indigo-500"
                                >
                                    Apply
                                </span>

                                <span
                                    className="shrink-0 rounded-full border border-current bg-white p-1 text-indigo-600 group-active:text-indigo-500"
                                >
                                    <svg
                                        className="size-5 rtl:rotate-180"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                                        />
                                    </svg>
                                </span>

                            </Link>
                        </div>
                    </dl>
                </div>








            </div>
            <OfferOverview open={open} handleOpen={handleOpen} offer={offer} />

        </>
    )
};
export default OfferApplyCard;