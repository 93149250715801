import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');

let headers = {};

if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject.token;

  headers = {
    Authorization: token,
  };
}

// Fetch all certificates
export const getAllCertificates = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/certificates`,  { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching certificates:', error);
    throw new Error('Failed to fetch certificates');
  }
};

// Fetch a single certificate by ID
export const getCertificateById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/certificates/${id}`, {headers: headers,  });
    return response.data;
  } catch (error) {
    console.error(`Error fetching certificate with ID ${id}:`, error);
    throw new Error(`Failed to fetch certificate with ID ${id}`);
  }
};

// Create a new certificate
export const createCertificate = async (certificateData) => {
  try {
    const response = await axios.post(`${BASE_URL}/certificates`, certificateData, {headers: headers,  });
    return response.data;
  } catch (error) {
    console.error('Error creating certificate:', error);
    throw new Error('Failed to create certificate');
  }
};

// Update an existing certificate
export const updateCertificate = async (id, certificateData) => {
  try {
    const response = await axios.put(`${BASE_URL}/certificates/${id}`, certificateData, {headers: headers,  });
    return response.data;
  } catch (error) {
    console.error(`Error updating certificate with ID ${id}:`, error);
    throw new Error(`Failed to update certificate with ID ${id}`);
  }
};

// Delete an certificate by ID
export const deleteCertificate = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/certificates/${id}`, {headers: headers,  });
    return response.data;
  } catch (error) {
    console.error(`Error deleting certificate with ID ${id}:`, error);
    throw new Error(`Failed to delete certificate with ID ${id}`);
  }
};

// Fetch certificates by student ID
export const getCertificatesByStudent = async (studentId) => {
  try {
    const response = await axios.get(`${BASE_URL}/certificates/student/${studentId}`, {headers: headers,  });
    return response.data;
  } catch (error) {
    console.error(`Error fetching certificates for student with ID ${studentId}:`, error);
    throw new Error(`Failed to fetch certificates for student with ID ${studentId}`);
  }
};
