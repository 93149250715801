import React from 'react';



export default function Pagination({ itemsPerPage, totalItems, currentPage, paginate }){
    
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  
    
    return(
    <div class="flex items-center gap-4">
  <button
    class="flex items-center gap-2 px-6 py-3 font-sans text-xs font-bold text-center text-gray-900 uppercase align-middle transition-all rounded-full select-none hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
    type="button"
    onClick={() => paginate(currentPage - 1)}
    disabled={currentPage === 1}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
      aria-hidden="true" class="w-4 h-4">
      <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"></path>
    </svg>
    <span className='hidden sm:block'>
    Previous</span>
  </button>
  <div class="flex items-center gap-2">
  {pageNumbers.map((number) => (
    <button
    className={`relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase transition-all ${
      currentPage === number
        ? 'text-white bg-gray-900 hover:bg-gray-900/10 active:bg-gray-900/20 pointer-events-none  shadow-none'
        : 'text-gray-900 hover:bg-gray-900/10 active:bg-gray-900/20'
    }`}
      key={number}
      onClick={() => paginate(number)}
      disabled={currentPage === number}
    >
      {number}
    </button>
  ))}
    
  </div>
  <button
    class="flex items-center gap-2 px-6 py-3 font-sans text-xs font-bold text-center text-gray-900 uppercase align-middle transition-all rounded-full select-none hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
    type="button"
    onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}>
              <span className='hidden sm:block'>

    Next</span>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
      aria-hidden="true" class="w-4 h-4">
      <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"></path>
    </svg>
  </button>
</div> )}