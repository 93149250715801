import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ; // Replace with your backend API base URL

const tokenString = localStorage.getItem('token');

let headers = {};

if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject.token;

  headers = {
    Authorization: token,
  };
}

export const searchProfiles = async (query = "") => {
  try {
    const response = await axios.get(`${BASE_URL}/search`, {
      params: { search : query },
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error('Error searching profiles:', error);
    throw new Error('Failed to search profiles');
  }
};

export const suggestProfiles = async (query = "") => {
    try {
      const response = await axios.get(`${BASE_URL}/search/suggestions`, {
        params: { q : query },
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error('Error searching profiles:', error);
      throw new Error('Failed to search profiles');
    }
  };