import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL ;

const tokenString = localStorage.getItem('token');

let headers = {};
let token;
if (tokenString) {
  const tokenObject = JSON.parse(tokenString);
  token = tokenObject.token;

  headers = {
    Authorization: token,
  };
}
export const getApplications = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/applications`
      ,{headers: headers,  }
      );
      return response.data;
    } catch (error) {
      // Handle error
      console.error(`Error fetching application :`, error);
      throw new Error(`Failed to fetch application `);
    }
  };
// Fetch a single application by ID
export const getApplicationById = async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/applications/${id}`,{headers: headers,  });
      return response.data;
    } catch (error) {
      // Handle error
      console.error(`Error fetching application with ID ${id}:`, error);
      throw new Error(`Failed to fetch application with ID ${id}`);
    }
  };
// get application by student along with files
export const getApplicationBystId = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/applications/st/${id}`,{headers: headers,  });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(`Error fetching application with ID ${id}:`, error);
    throw new Error(`Failed to fetch application with ID ${id}`);
  }
};
//gets application with offer name and company name
export const getApplicationBystudentId = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/applications/student/${id}`,{headers: headers,  });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(`Error fetching application with ID ${id}:`, error);
    throw new Error(`Failed to fetch application with ID ${id}`);
  }
};

export const getApplicationByCompanyId = async (id, statuses =[]) => {
  try {
    const statusQuery = statuses.length > 0 ? `?statuses=${statuses.join(',')}` : '';

    const response = await axios.get(`${BASE_URL}/applications/company/${id}${statusQuery}`,{headers: headers,  });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(`Error fetching application with ID ${id}:`, error);
    throw new Error(`Failed to fetch application with ID ${id}`);
  }
};

export const getApplicationsByUniversityId= async (id, statuses =[]) => {
  try {
    const statusQuery = statuses.length > 0 ? `?statuses=${statuses.join(',')}` : '';

    const response = await axios.get(`${BASE_URL}/applications/university/${id}${statusQuery}`,{headers: headers,  });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching applications for university ID ${id}: ${error.message}`);
  }
}
export const getApplicationsByStatuses = async (statuses) => {
  try {
    const response = await axios.post(`${BASE_URL}/applications/statuses`, { statuses }, { headers: headers,  });
    return response.data;
  } catch (error) {
    console.error('Error fetching applications by statuses:', error);
    throw new Error('Failed to fetch applications by statuses');
  }
};

//create application
export const createApplication = async (applicationData) => {
  try {
    const response = await axios.post(`${BASE_URL}/applications/files`, applicationData,
     {headers: 
      { Authorization: token,
        'Content-Type': 'multipart/form-data',
      } });
    return response.data;
  } catch (error) {
    console.error('Error creating application:', error);
    throw new Error('Failed to create application');
  }
};

export const updateApplicationStatus = async (id,status) => {
  try {
    const response = await axios.put(`${BASE_URL}/applications/${id}`,
    
    { status: status } ,
     /* {headers: 
      { Authorization: token,
        'Content-Type': 'multipart/form-data',
      } } */);
    return response.data;
  } catch (error) {
    console.error('Error creating application:', error);
    throw new Error('Failed to create application');
  }
};
// export const getApplicationsWithTitle = async (id) => {
//   try {
//     const response = await axios.get(`${BASE_URL}/applications/student/title/${id}`,{headers: headers,  });
//     return response.data;
//   } catch (error) {
//     // Handle error
//     console.error(`Error fetching application with ID ${id}:`, error);
//     throw new Error(`Failed to fetch application with ID ${id}`);
//   }
// };