import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getOffers, getOffersByStatus } from '../../../api/offerApi';
import OfferOverview from '../../offer/offerOverview';
import { Spinner } from '@material-tailwind/react';

export default function RecentJobs() {

    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [offers, setOffers] = useState([]);
    // const [totalOffers, setTotalOffers] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [employmentType, setEmploymentType] = useState('');
    const [university, setUniversity] = useState('');
    const [status, setStatus] = useState('open');

    // const [open, setOpen] = useState(false);

    // const handleOpen = () => setOpen(!open);

    const itemsPerPage = 4;

    useEffect(() => {
        const fetchOffers = async () => {
            setLoading(true);
            try {
                const data = await getOffersByStatus(currentPage, itemsPerPage, searchQuery, employmentType, university, status);
                setOffers(data.offers);
                // setTotalOffers(data.totalOffers);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching offer by user:', error);
                setLoading(false);

            }
        };
        fetchOffers()

    }, []);

    const sortedApplications = offers
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Assuming createdAt is a valid date field



    return (

        <div class="bg-white shadow-md rounded-xl bg-clip-border p-4">
            <div className='flex flex-col gap-4'>
                <span className='font-semibold text-lg text-left mb-2 ml-2'>
                    Recent Jobs
                </span>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 h-full'>

                    {loading ? (
                        <div className="flex justify-center items-center h-full">
                            <Spinner className="h-12 w-12" />
                        </div>
                    ) : sortedApplications.length === 0 ? (
                        <h1>No Applications Found</h1>
                    ) : (
                        sortedApplications.map(offer => (
                            <Link to={`/offers/${offer.id}`} className='rounded-md shadow-md p-2' key={offer.id} >

                                <div className="sm:flex justify-between sm:gap-4 w-full">
                                    <div className='flex gap-4'>
                                        <div className="">
                                            <img
                                                alt=""
                                                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                                                className="size-16 rounded-lg object-cover shadow-sm"
                                            />
                                        </div>
                                        <div className='flex flex-col items-start'>
                                            <h3 className=" text-left font-semibold text-gray-900 ">
                                                {offer.title}
                                            </h3>


                                            <p className="mt-1 text-sm font-medium text-gray-600"> {offer.Company.companyName}</p>
                                        </div>

                                    </div>
                                </div>

                                {/* <OfferOverview  open={open} handleOpen={handleOpen} offer={offer} /> */}

                            </Link>
                        )))}


                </div>
                <Link to="/offers" className='mt-10'>
                    <span className='underline font-body text-center'>Show more </span>
                </Link>

            </div>
        </div>
    )
}