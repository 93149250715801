import React, { useState, useContext, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/authContext';
import ProfileMenuButton from './profileMenuButton';
import { NotificationsMenu } from './notificationButton';
import { UserContext } from '../../contexts/userContext';
import { BurgerMenu } from './burgerMenu';
import { searchProfiles, suggestProfiles } from '../../api/searchApi';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash.debounce';


function NavbarDashboard() {
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useContext(UserContext);
    const [suggestions, setSuggestions] = useState([]);

    const [query, setQuery] = useState('');
    const [results, setResults] = useState({ students: [], companies: [], universities: [] });
    const navigate = useNavigate();

    const handleSearch = async () => {
        try {
            const data = await searchProfiles(query);
            setResults(data);

        } catch (error) {
            console.error('Error searching profiles:', error);
        }
    };
    // console.log(results);
    const getSuggestions = async (value) => {
        try {
            const data = await searchProfiles(value);
            const allSuggestions = [
                ...data.students.map(student => ({ ...student, role: 'student' })),
                ...data.companies.map(company => ({ ...company, role: 'company' })),
                ...data.universities.map(university => ({ ...university, role: 'university' }))
            ];
            setSuggestions(allSuggestions);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        }
    };
    // console.log(suggestions);
    const debouncedGetSuggestions = debounce(getSuggestions, 300);

    const onSuggestionsFetchRequested = async ({ value }) => {
        debouncedGetSuggestions(value);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (event, { suggestion }) => {
        setQuery(suggestion.userName || suggestion.email);
        navigate(`/profile/${suggestion.userName}`);

        // handleSearch();
    };

    const renderSuggestion = (suggestion) => {
        const displayText =
            suggestion.role === 'student'
                ? `${suggestion.Student.firstName} ${suggestion.Student.lastName}`
                : suggestion.role === 'company'
                    ? suggestion.Company.companyName
                    : suggestion.role === 'university'
                        ? suggestion.University.universityName
                        : suggestion.userName;

        return (
            <div>
                {displayText} ({suggestion.role})
            </div>
        );
    };

    const inputProps = {
        placeholder: 'Search',
        className: "rounded-lg",
        value: query,
        onChange: (e, { newValue }) => setQuery(newValue),
        onKeyPress: (e) => { if (e.key === 'Enter') handleSearch(); }
    };
    return (
        <nav className="sticky top-0 z-50 bg-white  dark:bg-gray-800 shadow">
            <div className="container px-4 py-3 mx-auto">
                <div className=' flex items-center'>
                    <div className="flex items-center justify-around gap-2  w-full  z-50">
                         


                        <nav className="sticky top-0 z-50 bg-white  dark:bg-gray-800 ">
                            <div className="container px-4 py-3 mx-auto">
                                <div className=' flex items-center'>
                                    <div class="w-62">
                                        <div class="relative h-10 w-full min-w-[200px]  rounded-lg">
                                           
                                            <Autosuggest
                                                suggestions={suggestions}
                                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                onSuggestionSelected={onSuggestionSelected}
                                                getSuggestionValue={(suggestion) => suggestion.userName}
                                                renderSuggestion={renderSuggestion}
                                                inputProps={inputProps}
                                                theme={{
                                                    container: 'relative',
                                                    suggestionsContainer: 'absolute z-50 w-full bg-white  border-gray-300 rounded-lg shadow-lg mt-1 max-h-60 overflow-y-auto',
                                                    suggestionsList: 'list-none p-0 m-0',
                                                    suggestion: 'p-2 cursor-pointer',
                                                    suggestionHighlighted: 'bg-gray-200'
                                                }}

                                            />
                                            {/* <div className='bg-white'>
      <div className="mt-2">
        <h3 className="text-lg font-semibold text-gray-700">Students</h3>
        {results.students.map((student) => (
          <div key={student.id} className="p-2 border-b border-gray-200">
            {student.Student.firstName} {student.Student.lastName}
          </div>
        ))}
      </div>
      <div className="mt-2">
        <h3 className="text-lg font-semibold text-gray-700">Companies</h3>
        {results.companies.map((company) => (
          <div key={company.id} className="p-2 border-b border-gray-200">
            {company.Company.companyName}
          </div>
        ))}
      </div>
      <div className="mt-2">
        <h3 className="text-lg font-semibold text-gray-700">Universities</h3>
        {results.universities.map((university) => (
          <div key={university.id} className="p-2 border-b border-gray-200">
            {university.University.universityName}
          </div>
        ))}
      </div>
      </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>


                        <div className='hidden lg:flex lg:items-center lg:justify-center w-full lg:w-auto '>

                            <div className="flex justify-center gap-4">
                                <Link to="/offers" className="flex-none flex gap-2 items-center px-3 py-2 text-sm text-gray-700 transition-colors duration-300 transform rounded-md dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                                    </svg>
                                    Find Work
                                </Link>
                                <Link to={user && user.userId ? `/profile/${user.userName}` : '#'} className="flex gap-2 items-center px-3 py-2 text-sm text-gray-700 transition-colors duration-300 transform rounded-md dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                                    </svg>
                                    Portfolio
                                </Link>
                                <Link to="/dashboard" className=" flex gap-2 items-center px-3 py-2 text-sm text-gray-700 transition-colors duration-300 transform rounded-md dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
                                    </svg>


                                    Dashboard
                                </Link>
                            </div>
                        </div>
                        {/* Mobile menu button */}
                        {/* <div className="flex lg:hidden">
                        <button onClick={toggleMenu} type="button" className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400" aria-label="toggle menu">
                            {!isOpen ? (
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                        </button>
                    </div> */}

                        {/* Mobile Menu open: "block", Menu closed: "hidden" */}
                        {/* <div className={`absolute  inset-x-0 z-20 w-full px-6 py-4  bg-white dark:bg-gray-800 lg:mt-0 lg:p-0 lg:top-0 lg:mr-6 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center ${isOpen ? '  translate-x-0 opacity-100' : 'opacity-0 -translate-x-full'}`}> */}
                        <div className="hidden lg:flex items-center justify-between   gap-4 -mx-6 lg:flex-row lg:items-center lg:mx-8">


                            <Link to="/chat" className="px-3 py-2  text-sm text-gray-700 transition-colors duration-300 transform rounded-md lg:mt-0 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                                </svg>

                            </Link>
                            <div>
                                <NotificationsMenu />
                            </div>


                            <div >
                                <ProfileMenuButton />
                            </div>



                        </div>


                        {/* </div> */}

                    </div>
                    <div className='block lg:hidden'>
                        <BurgerMenu />
                    </div>
                </div>
            </div>
        </nav>

    )
}
export default NavbarDashboard;