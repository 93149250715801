import React, { useContext, useEffect, useState } from 'react';
import SavedJobs from '../../components/dashboard/student/savedJobs';

import ProfileCard from '../../components/dashboard/student/profileCard';
import MyCalendar from '../../components/calendar/calendar';
import RecentApplication from '../../components/dashboard/company/recentCompanyApplication';
import RecentJobs from '../../components/dashboard/student/recentJobs';
import UpcomingEvents from '../../components/dashboard/upcomingEvents';
import PendingApplicationsChart from '../../components/dashboard/student/pendingApplicationsChart ';
import PendingApplications from '../../components/dashboard/student/pendingApplications';
import { UserContext } from '../../contexts/userContext';
import { Spinner } from '@material-tailwind/react';
import RecentStudentApplication from '../../components/dashboard/student/recentStudentApplication';

export default function DashboardPageStudent() {

    // const { username } = useParams();
    const [loading, setLoading] = useState(true);
    const [profileData, setProfileData] = useState(null);
    const { userData } = useContext(UserContext);
    // console.log(userData.firstName);
    // useEffect(() => {
    //   const fetchData = async () => {
    //     try {
    //       const profileData = await getUserProfileByUsername(username);
    //       setProfileData(profileData);
    //       setLoading(false);
    //     } catch (error) {
    //       console.error('Error fetching profile data:', error);
    //     }
    //   };

    //   fetchData();
    // }, [username]);

    return (
        <div className='flex flex-col gap-4 justify-center items-stretch'>
            <div
                className='grid md:grid-cols-2 gap-4 grid-cols-1'
            // className='flex flex-wrap items-center justify-around gap-4'
            >
                <div className='place-self-center w-full'>
                    {userData && userData.firstName && userData.lastName ? (
                        <ProfileCard
                            firstName={userData.firstName}
                            lastName={userData.lastName}
                        />
                    ) : (
                        <div><Spinner/></div> // or any other fallback UI
                    )}
                </div>
                <div className='flex gap-4  place-self-center'>
                    <SavedJobs />
                    <PendingApplications />
                </div>
                <PendingApplicationsChart />
                <RecentJobs />

                <RecentStudentApplication />


                <div className=' flex flex-col w-full bg-white shadow-md rounded-xl bg-clip-border p-4'>
                    <span className='font-semibold text-lg text-left mb-4 ml-2'>
                        Calendar
                    </span>
                    <MyCalendar height={280} />
                </div>
                <UpcomingEvents />

            </div>














            {/* <div className="mixed-chart"> */}
            {/* <Chart
              options={options}
              series={options.series}
            //   type=""
            //     height={350}
            //   width="500"
            chart={options.chart}
            /> */}

            {/* <RadialBar imageUrl= "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"/> */}
            {/* <Donut/> */}
            {/*  */}
            {/* </div> */}

            {/* <CirclePie percentage={5} colour="#000" imageUrl={'https://www.google.com/url?sa=i&url=https%3A%2F%2Favatar.fandom.com%2Fwiki%2FCategory%3ACharacters&psig=AOvVaw1k_GGusiMb5-z4SGH7QRkQ&ust=1715788158333000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCPCXoJG_jYYDFQAAAAAdAAAAABAE'}/> */}






        </div>
    )
}