import React, { useContext, useEffect,useState } from 'react';
import CertificateCard from './certificateCard';
import { createCertificate, deleteCertificate, getAllCertificates, getCertificatesByStudent, updateCertificate } from '../../api/certificateApi';
import Editor from '../common/editor';
import { UserContext } from '../../contexts/userContext';
import { Dialog } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import CertificateDialog from './certificateDialog';

export default function Certificate() {
    const { userData } = useContext(UserContext);
    const id = userData.id

    const [certificates, setCertificates] = useState([]);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [selectedCertificate, setSelectedCertificate] = useState(null);

    const [date, setDate] = useState({
        month: '',
        year: '',
        
    })
    const navigate = useNavigate();
    const handleOpen = () => {
        setOpen(!open);
        navigate('/account/certificate/add');
    };
    const handleEditOpen = (certificate = null) => {
        setSelectedCertificate(certificate);
        setOpen(!open);
        if (certificate) {
            setFormData({
                certificateName: certificate.certificateName,
                certificateDate: certificate.certificateDate,
                organizationName: certificate.organizationName,
                certificateLink: certificate.certificateLink,
                description: certificate.description,
                studentId: id,
            });

            const [year, month,day ] = certificate.certificateDate.split('-');
            setDate({
                month: month,
                year: year,
            });
        } else {
            setFormData({
                certificateName: "",
                certificateDate: "",
                organizationName: "",
                certificateLink: "",
                description: "",
                studentId: id,
            });

            setDate({
                month: '',
                year: '',
            });
        }
        navigate('/account/certificate/edit');
    };
    useEffect(() => {
        if (!open) {
            navigate('/account/certificate');
        }
    }, [open, navigate]);

    const [formData, setFormData] = useState({
        certificateName: "",
        certificateDate: "",
        organizationName: "",
        certificateLink: "",
        description: "",
        studentId: id,
    });
    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDate({
            ...date,
            [name]: value,
        });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        formData.certificateDate = `${date.year}-${date.month}-01`;
        if (!formData.certificateName || !formData.organizationName || !formData.certificateDate) {
            setAlert({ type: 'error', message: 'Please fill in the required fields.' });
            return;
        }

    

        try {
            if (selectedCertificate) {
                await updateCertificate(selectedCertificate.id, formData);
                setOpen(!open);

                setAlert({ type: 'success', message: 'Certificate updated successfully!' });
            } else {
                await createCertificate(formData);
                setAlert({ type: 'success', message: 'Certificate added successfully!' });
            }

            const updatedCertificates = await getCertificatesByStudent(id);
            setCertificates(updatedCertificates);

            setFormData({
                certificateName: "",
                certificateDate: "",
                organizationName: "",
                certificateLink: "",
                description: "",
                studentId: id,
            });

            setDate({
                month: '',
                year: '',
            });

            // handleOpen();

            setOpen(!open);
            

        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlert({ type: 'error', message: 'Unauthorized. Please log in.' });
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);

                } else if (error.response.status === 403) {
                    setAlert({ type: 'error', message: 'Forbidden. You do not have permission to add experience.' });
                    setTimeout(() => {
                        navigate('/unauthorized');
                    }, 1000);


                } else {
                    setTimeout(() => {
                        setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

                    }, 1000);
                }
            } else {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });

            }

        }
    };
    useEffect(() => {
        // Fetch data when component mounts
        const fetchCertificate = async () => {
            try {
                const data = await getCertificatesByStudent(id); // Call the API function
                setCertificates(data);
                // Set the fetched data to state
            } catch (error) {
                setAlert({ type: 'error', message: 'An error occurred. Please try again.' });
            }
        };
        fetchCertificate();
        // console.log(certificates);

        // Clean up function
        return () => {
            // Any cleanup code if needed
        };
    }, []);

    const handleDelete = async (certificateId) => {
        try {
          await deleteCertificate(certificateId);
          setCertificates(certificates.filter((certificate) => certificate.id !== certificateId));
          setAlert({ type: 'success', message: 'Certificate deleted successfully!' });
        } catch (error) {
          setAlert({ type: 'error', message: 'Failed to delete certificate. Please try again.' });
        }
      };
      
    const handleCloseAlert = () => {
        setAlert({ type: '', message: '' });
    };

    return (
        <div className='bg-white px-8 py-8  shadow-md rounded-xl bg-clip-border '>
            <div className='fixed  top-24 right-10 space-y-4 z-50'>

{alert.type === 'success' && (
    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-green-500 rounded-lg shadow-lg opacity-100 font-regular">
        {alert.message}
        <button
            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
            type="button"
            onClick={handleCloseAlert}
        >
            Close
        </button>
    </div>
)}
{alert.type === 'error' && (
    <div className="relative flex gap-2 items-center w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg shadow-lg opacity-100 font-regular">
        {alert.message}
        <button
            className='select-none rounded-lg py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white transition-all hover:bg-white/10 active:bg-white/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
            type="button"
            onClick={handleCloseAlert}
        >
            Close
        </button>
    </div>
)}
</div>
            <div className='flex flex-col justify-end'>
            <div className='flex justify-between'>

                <h3 className="self-start block font-sans text-lg antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                    Certificate
                </h3>
                
                <div className='mt-8 '>
                    <button
                        className="flex select-none items-center rounded-lg bg-gray-900 py-3 px-6 text-center align-middle font-sans text-xs font-bold  text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        type="button"  onClick={handleOpen}>
                        <span className='mr-4'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                            </svg></span>
                        Add Certificates
                    </button>

                   
                        
                </div>
                </div>
                {certificates.length === 0 ? (
                <div className='flex flex-col '>

                <div className='self-center'>
                    <span className=''>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="gray" className="w-36 h-36">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path><path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5"></path><path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73">
                    </path><path d="M6 9l12 0"></path><path d="M6 12l3 0"></path><path d="M6 15l2 0"></path>
                    </svg>

                    </span>
                </div>
                <div>
                    <h1> No additional certificate are available</h1>
                    <h1> Complete your profile information for a strong resume</h1>
                </div>
                </div>
 ) :  (
    <div className='mt-5'>
        <CertificateCard certificates={certificates} onDelete={handleDelete} onEdit={handleEditOpen} />
    </div>
)}
            </div>
            <CertificateDialog
                open={open}
                handleOpen={handleOpen}
                handleSubmit={handleSubmit}
                formData={formData}
                handleChange={handleChange}
                date={date}
                handleDateChange={handleDateChange}
            />

        </div>
    )
}