import React from 'react';
import { FunnelChart, Funnel, LabelList, Tooltip, Cell } from 'recharts';

const funnelData = [
  { name: 'New Applications', value: 100 },
  { name: 'Shortlisted', value: 60 },
  { name: 'Interviewed', value: 30 },
  { name: 'Hired', value: 10 },
];
const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042'];

const ApplicationFunnel = () => (
<div className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center">
<h3 className='self-start ml-4 font-semibold mb-2'> Application Funnel </h3>   
 <FunnelChart width={400} height={280}>
      <Tooltip />
      <Funnel
        dataKey="value"
        data={funnelData}
        isAnimationActive
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
         {funnelData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
        <LabelList position="right" fill="#000" stroke="none" dataKey="name" />
      </Funnel>
    </FunnelChart>
  </div>
);

export default ApplicationFunnel;
