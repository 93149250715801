import React from 'react';
import LoginForm from '../components/authentification/loginForm';
// import Profile from '../components/profile/profile';


const LoginPage = () => {
  return (
    // <div>login</div>
    <LoginForm/>
  );
}

export default LoginPage;
