import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { getStudents } from '../../../api/studentApi';
import { Spinner } from '@material-tailwind/react';

export default function RecommendedProfile() {
    const [loading, setLoading] = useState(true); // Add loading state
    const [students, setStudents] = useState([])

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const StudentsData = await getStudents();
                // console.log(StudentsData);
                // const StudentsData = await getStudents();
                setStudents(StudentsData);
                setLoading(false); // Set loading to false when offers are fetched

            } catch (error) {
                console.error('Error fetching offers:', error);
            }
        };

        fetchStudents();
    }, []);
    // console.log(students);
    const sortedStudents = students
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Assuming createdAt is a valid date field
        .slice(0, 4);
    return (

        <div className="bg-white shadow-md rounded-xl bg-clip-border p-4 flex flex-col h-full">
            <div className='flex flex-col gap-2 '>
                <span className='font-semibold text-lg text-left mb-2 ml-2'>
                    Recommended Profiles
                </span>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4  h-full">

                    {loading ? (
                        <div className="flex justify-center items-center h-full">
                            <Spinner className="h-12 w-12" />
                        </div>
                    ) : sortedStudents.length === 0 ? (
                        <h1>No Applications Found</h1>
                    ) : (
                        sortedStudents.map((student, index) => (
                            <Link 
                            to={`/profile/${student.User.userName}`} 
                            className='rounded-md shadow-md p-2' key={index} >
                                <div className="flex justify-between gap-4 w-full">
                                    <div className='flex gap-4'>
                                        <div className="">
                                            <img
                                                alt=""
                                                src="/studentProfile.jpeg"
                                                className="size-16 rounded-lg object-cover shadow-sm"
                                            />
                                        </div>
                                        <div className=''>
                                            <h3 className=" text-left font-semibold text-gray-900 ">
                                                {student.firstName} {student.lastName}
                                            </h3>
                                            {/* <p className="mt-1 text-sm font-medium text-gray-600">sopra </p> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )))}


                </div>
            </div>
            <div className='mt-auto'>
                <Link to="#" className=''>
                    <span className='underline font-body text-center'>Show more</span>
                </Link>
            </div>
        </div>
    )
}