import React from 'react';
import { Link } from "react-router-dom";

export default function UpcomingEvents() {
    return (
        <div class="bg-white shadow-md rounded-xl bg-clip-border p-4">

            <div className='flex flex-col gap-2'>
                <span className='font-semibold text-lg text-left mb-2 ml-2'>
                    Upcoming events
                </span>

                {/* <div className="flex justify-between gap-4 w-full border-y">
                    <div className='flex gap-4'>
                        <div className=" hidden sm:block sm:shrink-0">
                            <img
                                alt=""
                                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                                className="size-16 rounded-lg object-cover shadow-sm"
                            />
                        </div>
                        <div className=''>
                            <h3 className="text-lg text-left font-bold text-gray-900 sm:text-xl"
                            >
                            </h3>
                            Data scientist Intern

                            <p className="mt-1 text-sm font-medium text-gray-600">sopra </p>
                        </div>

                    </div>

                    <div className="  p-4 mr-4 border-blue-gray-50 ">
                        <div className="w-max">
                            <div className="relative grid items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap bg-blue-gray-500/20 text-blue-gray-900">
                                <span>PENDING</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-between gap-4 w-full border-y">
                    <div className='flex gap-4'>
                        <div className=" hidden sm:block sm:shrink-0">
                            <img
                                alt=""
                                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                                className="size-16 rounded-lg object-cover shadow-sm"
                            />
                        </div>
                        <div className=''>
                            <h3 className="text-lg text-left font-bold text-gray-900 sm:text-xl"
                            >
                            </h3>
                            Data scientist Intern

                            <p className="mt-1 text-sm font-medium text-gray-600">sopra </p>
                        </div>

                    </div>

                    <div className="  p-4 mr-4 border-blue-gray-50 ">
                        <div className="w-max">
                            <div className="relative grid items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap bg-blue-gray-500/20 text-blue-gray-900">
                                <span>PENDING</span>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="flex justify-between gap-4 w-full border-y">
                    <div className='flex gap-4'>
                        <div className=" hidden sm:block sm:shrink-0">
                            <img
                                alt=""
                                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                                className="size-16 rounded-lg object-cover shadow-sm"
                            />
                        </div>
                        <div className=''>
                            <h3 className="text-lg text-left font-bold text-gray-900 sm:text-xl"
                            >
                            </h3>
                            Data scientist Intern

                            <p className="mt-1 text-sm font-medium text-gray-600">sopra </p>
                        </div>

                    </div>

                    <div className="hidden sm:block p-4 mr-4 border-blue-gray-50 ">
                        <div className="w-max">
                            <div className="relative grid items-center px-2 py-1 font-sans text-xs font-bold uppercase rounded-md select-none whitespace-nowrap bg-blue-gray-500/20 text-blue-gray-900">
                                <span>PENDING</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to="#" className=' '>
                    <span className='underline font-body mt-2'>Show more </span>
                </Link> */}
            </div>
        </div>
    )
}